import React from 'react';
import { useRecoilValue } from 'recoil';

import i18n from 'i18next';

import { OperatorAvatar } from '../../components/operator-avatar/operator-avatar';
import {
  OperatorState,
  OperatorsStatus,
} from '../../components/operators-status/operators-status';
import { useUserClaims } from '../../hooks/use-user-claims';
import { activeOperatorsState } from '../../stores/activeOperators';
import { useUser } from '../../stores/usercontext';
import { Message } from '../../types/generated';
import { transformDateWithYear } from '../../utils/date';
import { UserRole, checkRoles } from '../../utils/role';

const getEmojiSentiment = (sentiment?: string) => {
  if (sentiment === 'positive') {
    return ' 😊';
  } else if (sentiment === 'neutral') {
    return ' 😐';
  } else if (sentiment === 'negative') {
    return ' 😞';
  }
  return '';
};

const getEmoji = (total_orders?: number) => {
  let emoji: string | undefined = undefined;
  let label: string | undefined = undefined;

  if (typeof total_orders === 'number') {
    if (total_orders === 1) {
      emoji = '👶';
      label = i18n.t('list.message.first_order') || '';
    } else if (total_orders && total_orders > 10) {
      emoji = '🌟';
      label = i18n.t('list.message.more_than_10_orders') || '';
    }
  }
  return emoji ? (
    <span className="emoji-fix mr-1" aria-label={label} role="img">
      {emoji}
    </span>
  ) : null;
};

type ConversationSummaryMessageProps = {
  message: Message;
  selectMessage: (userKey: string, fromClick?: boolean) => void;
  activeId: string;
};

export const ConversationSummaryMessage: React.FC<
  ConversationSummaryMessageProps
> = ({ message, selectMessage, activeId }: ConversationSummaryMessageProps) => {
  const { user } = useUser();
  const claims = useUserClaims();

  const activeOperators = useRecoilValue(activeOperatorsState);

  const renderOperatorsInteraction = () => {
    const activeOperatorsLegit = activeOperators.filter(
      (operator) =>
        message.userKey === operator.userKey &&
        operator.operatorId !== user?.uid
    );

    return activeOperatorsLegit.length > 0 ? (
      <div className="relative flex space-x-4 overflow-hidden">
        <OperatorsStatus
          operators={activeOperatorsLegit}
          state={OperatorState.IsReplying}
          size="small"
        />
        <OperatorsStatus
          operators={activeOperatorsLegit}
          state={OperatorState.IsReading}
          size="small"
        />
      </div>
    ) : (
      <div className="h-4"></div>
    );
  };

  const selectConversation = () => {
    selectMessage(message.userKey ?? '', true);
  };

  const isActive = () => activeId === message.userKey;

  return (
    <li
      onClick={selectConversation}
      className={`cursor-pointer p-4 hover:bg-gray-50 ${
        isActive() ? 'bg-gray-50' : ''
      }`}
    >
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center truncate">
          <h5 className="truncate text-sm font-bold text-gray-900">
            {getEmoji(message.total_orders ?? 0)}
            <span>{message.name}</span>
            {/* POC IA */}
            <span>
              {checkRoles(
                {
                  onlyAdmin: false,
                  requireRole: [UserRole.IA_SUPPORT],
                },
                claims
              ) && message.aiPriorityScore
                ? ` (${message.aiPriorityScore})`
                : null}

              {checkRoles(
                {
                  onlyAdmin: false,
                  requireRole: [UserRole.IA_SUPPORT],
                },
                claims
              ) && message.aiSentiment
                ? getEmojiSentiment(message.aiSentiment)
                : null}
            </span>
          </h5>
        </div>

        {message.createdAt ? (
          <time
            dateTime={message.createdAt}
            className="ml-4 shrink-0 text-xs text-gray-500"
          >
            {transformDateWithYear(
              new Date(message.createdAt),
              i18n.language === 'fr' ? 'fr' : 'en'
            )}
          </time>
        ) : null}
      </div>
      <div className="flex items-start justify-between">
        <p className="mb-1 line-clamp-2 min-h-8 w-[11.25rem] text-xs text-gray-700">
          {message.body}
        </p>
        {message.assignTo ? (
          <OperatorAvatar
            operatorId={message.assignTo}
            size="medium"
            showBorder={false}
          />
        ) : null}
      </div>
      {renderOperatorsInteraction()}
    </li>
  );
};

ConversationSummaryMessage.displayName = 'ConversationSummaryMessage';
