import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import { Order } from '../../types/order';
import { BRAND_BASE_URL } from '../../utils/url';
import { ActionOption, OtherActionsForm } from './other-actions-modal';

type OtherActionsModalActionFormFieldsProps = {
  options: ActionOption[];
  order?: Order;
};

export const OtherActionsModalActionFormFields = ({
  options,
  order,
}: OtherActionsModalActionFormFieldsProps) => {
  const { t } = useTranslation();

  const form = useFormContext<OtherActionsForm>();

  return (
    <>
      <h4 className="mb-4 font-semibold text-gray-700">
        {t('other_actions.step_action.title')}
      </h4>
      <a
        href={`${BRAND_BASE_URL}/${order?.seller?.id}/orders/${order?.id}?sid=${order?.saleId?.slice(0, 5)}`}
        target="_blank"
        rel="noreferrer"
        className="mb-4 flex items-center space-x-2 text-sm font-semibold text-green-900"
      >
        <Icon icon="externalLinkDefault" className="size-6" />
        <span>{t('other_actions.actions.add_edit_tracking')}</span>
      </a>
      <FormField
        control={form.control}
        name="actionType"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormItem>
            <FormLabel className="sr-only" hidden>
              {t('other_actions.step_action.fields.action_type.label')}
            </FormLabel>
            <FormControl>
              <RadioGroup
                value={value}
                onValueChange={(value) => onChange(value)}
                className="gap-4"
              >
                {options.map((option) => (
                  <div
                    className={cn('group flex items-center space-x-3', {
                      'items-start': option.description,
                    })}
                    key={option.value}
                  >
                    <RadioGroupItem
                      id={`action-option-${option.value}`}
                      key={option.value}
                      value={option.value}
                      //disabled={option.disabled}
                    />

                    <Label
                      htmlFor={`action-option-${option.value}`}
                      className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                    >
                      <p>{option.text}</p>
                      {option.description ? (
                        <p className="text-gray-500 group-has-[.peer:disabled]:text-gray-300">
                          {option.description}
                        </p>
                      ) : null}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage match="required">
              {t(
                'other_actions.step_action.fields.action_type.validation_errors.required'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
    </>
  );
};
