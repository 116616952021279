import { FilePreview } from '../file-preview/file-preview';

export type FilesPreviewProps = {
  files: File[] | undefined;
  canRemove: boolean;
  onRemoveFile?: (index: number) => void;
  className: string;
  sizeClassName: string;
};

export const FilesPreview: React.FC<FilesPreviewProps> = ({
  files,
  canRemove,
  onRemoveFile,
  className,
  sizeClassName,
}: FilesPreviewProps) => {
  if (files && files.length > 0) {
    return (
      <div
        className={`files flex items-center space-x-2 ${
          className ? className : ''
        }`}
      >
        {files.map((file, index) => (
          <FilePreview
            key={index}
            index={index}
            file={file}
            canRemove={canRemove}
            onRemoveFile={onRemoveFile}
            sizeClassName={sizeClassName}
          />
        ))}
      </div>
    );
  }
  return null;
};
