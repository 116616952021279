import React from 'react';

import Icon from '@appchoose/icon';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';

type DeliverySelectOption = {
  label: React.ReactNode;
  value: string;
  onClick: (e: Event) => void;
  disabled: boolean;
};

type DeliverySelectProps = {
  defaultValue?: string;
  options: DeliverySelectOption[];
  disabled?: boolean;
};

export const DeliverySelect = ({
  defaultValue,
  options,
  disabled,
}: DeliverySelectProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} className="flex space-x-1">
        <span>{options.find((o) => o.value === defaultValue)?.value}</span>
        <Icon icon="arrowDown" />
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" sideOffset={12} className="p-0">
        <DropdownMenuGroup className="p-1">
          {options.map((option) => (
            <DropdownMenuItem
              key={option.value}
              onSelect={option.onClick}
              disabled={option.disabled}
              className="text-gray-700"
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
