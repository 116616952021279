import { PaymentProcessor, PaymentSubProcessor } from '../../types/generated';
import { Order } from '../../types/order';
import { Alma } from '../icons/alma';
import { BancontactxStripe } from '../icons/bancontactxstripe';
import { KlarnaxStripe } from '../icons/klarnaxstripe';
import { Paypal } from '../icons/paypal';
import { PayPalxStripe } from '../icons/paypalxstripe';
import { Stripe } from '../icons/stripe';
import { Primer } from './primer';

type PaymentProviderLinkProps = {
  payment: Order['payment'];
};

export const PaymentProviderLink: React.FC<PaymentProviderLinkProps> = ({
  payment,
}: PaymentProviderLinkProps) => {
  const getPaymentProviderIcon = () => {
    switch (payment.processor) {
      case PaymentProcessor.Stripe:
        switch (payment.subProcessor) {
          case PaymentSubProcessor.PayPal:
            return <PayPalxStripe />;
          case PaymentSubProcessor.Bancontact:
            return <BancontactxStripe />;
          case PaymentSubProcessor.Klarna:
            return <KlarnaxStripe />;
          case null:
            return <Stripe />;
        }
        return <Stripe />;
      case PaymentProcessor.Primer:
        return <Primer />;
      case PaymentProcessor.Alma:
        return <Alma />;
      case PaymentProcessor.Braintree:
        return <Paypal />;
    }
  };

  return (
    <a href={payment.processorTransactionUrl} target="_blank" rel="noreferrer">
      {getPaymentProviderIcon()}
    </a>
  );
};

PaymentProviderLink.displayName = 'PaymentProviderLink';
