type ChooseProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Choose: React.FC<ChooseProps> = ({ ...props }: ChooseProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99231 5.07559C7.19994 4.35853 6.25403 4 5.15458 4C3.99992 4 3.01462 4.39544 2.19866 5.18631C1.3827 5.96198 0.982422 6.90495 0.982422 8C0.982422 9.09506 1.3827 10.038 2.19866 10.8289C3.01462 11.6046 3.99992 12 5.15458 12C6.25403 12 7.19994 11.6415 7.99231 10.9375C8.78467 11.6415 9.73058 12 10.83 12C11.9847 12 12.97 11.6046 13.786 10.8289C14.6019 10.038 15.0176 9.09506 15.0176 8C15.0176 6.90495 14.6019 5.96198 13.786 5.18631C12.97 4.39544 11.9847 4 10.83 4C9.73058 4 8.78467 4.35853 7.99231 5.07559ZM4.70811 10.6616C5.0776 11.27 5.47788 11.5742 5.90896 11.5742C6.44779 11.5742 6.74031 10.7681 6.74031 9.4905C6.74031 8.85172 6.63254 8.1521 6.4324 7.37643C6.23226 6.60076 5.95514 5.93156 5.57025 5.3384C5.18537 4.74525 4.78509 4.44107 4.36941 4.44107C3.84597 4.44107 3.58425 5.09506 3.58425 6.41825C3.58425 7.07225 3.67662 7.78708 3.86136 8.57795C4.0615 9.35362 4.33862 10.0532 4.70811 10.6616ZM10.3836 10.6616C10.7531 11.27 11.1533 11.5742 11.5844 11.5742C12.1233 11.5742 12.4158 10.7681 12.4158 9.4905C12.4158 8.85172 12.308 8.1521 12.1079 7.37643C11.9077 6.60076 11.6306 5.93156 11.2457 5.3384C10.8608 4.74525 10.4605 4.44107 10.0449 4.44107C9.52142 4.44107 9.2597 5.09506 9.2597 6.41825C9.2597 7.07225 9.35207 7.78708 9.53682 8.57795C9.73696 9.35362 10.0141 10.0532 10.3836 10.6616Z"
        fill="currentColor"
      />
    </svg>
  );
};
