import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormAddress } from '@appchoose/address';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { ModalFooter, ModalHeader, ModalTitle } from '@appchoose/modal';

import {
  OrderSplitActionErrorReason,
  OrderTagShipment,
  RefundOrderCoverInput,
} from '../../types/generated';
import { Item, Order } from '../../types/order';
import { RefundForm } from '../open-complaint/open-complaint';
import { OtherActionsModalActionFormFields } from './other-actions-modal-action-form-fields';
import { OtherActionsCancelOrder } from './steps/other-actions-cancel-order';
import { OtherActionsChangeBillingAddress } from './steps/other-actions-change-billing-address';
import { OtherActionsChangeShippingAddress } from './steps/other-actions-change-shipping-address';
import { OtherActionsDeleteArticles } from './steps/other-actions-delete-articles';
import { OtherActionsMarkAsDelivered } from './steps/other-actions-mark-as-delivered';
import { OtherActionsRefund } from './steps/other-actions-refund';
import { OtherActionsShippingRefund } from './steps/other-actions-shipping-refund';
import { OtherActionsSplitArticles } from './steps/other-actions-split-articles';

type OtherActionsModalProps = {
  setIsOpen: (isOpen: boolean) => void;
  order?: Order;
  userKey: string | undefined;
};

export type OtherActionsForm = {
  billing: FormAddress;
  shipping: FormAddress;
  actionType: string;
  deliveryService: string;
  trackingNumber: string;
  allOrders: boolean;
  products: {
    product: Item;
    selected: boolean;
  }[];
  cancelOrderReason: string;
  putBackInStock: boolean;
} & RefundForm;

export type ActionOption = {
  value: string;
  text: string;
  description?: string;
  disabled?: boolean;
};

export type StepRef = {
  onNextStep: (data: OtherActionsForm) => Promise<void>;
};

export type StepProps = {
  step: number;
  userKey?: string;
  order?: Order;
  setNumberOfSteps: (step: number) => void;
  setSubmitTitle: (title: string | undefined) => void;
  setLoading: (isOpen: boolean) => void;
  setShowFooter?: (isShow: boolean) => void;
};

export const OtherActionsModal = ({
  setIsOpen,
  order,
  userKey,
}: OtherActionsModalProps) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [numberOfSteps, setNumberOfSteps] = useState(2);
  const [submitTitle, setSubmitTitle] = useState<string | undefined>();
  const stepRef = useRef<StepRef>(null);
  const [loading, setLoading] = useState(false);
  const [showFooter, setShowFooter] = useState(true);

  const form = useForm<OtherActionsForm>({
    mode: 'onTouched',
    defaultValues: {
      billing: {
        firstname: order?.buyer.firstName,
        lastname: order?.buyer.lastName,
        street: order?.buyer.address.street,
        street2: order?.buyer.address.streetAdditional ?? undefined,
        city: order?.buyer.address.city,
        bp: order?.buyer.address.zipCode,
        province: order?.buyer.address.county ?? undefined,
        countryCode: order?.buyer.address.countryCode,
        country: order?.buyer.address.country,
      },
      shipping: {
        firstname: order?.recipient.firstName,
        lastname: order?.recipient.lastName,
        street: order?.recipient.address.street,
        street2: order?.recipient.address.streetAdditional ?? undefined,
        city: order?.recipient.address.city,
        bp: order?.recipient.address.zipCode,
        province: order?.recipient.address.county ?? undefined,
        countryCode: order?.recipient.address.countryCode,
        country: order?.recipient.address.country,
        phone: order?.recipient.phoneNumber?.replace(/\s/g, '').trim(),
      },
      allOrders: true,
      products:
        order?.items?.map((product) => ({
          product: product,
          selected: false,
        })) ?? [],
      putBackInStock: false,
      deliveryService: order?.parcels?.[0]?.trackingCarrierSlug ?? undefined,
      trackingNumber: order?.parcels?.[0]?.trackingNumber ?? undefined,
      initiator: RefundOrderCoverInput.Supplier,
    },
  });

  const canBeCancelled = () => {
    const physicalWithNoTrackingNumber = !order?.parcels?.[0]?.trackingNumber;
    return (
      !order?.isCancelled &&
      (physicalWithNoTrackingNumber || order?.isFullDigital)
    );
  };

  const splitActionErrorReason = (reason: OrderSplitActionErrorReason) => {
    if (reason === OrderSplitActionErrorReason.AllItemsDigital) {
      return t('other_actions.split_order_reasons.all_items_digital');
    }
    if (reason === OrderSplitActionErrorReason.OrderCancelled) {
      return t('other_actions.split_order_reasons.order_cancelled');
    }
    if (reason === OrderSplitActionErrorReason.OrderReshipped) {
      return t('other_actions.split_order_reasons.order_reshipped');
    }
    if (reason === OrderSplitActionErrorReason.SomeClaimsPending) {
      return t('other_actions.split_order_reasons.some_claims_pending');
    }
    if (reason === OrderSplitActionErrorReason.SomeRefunds) {
      return t('other_actions.split_order_reasons.some_refunds');
    }
    if (reason === OrderSplitActionErrorReason.SomeReturnParcels) {
      return t('other_actions.split_order_reasons.some_return_parcels');
    }
    if (reason === OrderSplitActionErrorReason.SomeReturnSlips) {
      return t('other_actions.split_order_reasons.some_return_slips');
    }
    return undefined;
  };

  const nbProducts = order?.items?.length ?? 0;

  const actionOptions: ActionOption[] = [
    {
      value: 'mark_as_delivered',
      text: t('other_actions.actions.mark_as_delivered'),
      disabled: order?.tags?.shipment?.[0] === OrderTagShipment.Delivered,
    },
    {
      value: 'change_shipping_address',
      text: t('other_actions.actions.change_shipping_address'),
      disabled:
        (order?.tags?.shipment?.[0] !== OrderTagShipment.Pending &&
          order?.tags?.shipment?.[0] !== OrderTagShipment.InfoReceived) ||
        order?.hasBeenExported,
    },
    {
      value: 'change_billing_address',
      text: t('other_actions.actions.change_billing_address'),
    },
    {
      value: 'refund',
      text: t('other_actions.actions.refund'),
      disabled: !order?.items.some(
        (item) =>
          item.refundSummary.isRefundable &&
          !item.refundSummary.isTotallyRefunded
      ),
    },
    {
      value: 'shipping_refund',
      text: t('other_actions.actions.shipping_refund'),
      disabled:
        !order?.shipping.refundSummary.isRefundable ||
        order?.shipping.refundSummary.isTotallyRefunded,
    },
    nbProducts > 1
      ? {
          value: 'split_articles',
          text: t('other_actions.actions.split_articles'),
          disabled: (order?.splitActionErrorReasons?.length ?? 0) > 0,
          description:
            (order?.splitActionErrorReasons?.length &&
              splitActionErrorReason(order?.splitActionErrorReasons[0])) ||
            undefined,
        }
      : undefined,
    nbProducts > 1
      ? {
          value: 'delete_articles',
          text: t('other_actions.actions.delete_articles'),
          description: order?.hasBeenExported
            ? t('other_actions.step_action.already_exported')
            : undefined,
        }
      : undefined,
    {
      value: 'cancel_order',
      text: t('other_actions.actions.cancel_order'),
      description: order?.hasBeenExported
        ? t('other_actions.step_action.already_exported')
        : undefined,
      disabled: !canBeCancelled(),
    },
  ].filter((r): r is ActionOption => !!r);

  const goToPreviousStep = () => {
    setLoading(false);
    setSubmitTitle(undefined);
    setStep((prevStep) => prevStep - 1);
  };

  const onSubmit = async (data: OtherActionsForm) => {
    setLoading(true);
    setSubmitTitle(undefined);

    if (step < numberOfSteps - 1) {
      setLoading(false);
      setStep((prevStep) => prevStep + 1);
    } else {
      try {
        await stepRef.current?.onNextStep(data);
        setLoading(false);
        setIsOpen(false);
      } catch {
        setLoading(false);
      }
    }
  };

  form.watch('actionType');

  const steps = [
    {
      actionType: 'mark_as_delivered',
      component: OtherActionsMarkAsDelivered,
    },
    {
      actionType: 'change_shipping_address',
      component: OtherActionsChangeShippingAddress,
    },
    {
      actionType: 'change_billing_address',
      component: OtherActionsChangeBillingAddress,
    },
    {
      actionType: 'refund',
      component: OtherActionsRefund,
    },
    {
      actionType: 'shipping_refund',
      component: OtherActionsShippingRefund,
    },
    {
      actionType: 'split_articles',
      component: OtherActionsSplitArticles,
    },
    {
      actionType: 'delete_articles',
      component: OtherActionsDeleteArticles,
    },
    {
      actionType: 'cancel_order',
      component: OtherActionsCancelOrder,
    },
  ];

  const Step = steps.find(
    (step) => step.actionType === form.getValues('actionType')
  )?.component as React.ForwardRefExoticComponent<
    StepProps & React.RefAttributes<StepRef>
  >;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          <ModalHeader>
            <ModalTitle>
              {step === 0
                ? t('other_actions.title')
                : actionOptions.find(
                    (option) => option.value === form.getValues('actionType')
                  )?.text}
            </ModalTitle>
          </ModalHeader>

          {step === 0 ? (
            <OtherActionsModalActionFormFields
              order={order}
              options={actionOptions}
            />
          ) : (
            <Step
              ref={stepRef}
              step={step}
              userKey={userKey}
              order={order}
              setNumberOfSteps={setNumberOfSteps}
              setSubmitTitle={setSubmitTitle}
              setShowFooter={setShowFooter}
              setLoading={setLoading}
            />
          )}
        </div>

        {showFooter && (
          <ModalFooter>
            {step > 0 ? (
              <button
                type="button"
                onClick={goToPreviousStep}
                className="rounded px-2 py-1 text-sm font-bold text-green-900 outline-none focus-visible:ring-2 focus-visible:ring-gray-100"
              >
                {t('complaint.open_complaint.previous')}
              </button>
            ) : null}
            <Button appearance="primary" type="submit" disabled={loading}>
              {submitTitle ? (
                <span>{submitTitle}</span>
              ) : (
                <span>
                  {step === numberOfSteps - 1
                    ? t('return.return.confirm')
                    : t('return.return.next')}
                </span>
              )}
            </Button>
          </ModalFooter>
        )}
      </form>
    </Form>
  );
};

OtherActionsModal.displayName = 'OtherActionsModal';
