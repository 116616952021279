import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { toast } from '@appchoose/toast';

import { isProcessingState } from '../../stores/isProcessing';
import { selectedState } from '../../stores/selected';
import {
  TrackingStatus,
  useCreateInvoiceLazyQuery,
} from '../../types/generated';
import { Order } from '../../types/order';

type OrderInvoiceProps = {
  billingId: Order['billingId'];
  parcel: Order['parcels'][0];
};

export const OrderInvoice: React.FC<OrderInvoiceProps> = ({
  billingId,
  parcel,
}: OrderInvoiceProps) => {
  const { t } = useTranslation();

  const selected = useRecoilValue(selectedState);
  const setIsProcessing = useSetRecoilState(isProcessingState);

  const [
    createInvoiceLazyQuery,
    { data: createInvoiceData, error: createInvoiceError },
  ] = useCreateInvoiceLazyQuery({ errorPolicy: 'all' });

  useEffect(() => {
    if (createInvoiceData) {
      toast.success(t('order.email_sent'));
    }
  }, [createInvoiceData, t]);

  useEffect(() => {
    if (createInvoiceError) {
      toast.error(t('order.create_invoice_error'));
    }
  }, [createInvoiceError, t]);

  if (parcel?.trackingStatus !== TrackingStatus.Delivered) return null;

  const handleInvoice = async () => {
    setIsProcessing(true);

    if (billingId && selected?.user?.userKey) {
      const userKey = selected.user?.userKey;

      await createInvoiceLazyQuery({
        variables: {
          billingId: billingId,
          userKey,
        },
      });
    }

    setIsProcessing(false);
  };

  return (
    <button
      type="button"
      className="flex cursor-pointer items-center text-sm font-semibold text-green-900"
      onClick={() => handleInvoice()}
    >
      {t('order.actions.send_invoice_by_email')}
    </button>
  );
};

OrderInvoice.displayName = 'OrderInvoice';
