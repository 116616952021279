import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import { FormControl, FormField, FormItem } from '@appchoose/form';

import {
  ProductView,
  ProductViewContent,
  ProductViewProductDetails,
} from '../product-view/product-view';
import { OtherActionsForm } from './other-actions-modal';

export const OtherActionsModalSplitProductsFormFields = () => {
  const { t } = useTranslation();

  const form = useFormContext<OtherActionsForm>();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const ProductsErrorMessage =
    (form.formState.errors.products
      ?.filter?.((s) => s !== undefined)
      .map((s) => s?.selected?.type)
      .find((t) => t === 'atLeastOne') &&
      t(
        'other_actions.step_params.fields.products.validation_errors.at_least_one'
      )) ||
    (form.formState.errors.products
      ?.filter?.((s) => s !== undefined)
      .map((s) => s?.selected?.type)
      .find((t) => t === 'notAll') &&
      t('other_actions.step_params.fields.products.validation_errors.not_all'));

  return (
    <>
      <div className="flex flex-wrap gap-2">
        {fields.map((field, index) => (
          <label key={field.id} className="w-full">
            <ProductView isSelected={field.selected} isDisabled={false}>
              <ProductViewContent>
                <ProductViewProductDetails item={field.product} />
                <FormField
                  control={form.control}
                  name={`products.${index}.selected`}
                  rules={{
                    validate: {
                      atLeastOne: () =>
                        form.getValues('products').filter((s) => s.selected)
                          .length > 0,
                      notAll: () =>
                        form.getValues('products').filter((s) => !s.selected)
                          .length > 0,
                    },
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Checkbox
                          {...field}
                          value=""
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </ProductViewContent>
            </ProductView>
          </label>
        ))}
      </div>
      <div className="mt-2 text-xs text-red-600">{ProductsErrorMessage}</div>
    </>
  );
};
