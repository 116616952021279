export enum ConversationTab {
  Unhandled = 'UNHANDLED',
  AssignedToMe = 'ASSIGNED_TO_ME',
  AssignedTo = 'ASSIGNED_TO',
  Unassigned = 'UNASSIGNED',
  Usa = 'USA',
  Pinned = 'PINNED',
  ClaimAuto = 'CLAIM_AUTO',
  Read = 'READ',
  Search = 'SEARCH',
  Delivery = 'DELIVERY',
  Order = 'ORDER',
  PostOperation = 'POST_OPERATION',
  PreSales = 'PRE_SALES',
}
