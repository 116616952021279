import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import cn from '@appchoose/cn';
import Flag from '@appchoose/flag';
import Icon from '@appchoose/icon';
import Logo from '@appchoose/logo';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { Sidebar, SidebarContent } from '@appchoose/sidebar';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

import { config } from '../../app/config';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/dropdown-menu';
import { History } from '../../components/icons/history';
import { OperatorAvatar } from '../../components/operator-avatar/operator-avatar';
import { SaleGroupMessageModal } from '../../components/sale-group-message-modal/sale-group-message-modal';
import { useUserClaims } from '../../hooks/use-user-claims';
import { useAuth } from '../../stores/authcontext';
import { mobileMenuOpenState } from '../../stores/mobileMenuOpenState';
import { operatorsState } from '../../stores/operators';
import { statsState } from '../../stores/stats';
import { useUser } from '../../stores/usercontext';
import { UserRole, checkRoles } from '../../utils/role';
import { getFirstName } from '../../utils/string';

import './navbar.css';

const NavbarContent: React.FC = () => {
  const { signOut } = useAuth();
  const claims = useUserClaims();
  const { t } = useTranslation();

  const operators = useRecoilValue(operatorsState);

  const navigate = useNavigate();
  const location = useLocation();

  const { user: userAuthenticated } = useUser();

  const [displaySaleGroupMessage, setDisplaySaleGroupMessage] = useState(false);

  const operatorInboxes = operators?.filter(
    (op) => op.operatorId !== userAuthenticated?.uid
  );

  const stats = useRecoilValue(statsState);

  const assignedTo = (operatorId: string) => {
    return (
      stats.assignOperatorRepartition?.find((r) => r.operatorId === operatorId)
        ?.nbConversationAssigned ?? 0
    );
  };

  const linkItemClassName = (isActive: boolean) =>
    cn('text-sm leading-5.5 truncate', {
      'text-gray-900 font-semibold': isActive,
      'text-gray-700 font-normal': !isActive,
    });
  const statsItemClassName = (isActive: boolean) =>
    cn('flex flex-shrink-0 ml-2 text-xs', {
      'text-gray-900 font-semibold': isActive,
      'text-gray-700 font-normal': !isActive,
    });

  const handleHome = () => {
    navigate('/' + config.pathname.unhandled);
  };

  const contextMenu = [
    {
      id: 'logout',
      name: t('navbar.context_menu.logout'),
      onClick: () => signOut(),
    },
  ];

  return (
    <>
      <header className="mb-6 px-6">
        <Logo
          appearance={
            import.meta.env.REACT_APP_STAGE === 'production'
              ? undefined
              : 'white'
          }
          role="img"
          aria-label="Logo Choose"
          onClick={handleHome}
          className="cursor-pointer"
        />
      </header>
      <nav className="navbar-scroll-shadow flex flex-1 flex-col space-y-10 overflow-y-auto px-4">
        <div className="space-y-1">
          <NavLink
            to={`/${config.pathname.assignedTo}/${
              userAuthenticated?.uid ?? ''
            }`}
            className={({ isActive }) =>
              `flex items-center justify-between rounded px-2 py-0.75 text-sm ${
                isActive ? 'bg-white' : 'hover:bg-gray-50'
              }`
            }
          >
            <div className="flex items-center truncate">
              <img
                src={userAuthenticated?.providerData?.[0]?.photoURL ?? ''}
                className="mr-2 w-4 rounded-full"
              />
              <p
                className={linkItemClassName(
                  location.pathname.includes(
                    `${config.pathname.assignedTo}/${
                      userAuthenticated?.uid ?? ''
                    }`
                  )
                )}
              >
                {t('sidebar.me')}
              </p>
            </div>
            {assignedTo(userAuthenticated?.uid ?? '') &&
            assignedTo(userAuthenticated?.uid ?? '') > 0 ? (
              <p
                className={statsItemClassName(
                  location.pathname.includes(
                    `${config.pathname.assignedTo}/${
                      userAuthenticated?.uid ?? ''
                    }`
                  )
                )}
              >
                {assignedTo(userAuthenticated?.uid ?? '')}
              </p>
            ) : null}
          </NavLink>
          <NavLink
            to={`/${config.pathname.unhandled}`}
            className={({ isActive }) =>
              `flex items-center justify-between rounded px-2 py-0.75 text-sm ${
                isActive ? 'bg-white' : 'hover:bg-gray-50'
              }`
            }
          >
            <div className="flex items-center truncate">
              <Icon
                className="mr-2 flex shrink-0 text-gray-700"
                icon="profileMultiple"
              />
              <p
                className={linkItemClassName(
                  location.pathname.includes('unhandled')
                )}
              >
                {t('sidebar.all')}
              </p>
            </div>
            {stats?.unhandled && stats?.unhandled > 0 ? (
              <p
                className={statsItemClassName(
                  location.pathname.includes('unhandled')
                )}
              >
                {stats.unhandled}
              </p>
            ) : null}
          </NavLink>
          <NavLink
            to={`/${config.pathname.unassigned}`}
            className={({ isActive }) =>
              `flex items-center justify-between rounded px-2 py-0.75 text-sm ${
                isActive ? 'bg-white' : 'hover:bg-gray-50'
              }`
            }
          >
            <div className="flex items-center truncate">
              <Icon
                className="mr-2 flex shrink-0 text-gray-700"
                icon="profileDisable"
              />
              <div
                className={linkItemClassName(
                  location.pathname.includes('unassigned')
                )}
              >
                {t('sidebar.unassigned')}
              </div>
            </div>
            {stats?.unassigned && stats?.unassigned > 0 ? (
              <p
                className={statsItemClassName(
                  location.pathname.includes('unassigned')
                )}
              >
                {stats.unassigned}
              </p>
            ) : null}
          </NavLink>
          <NavLink
            to={`/${config.pathname.pinned}`}
            className={({ isActive }) =>
              `flex items-center justify-between rounded px-2 py-0.75 text-sm ${
                isActive ? 'bg-white' : 'hover:bg-gray-50'
              }`
            }
          >
            <div className="flex items-center truncate">
              <Icon className="mr-2 flex shrink-0 text-gray-700" icon="tack" />
              <p
                className={linkItemClassName(
                  location.pathname.includes('pinned')
                )}
              >
                {t('sidebar.pinned')}
              </p>
            </div>

            {stats?.pinned && stats?.pinned > 0 ? (
              <p
                className={statsItemClassName(
                  location.pathname.includes('pinned')
                )}
              >
                {stats.pinned}
              </p>
            ) : null}
          </NavLink>
        </div>
        <CollapsiblePrimitive.Root defaultOpen={true}>
          <div className="space-y-2">
            <CollapsiblePrimitive.Trigger className="flex w-full items-center justify-between px-2 py-1 [&[data-state=closed]>svg]:-rotate-90">
              <p className="text-xs font-semibold uppercase tracking-wider text-gray-700">
                {t('sidebar.view')}
              </p>
              <Icon icon="arrowDown" className={`text-gray-700`} />
            </CollapsiblePrimitive.Trigger>
            <CollapsiblePrimitive.Content className="space-y-1">
              <NavLink
                to={`/${config.pathname.preSales}`}
                className={({ isActive }) =>
                  `flex items-center justify-between rounded px-2 py-0.75 text-sm text-gray-700 ${
                    isActive ? 'bg-white' : 'hover:bg-gray-50'
                  }`
                }
              >
                <div className="flex items-center truncate">
                  <History className="mr-2 shrink-0" />
                  <div
                    className={linkItemClassName(
                      location.pathname.includes('pre-sales')
                    )}
                  >
                    {t('sidebar.pre_sales')}
                  </div>
                </div>
                {stats?.categoryPreSales && stats?.categoryPreSales > 0 ? (
                  <p
                    className={statsItemClassName(
                      location.pathname.includes('pre-sales')
                    )}
                  >
                    {stats.categoryPreSales}
                  </p>
                ) : null}
              </NavLink>
              <NavLink
                to={`/${config.pathname.delivery}`}
                className={({ isActive }) =>
                  `flex items-center justify-between rounded px-2 py-0.75 text-sm text-gray-700 ${
                    isActive ? 'bg-white' : 'hover:bg-gray-50'
                  }`
                }
              >
                <div className="flex items-center truncate">
                  <Icon icon="truck" className="mr-2 shrink-0" />
                  <div
                    className={linkItemClassName(
                      location.pathname.includes('delivery/')
                    )}
                  >
                    {t('sidebar.delivery')}
                  </div>
                </div>
                {stats?.categoryDelivery && stats?.categoryDelivery > 0 ? (
                  <p
                    className={statsItemClassName(
                      location.pathname.includes('delivery/')
                    )}
                  >
                    {stats.categoryDelivery}
                  </p>
                ) : null}
              </NavLink>
              <NavLink
                to={`/${config.pathname.order}`}
                className={({ isActive }) =>
                  `flex items-center justify-between rounded px-2 py-0.75 text-sm text-gray-700 ${
                    isActive ? 'bg-white' : 'hover:bg-gray-50'
                  }`
                }
              >
                <div className="flex items-center truncate">
                  <Icon icon="orders" className="mr-2 shrink-0" />
                  <div
                    className={linkItemClassName(
                      location.pathname.includes('order')
                    )}
                  >
                    {t('sidebar.order')}
                  </div>
                </div>
                {stats?.categoryOrder && stats?.categoryOrder > 0 ? (
                  <p
                    className={statsItemClassName(
                      location.pathname.includes('order')
                    )}
                  >
                    {stats.categoryOrder}
                  </p>
                ) : null}
              </NavLink>
              <NavLink
                to={`/${config.pathname.postOperation}`}
                className={({ isActive }) =>
                  `flex items-center justify-between rounded px-2 py-0.75 text-sm text-gray-700 ${
                    isActive ? 'bg-white' : 'hover:bg-gray-50'
                  }`
                }
              >
                <div className="flex items-center truncate">
                  <Icon icon="tag" className="mr-2 shrink-0" />
                  <div
                    className={linkItemClassName(
                      location.pathname.includes('post-operation')
                    )}
                  >
                    {t('sidebar.post_operation')}
                  </div>
                </div>
                {stats?.categoryPostOperation &&
                stats?.categoryPostOperation > 0 ? (
                  <p
                    className={statsItemClassName(
                      location.pathname.includes('post-operation')
                    )}
                  >
                    {stats.categoryPostOperation}
                  </p>
                ) : null}
              </NavLink>
              <NavLink
                to={`/${config.pathname.usa}`}
                className={({ isActive }) =>
                  `flex items-center justify-between rounded px-2 py-0.75 text-sm text-gray-700 ${
                    isActive ? 'bg-white' : 'hover:bg-gray-50'
                  }`
                }
              >
                <div className="flex items-center truncate">
                  <Flag flag="US" className="mr-2 h-4 w-4 shrink-0" />
                  <div
                    className={linkItemClassName(
                      location.pathname.includes('usa')
                    )}
                  >
                    {t('sidebar.usa')}
                  </div>
                </div>
                {stats?.usa && stats?.usa > 0 ? (
                  <p
                    className={statsItemClassName(
                      location.pathname.includes('usa')
                    )}
                  >
                    {stats.usa}
                  </p>
                ) : null}
              </NavLink>
            </CollapsiblePrimitive.Content>
          </div>
        </CollapsiblePrimitive.Root>
        <CollapsiblePrimitive.Root>
          <div className="space-y-2">
            <CollapsiblePrimitive.Trigger className="flex w-full items-center justify-between px-2 py-1 [&[data-state=closed]>svg]:-rotate-90">
              <p className="text-xs font-semibold uppercase tracking-wider text-gray-700">
                {t('sidebar.team')}
              </p>
              <Icon icon="arrowDown" className={`text-gray-700`} />
            </CollapsiblePrimitive.Trigger>
            <CollapsiblePrimitive.Content className="space-y-1">
              {operatorInboxes.map((operator) => (
                <NavLink
                  key={operator.operatorId}
                  to={`/${config.pathname.assignedTo}/${operator.operatorId}`}
                  className={({ isActive }) =>
                    `flex items-center justify-between rounded px-2 py-0.75 text-sm ${
                      isActive ? 'bg-white' : 'hover:bg-gray-50'
                    }`
                  }
                >
                  <div className="flex items-center space-x-2 truncate">
                    <OperatorAvatar
                      operatorId={operator.operatorId}
                      size="small"
                      showBorder={false}
                    />
                    <p
                      className={linkItemClassName(
                        location.pathname.includes(operator.operatorId)
                      )}
                    >
                      {getFirstName(operator.displayName)}
                    </p>
                  </div>
                  {assignedTo(operator.operatorId) > 0 ? (
                    <p
                      className={statsItemClassName(
                        location.pathname.includes(operator.operatorId)
                      )}
                    >
                      {assignedTo(operator.operatorId)}
                    </p>
                  ) : null}
                </NavLink>
              ))}
            </CollapsiblePrimitive.Content>
          </div>
        </CollapsiblePrimitive.Root>
        <div className="space-y-0.75">
          <NavLink
            to={`/${config.pathname.claimAuto}`}
            className={({ isActive }) =>
              `flex items-center justify-between space-x-2 rounded px-2 py-0.75 text-sm ${
                isActive ? 'bg-white' : 'hover:bg-gray-50'
              }`
            }
          >
            <div className="flex items-center truncate">
              <Icon
                className="mr-2 flex shrink-0 text-gray-700"
                icon="loader"
              />
              <p
                className={linkItemClassName(
                  location.pathname.includes('claim-auto')
                )}
              >
                {t('sidebar.claim_auto')}
              </p>
            </div>
            {stats?.categoryBotProcessing &&
            stats?.categoryBotProcessing > 0 ? (
              <p
                className={statsItemClassName(
                  location.pathname.includes('claim-auto')
                )}
              >
                {stats.categoryBotProcessing}
              </p>
            ) : null}
          </NavLink>
          <NavLink
            to={`/${config.pathname.archives}`}
            className={({ isActive }) =>
              `flex items-center space-x-2 rounded px-2 py-0.75 text-sm ${
                isActive ? 'bg-white' : 'hover:bg-gray-50'
              }`
            }
          >
            <Icon className="flex shrink-0 text-gray-700" icon="check" />
            <p
              className={linkItemClassName(
                location.pathname.includes('archives')
              )}
            >
              {t('sidebar.archived')}
            </p>
          </NavLink>
        </div>
      </nav>
      <footer className="px-6">
        <Modal
          open={displaySaleGroupMessage}
          onOpenChange={setDisplaySaleGroupMessage}
        >
          <ModalTrigger
            type="button"
            className="my-6 flex cursor-pointer items-center justify-start space-x-1 disabled:cursor-not-allowed disabled:opacity-50"
            disabled={
              !checkRoles(
                {
                  onlyAdmin: false,
                  requireRole: [
                    UserRole.CONTACT,
                    UserRole.ACCOUNTING,
                    UserRole.FULL_ACCESS,
                  ],
                },
                claims
              )
            }
          >
            <Icon icon="write" className="text-gray-700" />
            <p className="whitespace-nowrap text-sm font-normal text-gray-700">
              {t('sidebar.group_messages')}
            </p>
          </ModalTrigger>
          <ModalContent scrollable>
            <SaleGroupMessageModal
              onClose={() => setDisplaySaleGroupMessage(false)}
            />
          </ModalContent>
        </Modal>
        <hr className="h-px w-full border-0 bg-gray-300" />
        <div className="pt-6">
          <DropdownMenu>
            <DropdownMenuTrigger className="group flex shrink-0 items-center space-x-4">
              <img
                className="w-6 cursor-pointer rounded-full"
                src={userAuthenticated?.providerData?.[0]?.photoURL ?? ''}
              />
              <p className="text-sm font-normal text-gray-700">
                {userAuthenticated?.displayName}
              </p>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="z-50 min-w-52">
              {contextMenu.map((menuItem) => (
                <DropdownMenuItem key={menuItem.id} onSelect={menuItem.onClick}>
                  {menuItem.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </footer>
    </>
  );
};

export const Navbar: React.FC = () => {
  const location = useLocation();

  const [mobileMenuOpen, setMobileMenuOpen] =
    useRecoilState(mobileMenuOpenState);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  return (
    <>
      <Sidebar open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
        <SidebarContent
          left
          className="flex max-w-xs flex-1 flex-col bg-gray-100 py-6"
        >
          <NavbarContent />
        </SidebarContent>
      </Sidebar>

      {/* Static sidebar for desktop */}
      <aside className="hidden lg:flex lg:shrink-0">
        <div className="flex w-[12.5rem] flex-col bg-gray-100 py-6">
          <NavbarContent />
        </div>
      </aside>
    </>
  );
};

Navbar.displayName = 'Navbar';
