import { useEffect, useRef } from 'react';

import Lottie from 'lottie-web/build/player/lottie_light';

import lottieData from './animated-check.json';

type AnimatedCheckProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'div'>;

export const AnimatedCheck: React.FC<AnimatedCheckProps> = ({
  className,
  ...props
}: AnimatedCheckProps) => {
  const ref = useRef(null);
  useEffect(() => {
    const instance = Lottie.loadAnimation({
      container: ref?.current as any,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: lottieData,
    });

    return () => instance.destroy();
  }, []);

  return (
    <div id="animated-check" ref={ref} className={className} {...props}></div>
  );
};
