import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import { TFunction } from 'i18next';

import { OrderTagReturn } from '../../types/generated';
import { OrderBadge } from './order-badge';

export const getOrderReturnLabelFromTag = (
  tag: OrderTagReturn,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagReturn.Canceled:
      return t('order.status.return.canceled');
    case OrderTagReturn.Delivered:
      return t('order.status.return.delivered');
    case OrderTagReturn.InProgress:
      return t('order.status.return.in_progress');
    case OrderTagReturn.Requested:
      return t('order.status.return.requested');
    case OrderTagReturn.Validated:
      return t('order.status.return.accepted');
  }
};

export const OrderReturnBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagReturn;
}> = ({ isCancelled, tag }) => {
  const { t } = useTranslation();

  if (tag === OrderTagReturn.None) return null;

  return (
    <OrderBadge>
      <div className="flex items-center gap-x-1">
        <span className={cn({ 'line-through': isCancelled })}>
          {getOrderReturnLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
