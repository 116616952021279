import { OperatorActive } from '../../types/generated';
import { IsReplying } from '../icons/is-replying';
import { IsViewing } from '../icons/is-viewing';
import { OperatorAvatar } from '../operator-avatar/operator-avatar';

import './operators-status.scss';

export enum OperatorState {
  IsReplying,
  IsReading,
}

type OperatorsStatusProps = {
  operators: OperatorActive[];
  state: OperatorState;
  size: 'small' | 'medium';
  className?: string;
};

export const OperatorsStatus: React.FC<OperatorsStatusProps> = ({
  operators,
  state,
  size,
  className,
}: OperatorsStatusProps) => {
  let avatars = operators.filter((op) => op.message === '');
  if (state === OperatorState.IsReplying)
    avatars = operators.filter((op) => op.message !== '');

  return avatars.length > 0 ? (
    <div className={`flex ${className ? className : ''}`}>
      <div className="relative flex -space-x-1 overflow-hidden">
        {avatars.map((avatar, index) => (
          <OperatorAvatar
            operatorId={avatar.operatorId}
            size={size}
            showBorder={avatars.length > 1}
            style={{
              zIndex: index,
            }}
            key={index}
          />
        ))}
      </div>
      {avatars.length > 0 ? (
        state === OperatorState.IsReplying ? (
          <IsReplying
            className={`operators-status-icon text-gray-500 ${size}`}
          />
        ) : (
          <IsViewing
            className={`operators-status-icon text-gray-500 ${size}`}
          />
        )
      ) : null}
    </div>
  ) : null;
};

OperatorsStatus.displayName = 'OperatorsStatus';
