import { useEffect } from 'react';
import { useLocalStorage, useUpdateEffect } from 'react-use';
import { useRecoilState } from 'recoil';

import { sortState } from '../../stores/messages';
import { SortType } from '../../types/generated';

export const useSort = (): [SortType, (sort: SortType) => void] => {
  const [cachedSort, setCachedSort] = useLocalStorage<string>(
    'sortingOption',
    'ASC'
  );
  const [sort, setSort] = useRecoilState(sortState);

  useEffect(() => {
    let defaultSort;
    switch (cachedSort) {
      case 'ASC':
        defaultSort = SortType.Asc;
        break;
      case 'DESC':
        defaultSort = SortType.Desc;
        break;
      case 'AI_SCORING':
        defaultSort = SortType.AiScoring;
        break;
      default:
        defaultSort = SortType.Asc;
        break;
    }
    setSort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    setCachedSort(sort);
  }, [sort, setCachedSort]);

  return [sort, setSort];
};
