import { useTranslation } from 'react-i18next';

export const UnauthorizedPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center justify-center">
      {t('access_denied')}
    </div>
  );
};
