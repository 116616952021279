import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';
import * as Collapsible from '@radix-ui/react-collapsible';

import {
  OrderTagReturn,
  TrackingStatus,
  useCancelReturnMutation,
  useValidateOrderItemsReturnedMutation,
} from '../../types/generated';
import { Order, ReturnedItem } from '../../types/order';
import { transformPrice } from '../../utils/currency';
import { getOrderReturnLabelFromTag } from '../order-status-badge/order-return-badge';

type OrderReturnProps = {
  order: Order;
};

type ItemsGroupedByVariants = {
  item: ReturnedItem;
  quantity: number;
};

export const OrderReturn: React.FC<OrderReturnProps> = ({
  order,
}: OrderReturnProps) => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [
    validateOrderItemsReturnedMutation,
    { error: validateOrderItemsReturnedError, loading: isLoadingValidReturn },
  ] = useValidateOrderItemsReturnedMutation();
  const [
    cancelReturnMutation,
    { error: cancelReturnError, loading: isLoadingCancelReturn },
  ] = useCancelReturnMutation();

  useEffect(() => {
    if (validateOrderItemsReturnedError) {
      toast.error(t('return.valid_return_error'));
    }
  }, [t, validateOrderItemsReturnedError]);

  useEffect(() => {
    if (cancelReturnError) {
      toast.error(t('return.delete_return_error'));
    }
  }, [cancelReturnError, t]);

  if (order.returnSlips.length === 0 && order.returnParcels.length === 0)
    return null;

  const returnSlip = order.returnSlips?.[0];
  const returnParcel = order.returnParcels?.[0];
  const returnedItems = returnParcel?.items ?? returnSlip?.items;

  const totalReturnedItems = returnedItems.reduce(
    (acc, val) => acc + val.priceSold.valueWithVat,
    0
  );
  const returnedItemsGrouped =
    returnedItems?.reduce<ItemsGroupedByVariants[]>((acc, val) => {
      let itemGrouped = acc.find(
        (itemGrouped) =>
          itemGrouped.item.productVariantId === val.productVariantId
      );

      if (!itemGrouped) {
        itemGrouped = { item: val, quantity: 0 };
        acc.push(itemGrouped);
      }

      itemGrouped.quantity++;

      return acc;
    }, []) ?? [];

  const validateOrderItemsReturned = async (event: React.MouseEvent) => {
    event.stopPropagation();

    await validateOrderItemsReturnedMutation({
      variables: {
        orderId: order.id,
      },
    });
    if (order.returnParcels.length > 0)
      order.returnParcels[0].trackingNumber = '';
  };

  const deleteReturn = async (event: React.MouseEvent) => {
    event.stopPropagation();

    await cancelReturnMutation({
      variables: {
        orderId: order.id,
      },
    });
    if (order.returnParcels.length > 0)
      order.returnParcels[0].trackingNumber = '';
  };

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <div className="-m-4 mb-4 flex flex-col items-center rounded-t-sm bg-gray-700 p-4 text-white">
        <Collapsible.Trigger className="w-full">
          <h3 className="flex items-center justify-between text-xs">
            {t('order.returns.return_details')}
            <Icon
              icon="arrowDown"
              className={cn({
                'rotate-180': open,
              })}
            />
          </h3>
        </Collapsible.Trigger>

        <Collapsible.Content className="w-full space-y-2">
          <span className="text-xs">
            {transformPrice(
              totalReturnedItems,
              i18n.language === 'fr' ? 'fr' : 'en',
              returnedItems?.[0]?.priceSold.currency || 'EUR'
            )}
          </span>
          <div className="space-y-1">
            <p className="text-sm font-semibold">
              {getOrderReturnLabelFromTag(order.tags.return[0], t)}
            </p>

            <a
              href={returnParcel?.trackingUrl ?? returnSlip?.trackingUrl}
              target="_blank"
              rel="noreferrer"
              className="mb-2 cursor-pointer text-xs"
            >
              {returnParcel?.trackingCarrierSlug ??
                returnSlip?.trackingCarrierSlug}{' '}
              -{' '}
              <span className="font-semibold">
                #{returnParcel?.trackingNumber ?? returnSlip?.trackingNumber}
              </span>
            </a>
          </div>
          <div className="space-y-1">
            <h4 className="text-xs font-semibold">
              {t('order.returns.article', { count: returnedItems.length })} (
              {returnedItems.length})
            </h4>
            <div className="space-y-2">
              {returnedItemsGrouped.map(({ item, quantity }) => (
                <div
                  className="flex flex-row items-start"
                  key={item.productVariantId}
                >
                  <img
                    src={item.imageUrl}
                    className="mr-2 size-14 rounded border-0.5 border-gray-300 object-cover"
                  />
                  <div className="flex flex-col justify-center">
                    <p className="text-sm">{item.name}</p>
                    {quantity > 1 ? (
                      <p className="text-sm">
                        x<span>{quantity}</span>
                      </p>
                    ) : null}
                    {item.size ? (
                      <p className="text-xs">
                        {t('order.product.size')} <span>{item.size}</span>
                      </p>
                    ) : null}
                    {item.priceSold ? (
                      <p className="text-xs">
                        {transformPrice(
                          item.priceSold.valueWithVat,
                          i18n.language === 'fr' ? 'fr' : 'en',
                          item.priceSold.currency || 'EUR'
                        )}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col">
            {returnParcel?.trackingStatus === TrackingStatus.Delivered &&
            returnedItems.length > 0 &&
            order.tags.return[0] !== OrderTagReturn.Canceled &&
            order.tags.return[0] !== OrderTagReturn.Validated ? (
              <div className="flex justify-between">
                <button
                  type="button"
                  className="flex cursor-pointer text-sm font-bold disabled:cursor-not-allowed"
                  onClick={(event) => validateOrderItemsReturned(event)}
                  disabled={isLoadingValidReturn}
                >
                  {t('order.returns.validate_return_and_refund')}
                </button>
                {isLoadingValidReturn ? <Loader className="size-4" /> : null}
              </div>
            ) : null}

            {order.tags.return[0] !== OrderTagReturn.Canceled &&
            order.tags.return[0] !== OrderTagReturn.Validated ? (
              <div className="flex justify-between">
                <button
                  type="button"
                  className="flex cursor-pointer text-sm font-bold disabled:cursor-not-allowed"
                  onClick={(event) => deleteReturn(event)}
                  disabled={isLoadingCancelReturn}
                >
                  {t('cancel')}
                </button>
                {isLoadingCancelReturn ? <Loader className="size-4" /> : null}
              </div>
            ) : null}
          </div>
        </Collapsible.Content>
      </div>
    </Collapsible.Root>
  );
};

OrderReturn.displayName = 'OrderReturn';
