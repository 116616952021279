import { atom, selector } from 'recoil';

export type ConversationEmail = {
  fromChoose: boolean;
  body: string;
  email: string;
  createdAt: string;
  isFixed: boolean;
  isPinned: boolean;
};

export const selectedEmailState = atom<ConversationEmail[] | undefined>({
  key: 'selectedEmailState',
  default: undefined,
});

export const firstEmailFromSelectedEmailState = selector({
  key: 'firstEmailFromSelectedEmailState',
  get: ({ get }) => {
    const conversationEmails = get(selectedEmailState);
    return conversationEmails?.[0];
  },
});

export const pinnedEmailsFromSelectedEmailState = selector({
  key: 'pinnedEmailsFromSelectedEmailState',
  get: ({ get }) => {
    const conversationEmails = get(selectedEmailState);
    if (conversationEmails && conversationEmails.length > 0) {
      return conversationEmails.filter((c) => c.isPinned).length > 0;
    }
    return false;
  },
});

export const fixedEmailsFromSelectedEmailState = selector({
  key: 'fixedEmailsFromSelectedEmailState',
  get: ({ get }) => {
    const conversationEmails = get(selectedEmailState);
    if (conversationEmails && conversationEmails.length > 0) {
      return conversationEmails.filter((c) => !c.isFixed).length === 0;
    }
    return false;
  },
});
