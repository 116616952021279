import React from 'react';

import emojis from '../../utils/emoji';

type EmojiPopoverContentProps = {
  selectSmiley: (emoji: string) => void;
};

export const EmojiPopoverContent = ({
  selectSmiley,
}: EmojiPopoverContentProps) => {
  const options = emojis.map((emoji) => ({
    label: emoji,
    onClick: () => selectSmiley(emoji),
  }));
  return (
    <div className="grid" style={{ gridTemplateColumns: 'repeat(12, 1fr)' }}>
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          className={
            'emoji-fix flex items-center justify-center border-none hover:bg-gray-100'
          }
          style={{ width: '24px' }}
          onClick={option.onClick}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};
