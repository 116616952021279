import { atom, selector } from 'recoil';

import { Email, Message, SortType } from '../types/generated';

export const messagesState = atom<Message[] | undefined>({
  key: 'messagesState',
  default: undefined,
});

export const emailsState = atom<Email[] | undefined>({
  key: 'emailsState',
  default: undefined,
});

export const sortState = atom<SortType>({
  key: 'sortState',
  default: SortType.Asc,
});

export const messagesOrEmailsDataState = selector({
  key: 'messagesOrEmailsDataState',
  get: ({ get }) => {
    const messages = get(messagesState);
    const emails = get(emailsState);
    const sort = get(sortState);

    switch (sort) {
      case SortType.Asc:
        return [...(messages ?? []), ...(emails ?? [])].slice().sort((a, b) => {
          return new Date(a.createdAt ?? '').getTime() <
            new Date(b.createdAt ?? '').getTime()
            ? -1
            : 1;
        });
      case SortType.Desc:
        return [...(messages ?? []), ...(emails ?? [])].slice().sort((a, b) => {
          return new Date(a.createdAt ?? '').getTime() >
            new Date(b.createdAt ?? '').getTime()
            ? -1
            : 1;
        });
      case SortType.AiScoring:
      default:
        return [...(messages ?? []), ...(emails ?? [])].slice();
    }
  },
});
