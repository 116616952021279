import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

import { ordersLoadingState } from '../../stores/ordersLoading';
import {
  selectedOrderIdActiveState,
  selectedOrdersState,
  selectedState,
} from '../../stores/selected';
import { scrollIntoOrder } from '../../utils/order';
import { UserSidebarOrder } from './user-sidebar-order';

export const UserSidebarOrders: React.FC = () => {
  const { t } = useTranslation();

  const selected = useRecoilValue(selectedState);
  const selectedOrders = useRecoilValue(selectedOrdersState);
  const [selectedOrderIdActive, setSelectedOrderIdActive] = useRecoilState(
    selectedOrderIdActiveState
  );
  const ordersLoading = useRecoilValue(ordersLoadingState);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('order_id')) {
      setSelectedOrderIdActive(searchParams.get('order_id') ?? '');
    } else if (searchParams.has('q') && searchParams.get('q')?.length === 25) {
      setSelectedOrderIdActive(searchParams.get('q') ?? '');
    } else {
      setSelectedOrderIdActive(undefined);
    }
  }, [location.search, setSelectedOrderIdActive]);

  useEffect(() => {
    if (selectedOrderIdActive && selectedOrders) {
      scrollIntoOrder(selectedOrderIdActive);
    }
  }, [selectedOrderIdActive, selectedOrders]);

  if (!selected) return null;

  if (!selectedOrders) return null;

  return (
    <div>
      <div className="mb-6">
        <CollapsiblePrimitive.Root defaultOpen={true}>
          <CollapsiblePrimitive.Trigger className="group flex w-full items-center justify-between px-4 font-bold text-gray-900 hover:bg-gray-100 data-[state=open]:mb-4 data-[state=closed]:py-6 data-[state=open]:pb-2 data-[state=open]:pt-6">
            <div className="flex items-end">
              <h4 className="font-bold text-gray-900">{t('sidebar.orders')}</h4>
              <span className="ml-2 text-sm font-normal leading-5.5 text-gray-500">
                {selectedOrders.length}
              </span>
            </div>
            <Icon
              icon="plus"
              size="large"
              className="text-gray-500 group-data-[state=open]:hidden"
            />
            <Icon
              icon="minus"
              size="large"
              className="text-gray-500 group-data-[state=closed]:hidden"
            />
          </CollapsiblePrimitive.Trigger>
          <CollapsiblePrimitive.Content className="flex flex-col items-center space-y-4 px-4">
            {ordersLoading && <Loader className="size-10" />}
            {!ordersLoading &&
              selectedOrders.map((order) => (
                <UserSidebarOrder order={order} key={order.id} />
              ))}
          </CollapsiblePrimitive.Content>
        </CollapsiblePrimitive.Root>
      </div>
    </div>
  );
};

UserSidebarOrders.displayName = 'UserSidebarOrders';
