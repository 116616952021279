import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { toast } from '@appchoose/toast';

import { operatorsState } from '../../stores/operators';
import { statsState } from '../../stores/stats';
import { categoriesState, templatesState } from '../../stores/templates';
import {
  CategoryData,
  OperatorUser,
  TemplateData,
  useListOperatorsQuery,
  useStatsQuery,
  useTemplatesQuery,
} from '../../types/generated';
import { Navbar } from './navbar';

import './Dashboard-Screen.scss';

export const DashboardScreen: React.FC = () => {
  const { data: listOperatorsData, error: listOperatorsError } =
    useListOperatorsQuery();
  const { data: statsData } = useStatsQuery();
  const { data: templatesData } = useTemplatesQuery();

  const setCategories = useSetRecoilState(categoriesState);
  const setTemplates = useSetRecoilState(templatesState);
  const setStats = useSetRecoilState(statsState);
  const setOperators = useSetRecoilState(operatorsState);

  useEffect(() => {
    if (listOperatorsData?.listOperators) {
      setOperators(
        listOperatorsData.listOperators
          .filter((op): op is OperatorUser => !!op)
          .sort((a, b) => {
            const nameA = a.displayName?.toUpperCase() ?? '';
            const nameB = b.displayName?.toUpperCase() ?? '';
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
      );
    }
  }, [listOperatorsData]);

  useEffect(() => {
    if (listOperatorsError) {
      toast.error('Une erreur est survenue en récupérant la liste des agents');
    }
  }, [listOperatorsError]);

  useEffect(() => {
    if (statsData?.stats) {
      setStats(statsData.stats);
    }
  }, [statsData]);

  useEffect(() => {
    if (templatesData?.templates) {
      setTemplates(
        templatesData?.templates?.templates?.filter(
          (template): template is TemplateData => !!template
        )
      );
      setCategories(
        templatesData?.templates?.categories?.filter(
          (category): category is CategoryData => !!category
        )
      );
    }
  }, [templatesData]);

  return (
    <div className="dashboard flex h-screen w-screen">
      <Navbar />
      <Outlet />
    </div>
  );
};

DashboardScreen.displayName = 'DashboardScreen';
