type IsViewingProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const IsViewing: React.FC<IsViewingProps> = ({
  ...props
}: IsViewingProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3" width="14" height="14" rx="7" fill="currentColor" />
      <path
        d="M5.5 7C5.5 7 7.13636 4 10 4C12.8636 4 14.5 7 14.5 7C14.5 7 12.8636 10 10 10C7.13636 10 5.5 7 5.5 7Z"
        fill="white"
        stroke="white"
        strokeWidth="0.818182"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.818182"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="4.5" cy="12.5" r="2.5" fill="currentColor" />
      <circle cx="1" cy="15" r="1" fill="currentColor" />
    </svg>
  );
};
