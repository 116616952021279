import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input, { InputAdornment } from '@appchoose/input';

import { Item } from '../../../../types/order';
import { transformPrice } from '../../../../utils/currency';
import { RefundForm } from '../../../open-complaint/open-complaint';
import { RefundProduct } from './other-actions-refund-product';

type RefundProductGroupProps = {
  handleUnselectProduct: (product: Item) => void;
  fields: FieldArrayWithId<RefundForm, 'selected', 'id'>[];
};

export const RefundProductGroup: React.FC<RefundProductGroupProps> = ({
  handleUnselectProduct,
  fields,
}: RefundProductGroupProps) => {
  const { i18n, t } = useTranslation();
  const form = useFormContext<RefundForm>();

  return (
    <div
      className={`rounded border-2 border-green-900 p-4 ${
        fields.length ? '' : 'hidden'
      }`}
    >
      <div className="space-y-4">
        {fields.map((field) => (
          <RefundProduct
            key={field.id}
            checkboxIdentifier={field.product.id}
            defaultChecked={true}
            handleUnselectProduct={() => handleUnselectProduct(field.product)}
            image={field.product.imageUrl}
            priceSold={transformPrice(
              field.product.priceSold.valueWithVat,
              i18n.language === 'fr' ? 'fr' : 'en',
              field.product.priceSold.currency || 'EUR'
            )}
            refundAmount={Math.round(
              field.product.refundSummary.refundedAmountInPercentage
            )}
            size={field.product.size}
            title={field.product.name}
          />
        ))}
      </div>
      <div className="pt-4">
        <FormField
          control={form.control}
          name="percentage"
          rules={{
            min: 1,
            max: Math.min(
              ...fields.map<number>((field) =>
                Math.round(
                  field.product.refundSummary
                    .remainingToRefundAmountInPercentage
                )
              )
            ),
            required: true,
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('other_actions.refund.percentage_to_refund')}
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  onChange={(event) => {
                    field.onChange(
                      !isNaN(event.target.valueAsNumber)
                        ? event.target.valueAsNumber
                        : null
                    );
                  }}
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon icon="percent" className="text-gray-900" />
                    </InputAdornment>
                  }
                  className="spin-button-none"
                />
              </FormControl>
              <FormMessage match="required">
                {t('other_actions.refund.validation_errors.required')}
              </FormMessage>
              <FormMessage match="min">
                {t('other_actions.refund.validation_errors.min_value')}
              </FormMessage>
              <FormMessage match="max">
                {t('other_actions.refund.validation_errors.max_value')}
              </FormMessage>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};
