import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatTimeWithTimezone } from '../../utils/date';

export type CurrentTimeProps = {
  timezone: string | undefined;
};

export const CurrentTime: React.FC<CurrentTimeProps> = ({
  timezone,
}: CurrentTimeProps) => {
  const { i18n } = useTranslation();

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(id);
  }, []);

  return (
    <span className="flex flex-nowrap">
      {formatTimeWithTimezone(
        currentDate,
        i18n.language === 'fr' ? 'fr' : 'en',
        timezone
      )}
    </span>
  );
};

CurrentTime.displayName = 'CurrentTime';
