import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import cn from '@appchoose/cn';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Label from '@appchoose/label';
import Loader from '@appchoose/loader';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import {
  CashbackStatus,
  StoreCurrency,
  useCreateClaimIntentQuery,
} from '../../types/generated';
import { Order } from '../../types/order';
import { hasPostActionCashbackAmountChanged } from '../../utils/cashback';
import { transformPrice } from '../../utils/currency';
import { ActionOption, OtherActionsForm } from './other-actions-modal';
import { PostActionCashbackAmountMessage } from './post-action-cashback-amount-message';

type OtherActionsModalCancelOrderFormFieldsProps = {
  order?: Order;
  isPartialCancel?: boolean;
};

export const OtherActionsModalCancelOrderFormFields = ({
  order,
  isPartialCancel,
}: OtherActionsModalCancelOrderFormFieldsProps) => {
  const { i18n, t } = useTranslation();

  const form = useFormContext<OtherActionsForm>();
  const { data: createClaimIntentData, loading: createClaimIntentLoading } =
    useCreateClaimIntentQuery({
      variables: {
        orderId: order?.id ?? '',
        deltaAmount: form
          .getValues('products')
          .filter((product) => product.selected)
          .map((product) => product.product.priceSold.valueWithVat)
          .reduce((partialSum, a) => partialSum + a, 0),
      },
      skip: !isPartialCancel,
    });

  const formatedAmount = transformPrice(
    order?.cashback?.amount ?? 0,
    i18n.language === 'fr' ? 'fr' : 'en',
    order?.cashback?.currency === StoreCurrency.Eur ? 'EUR' : 'USD',
    0
  );

  const cancelOrderReasonOptions: ActionOption[] = [
    !order?.isFullDigital
      ? {
          value: 'no_stock',
          text: t('other_actions.cancel_order_reasons.no_stock'),
        }
      : undefined,
    {
      value: 'dont_want_it',
      text: t('other_actions.cancel_order_reasons.dont_want_it'),
      disabled: order?.hasBeenExported,
    },
  ].filter((r): r is ActionOption => !!r);

  const getAlert = () => {
    const alerts: React.ReactNode[] = [];

    if (order?.hasBeenExported) {
      alerts.push(
        <li className="text-sm text-gray-700">
          <Trans i18nKey="other_actions.step_action.already_exported_bold" />
        </li>
      );
    }

    if (order?.cashback && order.cashback.status === CashbackStatus.Pending) {
      if (isPartialCancel) {
        if (
          createClaimIntentData?.createClaimIntent &&
          hasPostActionCashbackAmountChanged(
            createClaimIntentData?.createClaimIntent
          )
        )
          alerts.push(
            <li className="text-sm text-gray-700">
              <p>{t('other_actions.step_action.cashback_will_be_updated')}</p>

              <p>
                <PostActionCashbackAmountMessage
                  cashbackAmount={order?.cashback.amount}
                  cashbackCurrency={order?.cashback.currency}
                  currentAmount={
                    createClaimIntentData.createClaimIntent.currentAmount
                  }
                  postActionAmount={
                    createClaimIntentData.createClaimIntent.postActionAmount
                  }
                />
              </p>
            </li>
          );
      } else {
        alerts.push(
          <li className="text-sm text-gray-700">
            <p>
              <Trans
                values={{ cashback: formatedAmount }}
                i18nKey="other_actions.step_action.cashback_has_cashback"
              />
            </p>

            <p>
              <Trans i18nKey="other_actions.step_action.cashback_cancel" />
            </p>
          </li>
        );
      }
    }

    return (
      <Alert
        appearance="error"
        icon="alertCircle"
        title={
          <div className={alerts.length > 1 ? 'my-3' : ''}>{t('warning')}</div>
        }
        className="mb-6"
      >
        <ul
          className={`space-y-2 ${
            alerts.length > 1 ? 'ml-4 list-outside list-disc' : ''
          }`}
        >
          {alerts.map((alert, index) => (
            <Fragment key={index}>{alert}</Fragment>
          ))}
        </ul>
      </Alert>
    );
  };

  return (
    <>
      {!createClaimIntentLoading ? (
        (order?.hasBeenExported ?? order?.cashback) && getAlert()
      ) : (
        <div className="mb-6">
          <Loader className="size-6" />
        </div>
      )}
      <FormField
        control={form.control}
        name="cancelOrderReason"
        rules={{ required: true }}
        render={({ field }) => (
          <FormItem>
            <FormLabel className="sr-only" hidden>
              {t('other_actions.step_params.fields.cancel_order_reason.label')}
            </FormLabel>
            <FormControl>
              <RadioGroup
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
                className="gap-4"
              >
                {cancelOrderReasonOptions.map((option) => (
                  <div
                    className={cn('group flex items-center space-x-3', {
                      'items-start': option.description,
                    })}
                    key={option.value}
                  >
                    <RadioGroupItem
                      id={`reason-option-${option.value}`}
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                    />

                    <Label
                      htmlFor={`reason-option-${option.value}`}
                      className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                    >
                      <p>{option.text}</p>
                      {option.description ? (
                        <p className="text-gray-500 group-has-[.peer:disabled]:text-gray-300">
                          {option.description}
                        </p>
                      ) : null}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage match="required">
              {t(
                'other_actions.step_params.fields.cancel_order_reason.validation_errors.required'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
    </>
  );
};
