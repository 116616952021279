type RefundChooseProps = React.ComponentPropsWithoutRef<'svg'>;

export const RefundChoose: React.FC<RefundChooseProps> = ({
  ...props
}: RefundChooseProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.583374 3.5C0.583374 2.5335 1.36688 1.75 2.33337 1.75H11.6667C12.6332 1.75 13.4167 2.5335 13.4167 3.5V10.5C13.4167 11.4665 12.6332 12.25 11.6667 12.25H7.29171C6.96954 12.25 6.70837 11.9888 6.70837 11.6667C6.70837 11.3445 6.96954 11.0833 7.29171 11.0833H11.6667C11.9889 11.0833 12.25 10.8222 12.25 10.5V6.41667H1.75004V6.70833C1.75004 7.0305 1.48887 7.29167 1.16671 7.29167C0.844541 7.29167 0.583374 7.0305 0.583374 6.70833V3.5ZM1.75004 5.25H12.25V3.5C12.25 3.17783 11.9889 2.91667 11.6667 2.91667H2.33337C2.01121 2.91667 1.75004 3.17783 1.75004 3.5V5.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49684 8.49144C3.16752 8.17773 2.77437 8.02087 2.31742 8.02087C1.83752 8.02087 1.428 8.19388 1.08887 8.53989C0.74974 8.87924 0.583374 9.29179 0.583374 9.77087C0.583374 10.25 0.74974 10.6625 1.08887 11.0085C1.428 11.3479 1.83752 11.5209 2.31742 11.5209C2.77437 11.5209 3.16752 11.364 3.49684 11.056C3.82617 11.364 4.21931 11.5209 4.67627 11.5209C5.15617 11.5209 5.56568 11.3479 5.90481 11.0085C6.24394 10.6625 6.41671 10.25 6.41671 9.77087C6.41671 9.29179 6.24394 8.87924 5.90481 8.53989C5.56568 8.19388 5.15617 8.02087 4.67627 8.02087C4.21931 8.02087 3.82617 8.17773 3.49684 8.49144ZM2.13186 10.9353C2.28542 11.2015 2.45179 11.3346 2.63095 11.3346C2.85491 11.3346 2.97648 10.9819 2.97648 10.423C2.97648 10.1435 2.93169 9.83741 2.84851 9.49806C2.76532 9.15871 2.65015 8.86593 2.49018 8.60643C2.33021 8.34692 2.16385 8.21384 1.99108 8.21384C1.77353 8.21384 1.66475 8.49996 1.66475 9.07886C1.66475 9.36498 1.70314 9.67772 1.77993 10.0237C1.86311 10.3631 1.97829 10.6692 2.13186 10.9353ZM4.4907 10.9353C4.64427 11.2015 4.81064 11.3346 4.9898 11.3346C5.21376 11.3346 5.33533 10.9819 5.33533 10.423C5.33533 10.1435 5.29054 9.83741 5.20736 9.49806C5.12417 9.15871 5.009 8.86593 4.84903 8.60643C4.68906 8.34692 4.5227 8.21384 4.34993 8.21384C4.13238 8.21384 4.0236 8.49996 4.0236 9.07886C4.0236 9.36498 4.06199 9.67772 4.13878 10.0237C4.22196 10.3631 4.33714 10.6692 4.4907 10.9353Z"
        fill="currentColor"
      />
    </svg>
  );
};
