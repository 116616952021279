import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import AddressFormFields from '@appchoose/address-form-fields';
import Alert from '@appchoose/alert';
import Checkbox from '@appchoose/checkbox';
import Flag from '@appchoose/flag';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormLabelCheckbox,
  FormMessage,
} from '@appchoose/form';
import Loader from '@appchoose/loader';

import {
  CashbackStatus,
  StoreCurrency,
  useCreateClaimIntentQuery,
} from '../../types/generated';
import { Order } from '../../types/order';
import { hasPostActionCashbackAmountChanged } from '../../utils/cashback';
import { transformPrice } from '../../utils/currency';
import { getDefaultClaimReasonTemplate } from '../../utils/utils';
import { PostActionCashbackAmountMessage } from '../other-actions-modal/post-action-cashback-amount-message';
import { SimpleTextArea } from '../simple-textarea/simple-textarea';
import { OpenComplaintForm } from './open-complaint';

type OpenComplaintMessageFormFieldsProps = {
  order?: Order;
  isPartialCancel: boolean;
};

export const OpenComplaintMessageFormFields = ({
  order,
  isPartialCancel,
}: OpenComplaintMessageFormFieldsProps) => {
  const { i18n, t } = useTranslation();

  const form = useFormContext<OpenComplaintForm>();

  const { data: createClaimIntentData, loading: createClaimIntentLoading } =
    useCreateClaimIntentQuery({
      variables: {
        orderId: order?.id ?? '',
        deltaAmount: form
          .getValues('products')
          .filter((product) => product.selected)
          .map((product) => product.product.priceSold.valueWithVat)
          .reduce((partialSum, a) => partialSum + a, 0),
      },
    });

  const formatedAmount = transformPrice(
    order?.cashback?.amount ?? 0,
    i18n.language === 'fr' ? 'fr' : 'en',
    order?.cashback?.currency === StoreCurrency.Eur ? 'EUR' : 'USD',
    0
  );

  const getAlert = () => {
    if (order?.cashback && order.cashback.status === CashbackStatus.Pending) {
      let content: React.ReactNode | null = null;
      if (isPartialCancel) {
        if (
          order?.cashback &&
          createClaimIntentData?.createClaimIntent &&
          hasPostActionCashbackAmountChanged(
            createClaimIntentData?.createClaimIntent
          )
        ) {
          content = (
            <PostActionCashbackAmountMessage
              cashbackAmount={order?.cashback.amount}
              cashbackCurrency={order?.cashback.currency}
              currentAmount={
                createClaimIntentData.createClaimIntent.currentAmount
              }
              postActionAmount={
                createClaimIntentData.createClaimIntent.postActionAmount
              }
            />
          );
        }
      } else {
        content = <Trans i18nKey="other_actions.step_action.cashback_cancel" />;
      }

      if (content)
        return (
          <Alert
            appearance="error"
            icon="alertCircle"
            title={t('warning')}
            className="mb-6"
          >
            <p className="text-sm text-gray-700">
              <Trans
                values={{ cashback: formatedAmount }}
                i18nKey="other_actions.step_action.cashback_has_cashback"
              />
            </p>
            <p className="text-sm text-gray-700">
              {t('other_actions.step_action.cashback_will_be_updated')}
            </p>
            <div className="text-sm font-semibold text-gray-700">{content}</div>
          </Alert>
        );
    }
  };

  useEffect(() => {
    form.setValue(
      'message',
      getDefaultClaimReasonTemplate(
        form.getValues('reason'),
        order?.seller?.isFrench ? 'fr' : 'en',
        t
      )
    );
  }, []);

  form.watch('hasAddressChanged');

  return (
    <>
      {!createClaimIntentLoading ? (
        getAlert()
      ) : (
        <div className="mb-6">
          <Loader className="size-6" />
        </div>
      )}
      <h4 className="mb-2 font-semibold text-gray-700">
        {t('complaint.open_complaint.step_message.subtitle')}
      </h4>

      <div className="space-y-6">
        <p className="text-sm text-gray-700">
          {t('complaint.open_complaint.step_message.message_visible_to', {
            brand_name: order?.seller?.name ?? '',
          })}
        </p>
        {!order?.seller?.isFrench ? (
          <p className="flex items-center space-x-2 text-sm text-gray-700">
            <Flag flag="US" />
            <span>
              {t('complaint.open_complaint.step_message.brand_speaks_english')}
            </span>
          </p>
        ) : null}
        <FormField
          control={form.control}
          name="message"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sr-only">
                {t(
                  'complaint.open_complaint.step_message.fields.message.label'
                )}
              </FormLabel>
              <FormControl>
                <SimpleTextArea
                  placeholder={t(
                    'complaint.open_complaint.step_message.fields.message.placeholder'
                  )}
                  {...field}
                />
              </FormControl>
              <FormMessage match="required">
                {t(
                  'complaint.open_complaint.step_message.fields.message.validation_errors.required'
                )}
              </FormMessage>
            </FormItem>
          )}
        />
        {!order?.isFullDigital ? (
          <FormField
            control={form.control}
            name="hasAddressChanged"
            render={({ field }) => (
              <FormItem>
                <div className="flex space-x-3">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value=""
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabelCheckbox>
                    {t(
                      'complaint.open_complaint.step_message.send_to_new_shipping_address'
                    )}
                  </FormLabelCheckbox>
                </div>
              </FormItem>
            )}
          />
        ) : null}
        {form.getValues('hasAddressChanged') ? (
          <AddressFormFields
            googleMapsApiKey={
              import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
            }
            locale={i18n.language}
            showCompany={false}
            showName={true}
            showPhone={true}
            translations={{
              suggestions: t('suggestions'),
              fieldsNameValidationErrorsRequired: t(
                'address.fields.name.validation_errors.required'
              ),
              fieldsNameValidationErrorsMaxLength: t(
                'address.fields.name.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsFirstnameValidationErrorsRequired: t(
                'address.fields.firstname.validation_errors.required'
              ),
              fieldsFirstnameValidationErrorsMaxLength: t(
                'address.fields.firstname.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsLastnameValidationErrorsRequired: t(
                'address.fields.lastname.validation_errors.required'
              ),
              fieldsLastnameValidationErrorsMaxLength: t(
                'address.fields.lastname.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsPhoneValidationErrorsRequired: t(
                'address.fields.phone.validation_errors.required'
              ),
              fieldsPhoneValidationErrorsMaxLength: t(
                'address.fields.phone.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsStreetValidationErrorsRequired: t(
                'address.fields.street.validation_errors.required'
              ),
              fieldsStreetValidationErrorsMaxLength: t(
                'address.fields.street.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsStreet2ValidationErrorsMaxLength: t(
                'address.fields.street2.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsBpValidationErrorsRequired: t(
                'address.fields.bp.validation_errors.required'
              ),
              fieldsBpValidationErrorsMaxLength: t(
                'address.fields.bp.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsCityValidationErrorsRequired: t(
                'address.fields.city.validation_errors.required'
              ),
              fieldsCityValidationErrorsMaxLength: t(
                'address.fields.city.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsProvinceValidationErrorsRequired: t(
                'address.fields.province.validation_errors.required'
              ),
              fieldsProvinceValidationErrorsMaxLength: t(
                'address.fields.province.validation_errors.maxLength',
                {
                  maxLength: '200',
                }
              ),
              fieldsCountryValidationErrorsRequired: t(
                'address.fields.country.validation_errors.required'
              ),
              fieldsNameLabel: t('address.fields.name.label_return'),
              fieldsNamePlaceholder: t('address.fields.name.placeholder'),
              fieldsFirstnameLabel: t('address.fields.firstname.label'),
              fieldsFirstnamePlaceholder: t(
                'address.fields.firstname.placeholder'
              ),
              fieldsLastnameLabel: t('address.fields.lastname.label'),
              fieldsLastnamePlaceholder: t(
                'address.fields.lastname.placeholder'
              ),
              fieldsPhoneLabel: t('address.fields.phone.label'),
              fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
              fieldsStreetLabel: t('address.fields.street.label'),
              fieldsStreetPlaceholder: t('address.fields.street.placeholder'),
              fieldsStreet2Label: t('address.fields.street2.label'),
              fieldsStreet2Placeholder: t('address.fields.street2.placeholder'),
              fieldsBpLabel: t('address.fields.bp.label'),
              fieldsBpPlaceholder: '',
              fieldsCityLabel: t('address.fields.city.label'),
              fieldsCityPlaceholder: '',
              fieldsProvinceLabel: t('address.fields.province.label'),
              fieldsProvincePlaceholder: '',
              fieldsProvinceEmptyField: t(
                'address.fields.province.empty_field'
              ),
              fieldsCountryLabel: t('address.fields.country.label'),
              fieldsCountryPlaceholder: t('address.fields.country.placeholder'),
              fieldsCountryPlaceholderSearch: t(
                'address.fields.country.placeholder_search'
              ),
              fieldsCountryEmptyField: t('address.fields.country.empty_field'),
              fieldsCountryNoResults: t('address.fields.country.no_results'),
            }}
          />
        ) : null}
      </div>
    </>
  );
};
