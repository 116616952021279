type CheckProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Check: React.FC<CheckProps> = ({ ...props }: CheckProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM17.5 9.05605C17.8732 9.46317 17.8457 10.0957 17.4386 10.4689L11.4386 15.9689C11.0695 16.3072 10.5072 16.3205 10.1227 16L7.12266 13.5C6.69839 13.1464 6.64106 12.5159 6.99463 12.0916C7.34819 11.6673 7.97875 11.61 8.40303 11.9636L10.7315 13.9039L16.0871 8.99463C16.4942 8.62143 17.1268 8.64894 17.5 9.05605Z"
        fill="currentcolor"
      />
    </svg>
  );
};
