import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import { FormField } from '@appchoose/form';

import {
  ProductView,
  ProductViewContent,
  ProductViewProductDetails,
} from '../product-view/product-view';
import { CreateReturnSlipProductsForm } from './create-return-slip-step-products';

export const CreateReturnSlipStepProductsWithoutReason: React.FC = () => {
  const { t } = useTranslation();

  const form = useFormContext<CreateReturnSlipProductsForm>();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const ProductsErrorMessage =
    form.formState.errors.products
      ?.filter?.((s) => s !== undefined)
      .map((s) => s?.selected?.type)
      .find((t) => t === 'atLeastOne') &&
    t('return.return.fields.products.validation_errors.at_least_one');

  return (
    <div>
      <p className="mb-4 font-semibold text-gray-700">
        {t('return.return.select_products_to_return')}
      </p>
      <div className="flex flex-wrap gap-2">
        {fields.map((field, index) => (
          <label key={field.id} className="w-full">
            <ProductView
              isSelected={field.selected}
              isDisabled={!field.product.isReturnable}
              className="space-y-4"
            >
              <ProductViewContent>
                <ProductViewProductDetails item={field.product} />
                <div className="flex h-14 items-center">
                  <FormField
                    control={form.control}
                    name={`products.${index}.selected`}
                    rules={{
                      validate: {
                        atLeastOne: () =>
                          form.getValues('products').filter((s) => s.selected)
                            .length > 0,
                      },
                    }}
                    disabled={!field.product.isReturnable}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        value=""
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </ProductViewContent>
            </ProductView>
          </label>
        ))}
      </div>
      <div className="mt-2 text-xs text-red-600">{ProductsErrorMessage}</div>
    </div>
  );
};
