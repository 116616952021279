type PrevNextButtonProps = {
  enabled: boolean;
  onClick: () => void;
};

export const PrevButton: React.FC<PrevNextButtonProps> = ({
  enabled,
  onClick,
}: PrevNextButtonProps) => {
  return (
    <button
      type="button"
      className="embla__button embla__button--prev"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1785 31.1785C26.8293 30.5276 26.8293 29.4723 26.1785 28.8214L17.357 20L26.1785 11.1785C26.8293 10.5276 26.8293 9.47232 26.1785 8.82145C25.5276 8.17057 24.4723 8.17057 23.8214 8.82145L13.8214 18.8214C13.1706 19.4723 13.1706 20.5276 13.8214 21.1785L23.8214 31.1785C24.4723 31.8293 25.5276 31.8293 26.1785 31.1785Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export const NextButton: React.FC<PrevNextButtonProps> = ({
  enabled,
  onClick,
}: PrevNextButtonProps) => {
  return (
    <button
      type="button"
      className="embla__button embla__button--next"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8215 31.1785C13.1706 30.5276 13.1706 29.4723 13.8215 28.8214L22.643 20L13.8215 11.1785C13.1706 10.5276 13.1706 9.47232 13.8215 8.82145C14.4723 8.17057 15.5276 8.17057 16.1785 8.82145L26.1785 18.8214C26.8294 19.4723 26.8294 20.5276 26.1785 21.1785L16.1785 31.1785C15.5276 31.8293 14.4723 31.8293 13.8215 31.1785Z"
          fill="white"
        />
      </svg>
    </button>
  );
};
