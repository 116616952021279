type ThreexProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Threex: React.FC<ThreexProps> = ({ ...props }: ThreexProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.21582 8.89054C2.41849 10.6505 3.54915 11.4932 5.12782 11.4932C6.86649 11.4932 7.79449 10.4692 7.79449 9.21054C7.79449 7.89854 6.89849 7.38654 6.48249 7.30121V7.26921C6.78115 7.17321 7.44249 6.78921 7.44249 5.76521C7.44249 4.47454 6.40782 3.70654 5.09582 3.70654C3.68782 3.70654 2.71715 4.52788 2.58915 5.87188L3.90115 6.11721C4.00782 5.40254 4.35982 4.98654 5.04249 4.98654C5.72515 4.98654 6.10915 5.37054 6.10915 5.96788C6.10915 6.58654 5.65049 6.88521 4.99982 6.88521H4.56249V8.01588H5.05315C5.95982 8.01588 6.45049 8.38921 6.45049 9.11454C6.45049 9.80788 5.95982 10.2132 5.11715 10.2132C4.24249 10.2132 3.75182 9.66921 3.61315 8.59188L2.21582 8.89054Z"
        fill="#434647"
      />
      <path
        d="M8.3254 11.3332H9.80807L11.1521 9.30654H11.1734L12.5174 11.3332H14.0001L12.0374 8.41054L13.8827 5.67988H12.4427L11.1734 7.57854H11.1521L9.88274 5.67988H8.44274L10.2881 8.41054L8.3254 11.3332Z"
        fill="#434647"
      />
    </svg>
  );
};
