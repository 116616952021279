import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { Kind, OperationTypeNode } from 'graphql';

import { auth } from './firebase';
import { wsLink } from './wslink';

const retryLink = new RetryLink({
  delay: {
    initial: 0,
  },
  attempts: {
    max: 2,
  },
});

const httpLink = createUploadLink({
  uri: import.meta.env.REACT_APP_API_URL,
  credentials: 'omit',
  useGETForQueries: true,
  headers: { 'Apollo-Require-Preflight': 'true' },
});

const authLink = setContext(async (_, { headers }) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken(false);
    return {
      headers: {
        Authorization: 'Bearer ' + token,
        ...headers,
      },
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return headers;
});

export const fetchApiKey = async () => {
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken(true);
  }
  return '';
};

const promiseToObservable = (promise: Promise<string>) =>
  new Observable((subscriber: ZenObservable.SubscriptionObserver<string>) => {
    promise.then(
      (result) => {
        if (subscriber.closed) return;
        subscriber.next(result);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      Sentry.withScope((scope) => {
        scope.setTag('kind', operation.operationName);

        scope.setExtra('query', operation.query);
        scope.setExtra('variables', operation.variables);

        if (err.path) {
          scope.addBreadcrumb({
            category: 'query-path',
            message: err.path.join(' > '),
            level: 'debug',
          });
        }

        Sentry.captureException(err);
      });
      switch (err.extensions?.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED': {
          // Modify the operation context with a new token
          return promiseToObservable(fetchApiKey()).flatMap(
            (result: string) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: 'Bearer ' + result,
                },
              });
              // Retry the request, returning the new observable
              return forward(operation);
            }
          );
        }
      }
    }
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  link: from([
    retryLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === Kind.OPERATION_DEFINITION &&
          definition.operation === OperationTypeNode.SUBSCRIPTION
        );
      },
      wsLink,
      errorLink.concat(authLink).concat(httpLink)
    ),
  ]),
  name: 'support-v2',
  defaultOptions,
  cache: new InMemoryCache(),
});
