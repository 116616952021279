import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { motivesState } from '../../stores/group-message/motives';
import { selectedSaleState } from '../../stores/group-message/selectedSale';
import { selectedSegmentationState } from '../../stores/group-message/selectedSegmentation';
import { stepState } from '../../stores/group-message/step';
import { transformDateSimple } from '../../utils/date';
import { SaleGroupMessageForm } from './sale-group-message-modal';

export type Info = {
  title: string;
  content: string;
  additionalContent?: number | string;
};

export const SaleGroupMessageSummary: React.FC = () => {
  const { i18n, t } = useTranslation();

  const { getValues } = useFormContext<SaleGroupMessageForm>();
  const step = useRecoilValue(stepState);

  const motives = useRecoilValue(motivesState);
  const selectedSale = useRecoilValue(selectedSaleState);
  const selectedSegmentation = useRecoilValue(selectedSegmentationState);

  const messageMotive =
    motives.find((motive) => motive.id === getValues('motiveId'))?.reason ?? '';

  const [content] = useState<Info[]>(
    [
      step > 0
        ? { title: t('message_group.summary.motive'), content: messageMotive }
        : undefined,
      step > 1
        ? {
            title: t('message_group.summary.to_customers'),
            content: selectedSale?.name ?? '',
            additionalContent: selectedSale?.start_at
              ? transformDateSimple(
                  new Date(selectedSale.start_at),
                  i18n.language === 'fr' ? 'fr' : 'en'
                )
              : t('message_group.no_start_date'),
          }
        : undefined,
      step > 2
        ? {
            title: t('message_group.summary.having'),
            content: selectedSegmentation?.label ?? '',
            additionalContent: selectedSegmentation?.statsBySale?.nbCustomers
              ? selectedSegmentation.statsBySale.nbCustomers
              : getValues('segmentationOrderIds')?.length,
          }
        : undefined,
      step > 2
        ? {
            title: t('message_group.summary.contact_them'),
            content: t('message_group.chat'),
          }
        : undefined,
    ].filter((contentEntry): contentEntry is Info => !!contentEntry)
  );

  return (
    <div className="mb-6 space-y-0.5 rounded border border-beige-400 bg-beige-300 p-4">
      {content.map((info) => (
        <div key={info.title} className="flex items-end">
          <div className="mb-0.5 mr-2 flex w-[8.5rem] justify-end">
            <span className="text-xs font-semibold uppercase tracking-wider text-gray-500">
              {info.title}
            </span>
          </div>
          <div className="flex items-end">
            <div
              className="mr-1 text-sm leading-5.5 text-gray-900"
              dangerouslySetInnerHTML={{
                __html: info.content,
              }}
            />
            {info.additionalContent ? (
              <div className="mb-0.5 text-xs text-gray-500">
                {typeof info.additionalContent === 'number'
                  ? t('message_group.customerWithCount', {
                      count: info.additionalContent,
                    })
                  : info.additionalContent}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
