import React from 'react';
import {
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import Loader from '@appchoose/loader';

import { RequireAuthorization, useAuth } from '../stores/authcontext';
import { useUser } from '../stores/usercontext';
import { UserRole } from '../utils/role';
import { DashboardScreen } from '../views/Dashboard-Screen/Dashboard-Screen';
import { LoginScreen } from '../views/Login-Screen/Login-Screen';
import { MessagesScreen } from '../views/Messages-Screen/MessagesScreen';
import { TemplatesScreen } from '../views/Templates-Screen/Templates-Screen';
import { UnauthorizedPage } from './unauthorized-page';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { isAuthenticationLoading } = useAuth();
  const { user: userAuthenticated } = useUser();

  if (isAuthenticationLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="size-8" />
      </div>
    );
  }

  if (!userAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const AppRouting: React.FC = () => {
  const { user: userAuthenticated } = useUser();

  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <DashboardScreen />
            </RequireAuth>
          }
        >
          <Route
            element={
              <RequireAuthorization
                onlyAdmin={false}
                requireRole={[
                  UserRole.CONTACT,
                  UserRole.ACCOUNTING,
                  UserRole.FULL_ACCESS,
                ]}
              >
                <Outlet />
              </RequireAuthorization>
            }
          >
            <Route path="unhandled">
              <Route path="" element={<MessagesScreen key="unhandled" />} />
              <Route path=":id" element={<MessagesScreen key="unhandled" />} />
            </Route>
            <Route path={`assigned-to/${userAuthenticated?.uid ?? ''}`}>
              <Route
                path=""
                element={<MessagesScreen key="assigned-to-me" />}
              />
              <Route
                path=":id"
                element={<MessagesScreen key="assigned-to-me" />}
              />
            </Route>
            <Route path="assigned-to">
              <Route path=":operatorId">
                <Route path="" element={<MessagesScreen key="assigned-to" />} />
                <Route
                  path=":id"
                  element={<MessagesScreen key="assigned-to" />}
                />
              </Route>
            </Route>
            <Route path="unassigned">
              <Route path="" element={<MessagesScreen key="unassigned" />} />
              <Route path=":id" element={<MessagesScreen key="unassigned" />} />
            </Route>
            <Route path="usa">
              <Route path="" element={<MessagesScreen key="usa" />} />
              <Route path=":id" element={<MessagesScreen key="usa" />} />
            </Route>
            <Route path="pre-sales">
              <Route path="" element={<MessagesScreen key="pre-sales" />} />
              <Route path=":id" element={<MessagesScreen key="pre-sales" />} />
            </Route>
            <Route path="delivery">
              <Route path="" element={<MessagesScreen key="delivery" />} />
              <Route path=":id" element={<MessagesScreen key="delivery" />} />
            </Route>
            <Route path="order">
              <Route path="" element={<MessagesScreen key="order" />} />
              <Route path=":id" element={<MessagesScreen key="order" />} />
            </Route>
            <Route path="post-operation">
              <Route
                path=""
                element={<MessagesScreen key="post-operation" />}
              />
              <Route
                path=":id"
                element={<MessagesScreen key="post-operation" />}
              />
            </Route>
            <Route path="pinned">
              <Route path="" element={<MessagesScreen />} key="pinned" />
              <Route path=":id" element={<MessagesScreen />} key="pinned" />
            </Route>
            <Route path="claim-auto">
              <Route path="" element={<MessagesScreen key="claim-auto" />} />
              <Route path=":id" element={<MessagesScreen key="claim-auto" />} />
            </Route>
            <Route path="archives">
              <Route path="" element={<MessagesScreen key="archives" />} />
              <Route path=":id" element={<MessagesScreen key="archives" />} />
            </Route>
            <Route path="search">
              <Route path="" element={<MessagesScreen key="search" />} />
              <Route path=":id" element={<MessagesScreen key="search" />} />
            </Route>
          </Route>
          <Route path="unauthorized" element={<UnauthorizedPage />} />
          <Route path="" element={<Navigate to="/unhandled" replace />} />
          <Route path="*" element={<Navigate to="/unhandled" replace />} />
        </Route>
        <Route
          path="/templates"
          element={<TemplatesScreen key="templates" />}
        />
      </Routes>
    </HashRouter>
  );
};
