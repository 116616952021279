import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Maybe,
  PickupOpeningDays,
  PickupOpeningHours,
} from '../../types/generated';
import { getDay } from '../../utils/date';

type PickupPointOpeningHoursProps = {
  openingHours?: Maybe<PickupOpeningHours[]>;
};

export const PickupPointOpeningHours: React.FC<
  PickupPointOpeningHoursProps
> = ({ openingHours }: PickupPointOpeningHoursProps) => {
  const { t } = useTranslation();

  if (!openingHours) {
    return (
      <div className="gap-x-2 text-right text-xs text-gray-500">
        {t('pickup_point.closed')}
      </div>
    );
  }
  return (
    <div>
      {openingHours.map((openingHour, idx) => (
        <div key={idx} className="gap-x-2 text-sm font-semibold text-gray-600">
          <span>{openingHour.open}</span> <span>-</span>{' '}
          <span>{openingHour.close}</span>
        </div>
      ))}
    </div>
  );
};

type PickupPointOpeningDaysProps = {
  openingDays: PickupOpeningDays;
};

export const PickupPointOpeningDays: React.FC<PickupPointOpeningDaysProps> = ({
  openingDays,
}) => {
  const [days] = useState([
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ] as const);

  return days.map((day) => (
    <div key={day} className="flex items-start justify-between py-1">
      <span
        className={`text-xs leading-5 ${
          openingDays[day] ? 'text-gray-600' : 'text-gray-500'
        }`}
      >
        {getDay(day)}
      </span>
      <PickupPointOpeningHours openingHours={openingDays[day]} />
    </div>
  ));
};
