import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';

import Loader from '@appchoose/loader';

import { NoResult } from '../../components/icons/no-result';
import { Party } from '../../components/icons/party';
import { messagesOrEmailsDataState } from '../../stores/messages';
import { operatorsState } from '../../stores/operators';
import {
  MessageFromSearchResult,
  messagesFromSearchResultsState,
} from '../../stores/searchResults';
import { selectedState } from '../../stores/selected';
import { firstEmailFromSelectedEmailState } from '../../stores/selectedEmail';
import { ConversationTab } from '../../types/conversationTab';
import { Email, Message } from '../../types/generated';
import { SupportMatch } from '../../types/navigation';
import { getFirstName } from '../../utils/string';
import { ConversationSummaryEmail } from './conversation-summary-email';
import { ConversationSummaryMessage } from './conversation-summary-message';
import { ConversationSummarySearchResult } from './conversation-summary-search-result';

type ConversationListProps = {
  search: string;
  isLoading: boolean;
  hasMoreMessages: boolean;
  conversationTab: ConversationTab;
  selectEmail: (email: string) => void;
  selectMessage: (userKey: string, fromClick?: boolean) => void;
  onScrollToEnd: () => void;
};

export const ConversationList: React.FC<ConversationListProps> = ({
  search,
  isLoading,
  hasMoreMessages,
  conversationTab,
  selectEmail,
  selectMessage,
  onScrollToEnd,
}: ConversationListProps) => {
  const { t } = useTranslation();

  const messagesOrEmailsData = useRecoilValue(messagesOrEmailsDataState);
  const selected = useRecoilValue(selectedState);
  const operators = useRecoilValue(operatorsState);
  const messagesFromSearchResults = useRecoilValue(
    messagesFromSearchResultsState
  );
  const firstEmailFromSelectedEmail = useRecoilValue(
    firstEmailFromSelectedEmailState
  );

  const { operatorId } = useParams<SupportMatch>();

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      if (
        conversationTab !== ConversationTab.Search &&
        !isLoading &&
        hasMoreMessages
      ) {
        onScrollToEnd();
      }
    }
  }, [inView]);

  const renderEmpty = () => {
    const selectedOperator = operators.find(
      (operator) => operator.operatorId === operatorId
    );

    let noResultMessage = '';
    let logo = <Party />;

    switch (conversationTab) {
      case ConversationTab.Pinned:
        noResultMessage = t('list.no_pinned_messages');

        break;
      case ConversationTab.Read:
        noResultMessage = t('list.no_archived_messages');

        break;
      case ConversationTab.Search:
        if (search.length < 2) {
          noResultMessage = t('list.please_enter_at_least_2_characters');
          logo = <NoResult />;
        } else {
          noResultMessage = t('list.no_result_for_this_search');
          logo = <NoResult />;
        }

        break;
      case ConversationTab.AssignedTo:
        noResultMessage = `${getFirstName(selectedOperator?.displayName)}${t(
          'list.has_no_message_to_process'
        )}`;

        break;
      default:
        noResultMessage = t('list.all_tickets_have_been_processed');

        break;
    }

    return (
      <div className="mt-6 flex flex-col items-center">
        <div>{logo}</div>
        <p className="mx-16 mt-2 text-center text-xs text-gray-700">
          {noResultMessage}
        </p>
      </div>
    );
  };

  const messagesToRender =
    conversationTab === ConversationTab.Search
      ? messagesFromSearchResults
      : messagesOrEmailsData;

  const renderConversationSummary = (
    messagesToRender: MessageFromSearchResult[] | (Email | Message)[]
  ) =>
    messagesToRender.map(
      (message: MessageFromSearchResult | Message | Email, idx: number) => {
        if (conversationTab === ConversationTab.Search)
          return renderConversationSummarySearchResult(
            idx,
            message as MessageFromSearchResult
          );
        else if ('__typename' in message && message.__typename === 'Message')
          return renderConversationSummaryMessage(idx, message);
        else if ('__typename' in message && message.__typename === 'Email')
          return renderConversationSummaryEmail(idx, message);
        return null;
      }
    );

  const renderConversationSummarySearchResult = (
    key: number,
    message: MessageFromSearchResult
  ) => (
    <ConversationSummarySearchResult
      key={key}
      message={message}
      selectEmail={selectEmail}
      selectMessage={selectMessage}
      activeId={
        selected?.user?.userKey ?? firstEmailFromSelectedEmail?.email ?? ''
      }
    />
  );

  const renderConversationSummaryMessage = (key: number, message: Message) => (
    <ConversationSummaryMessage
      key={key}
      message={message}
      selectMessage={selectMessage}
      activeId={selected?.user?.userKey ?? ''}
    />
  );

  const renderConversationSummaryEmail = (key: number, message: Email) => (
    <ConversationSummaryEmail
      key={key}
      message={message}
      selectEmail={selectEmail}
      activeId={firstEmailFromSelectedEmail?.email ?? ''}
    />
  );

  return (
    <div className="h-screen w-full overflow-y-scroll md:w-auto">
      {messagesToRender.length > 0 && (
        <ul className="divide-y divide-gray-100">
          {renderConversationSummary(messagesToRender)}
        </ul>
      )}
      {messagesToRender.length > 0 && (
        <div style={{ height: '1px' }} ref={ref}></div>
      )}
      {!isLoading && messagesToRender.length === 0 ? renderEmpty() : null}
      {isLoading ? (
        <div className="flex justify-center">
          <Loader className="m-4 h-8 w-8" />
        </div>
      ) : null}
    </div>
  );
};

ConversationList.displayName = 'ConversationList';
