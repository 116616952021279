import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import {
  useUpdateBillingOrdersShippingAddressMutation,
  useUpdateOrderShippingAddressMutation,
} from '../../../types/generated';
import { OtherActionsForm, StepProps, StepRef } from '../other-actions-modal';
import { OtherActionsModalShippingAddressFormFields } from '../other-actions-modal-shipping-address-form-fields';

export const OtherActionsChangeShippingAddress = forwardRef<StepRef, StepProps>(
  ({ step, order }, ref) => {
    const { t } = useTranslation();

    const [
      updateBillingOrdersShippingAddressMutation,
      { error: updateBillingOrdersShippingAddressError },
    ] = useUpdateBillingOrdersShippingAddressMutation();

    const [
      updateOrderShippingAddressMutation,
      { error: updateOrderShippingAddressError },
    ] = useUpdateOrderShippingAddressMutation();

    useEffect(() => {
      if (updateBillingOrdersShippingAddressError) {
        toast.error(t('other_actions.errors.update_order_shipping_error'));
      }
    }, [updateBillingOrdersShippingAddressError]);

    useEffect(() => {
      if (updateOrderShippingAddressError) {
        toast.error(t('other_actions.errors.update_order_shipping_error'));
      }
    }, [updateOrderShippingAddressError]);

    useImperativeHandle(ref, () => ({
      onNextStep: async (data: OtherActionsForm) => {
        const input = {
          city: data.shipping.city,
          countryCode: data.shipping.countryCode,
          county: data.shipping.province,
          firstname: data.shipping.firstname || '',
          lastname: data.shipping.lastname || '',
          phoneNumber: data.shipping.phone || '',
          street: data.shipping.street,
          streetAdditional: data.shipping.street2,
          zipCode: data.shipping.bp,
        };

        if (data.allOrders) {
          await updateBillingOrdersShippingAddressMutation({
            variables: {
              billingId: order?.billingId || '',
              input,
            },
          });

          toast.success(
            t('other_actions.confirmation_message.change_shipping_address')
          );

          return;
        }

        await updateOrderShippingAddressMutation({
          variables: {
            orderId: order?.id || '',
            input,
          },
        });

        toast.success(
          t('other_actions.confirmation_message.change_shipping_address')
        );
      },
    }));

    return (
      <>
        {step === 1 && (
          <OtherActionsModalShippingAddressFormFields order={order} />
        )}
      </>
    );
  }
);

OtherActionsChangeShippingAddress.displayName =
  'OtherActionsChangeShippingAddress';
