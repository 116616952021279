import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import {
  IdTokenResult,
  NextOrObserver,
  ParsedToken,
  User,
} from 'firebase/auth';

import { setUserId } from '../api/analytics';
import { FirebaseService } from '../api/firebase';
import { UserRole, checkRoles } from '../utils/role';
import { UserContext, useUser } from './usercontext';

export type AuthState = {
  isAuthenticationLoading: boolean;
  onStateChanged?: NextOrObserver<User | null>;
  signIn: () => Promise<boolean>;
  signOut: () => Promise<void>;
};

export type ChooseClaims = ParsedToken & {
  admin: boolean;
  roles: UserRole[] | UserRole;
};

const firebaseService = new FirebaseService();

const authState: AuthState = {
  isAuthenticationLoading: false,
  signIn: () => new Promise(() => true),
  signOut: firebaseService.signOut.bind(firebaseService),
};

export const AuthContext = React.createContext<AuthState>(authState);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export type AuthProviderProps = { children: React.ReactNode };

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

  const isValidUser = (user: User) => {
    const email = user.providerData[0].email;

    if (email?.endsWith('@appchoose.io')) {
      return true;
    }
    return false;
  };

  const onAuthStateChanged = (user: User | null) => {
    if (!user) {
      setUser(null);
      Sentry.setUser(null);
      setAuth({ ...auth, isAuthenticationLoading: false });
      return;
    }

    const isValid = isValidUser(user);

    if (isValid) {
      setUser(user);
      setAuth({ ...auth, isAuthenticationLoading: false });
      Sentry.setUser({ id: user.uid, email: user.email ?? '' });
      setUserId(user.email ?? '');
      return;
    }

    auth.signOut();
    setUser(null);
    Sentry.setUser(null);
    setAuth({ ...auth, isAuthenticationLoading: false });
  };

  const [auth, setAuth] = useState<AuthState>({
    isAuthenticationLoading: true,
    onStateChanged: firebaseService.onStateChanged.bind(
      firebaseService,
      onAuthStateChanged
    ),
    signIn: () =>
      firebaseService.signInWithPopup
        .call(firebaseService)
        .then((result) => isValidUser(result.user)),
    signOut: firebaseService.signOut.bind(firebaseService),
  });

  useEffect(() => {
    return firebaseService.onStateChanged(onAuthStateChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      <UserContext.Provider value={{ user, setUser }}>
        {children}
      </UserContext.Provider>
    </AuthContext.Provider>
  );
};

export type RequireAuthorizationProps = {
  onlyAdmin: boolean;
  requireRole?: UserRole[];
  children: React.ReactNode;
};

export const RequireAuthorization: React.FC<RequireAuthorizationProps> = ({
  onlyAdmin,
  requireRole,
  children,
}) => {
  const location = useLocation();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [claims, setClaims] = useState<ChooseClaims | null>(null);

  const getTokenResult = useCallback(
    (callback: (idTokenResult: IdTokenResult) => void) => {
      return user?.getIdTokenResult().then((token) => {
        callback(token);
      });
    },
    [user]
  );

  useEffect(() => {
    getTokenResult((tokenResult) => {
      setClaims(tokenResult.claims as any);
      setIsLoading(false);
    });
  }, [getTokenResult]);

  if (isLoading) return null;

  if (!checkRoles({ onlyAdmin, requireRole }, claims)) {
    return <Navigate to="/unauthorized" state={{ from: location }} />;
  }

  return children;
};
