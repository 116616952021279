import cn from '@appchoose/cn';
import { type VariantProps, cva } from 'cva';

const orderBadgeVariants = cva({
  base: 'inline-flex items-center rounded-[3px] text-sm',
  variants: {
    style: {
      default: '',
      filled: 'px-2 py-1',
      warning: 'bg-orange-300 px-2 py-1 font-semibold text-orange-600',
      danger: 'bg-red-300 px-2 py-1 font-semibold text-red-600',
    },
    intent: {
      default: '',
      bold: '',
    },
  },
  compoundVariants: [
    {
      style: ['default', 'filled'],
      intent: 'default',
      className: 'text-[#767A7C]',
    },
    {
      style: ['default', 'filled'],
      intent: 'bold',
      className: 'text-gray-700',
    },
    {
      style: 'filled',
      intent: 'default',
      className: 'bg-gray-100',
    },
    {
      style: 'filled',
      intent: 'bold',
      className: 'bg-gray-50',
    },
  ],
  defaultVariants: {
    style: 'default',
    intent: 'default',
  },
});

export type OrderBadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof orderBadgeVariants>;

export const OrderBadge: React.FC<OrderBadgeProps> = ({
  className,
  children,
  intent,
  style,
  ...props
}: OrderBadgeProps) => {
  return (
    <div
      className={cn(orderBadgeVariants({ style, intent }), className)}
      {...props}
    >
      {children}
    </div>
  );
};
