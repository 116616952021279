export const transformPrice = (
  price: number | undefined,
  locale: string,
  currency: string,
  minimumFractionDigits = 2
) => {
  const value = typeof price === 'number' ? price / 100 : 0;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: minimumFractionDigits,
  }).format(value);
};

export const formatPrice = (
  price: number,
  locale: string,
  currency: string,
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: minimumFractionDigits,
  }).format(price);
};
