import { uniqueBy } from '../utils/utils';

export interface Service {
  text: string;
  type: string;
}
export type ServiceOption = {
  logo_url?: string;
} & Service;

const frDeliveryServices: ServiceOption[] = [
  {
    type: 'colissimo',
    text: 'Colissimo',
    logo_url: 'https://assets.aftership.com/couriers/svg/colissimo.svg',
  },
  {
    type: 'la-poste-colissimo',
    text: 'La Poste',
    logo_url:
      'https://assets.aftership.com/couriers/svg/la-poste-colissimo.svg',
  },
  {
    type: 'chronopost-france',
    text: 'Chronopost France',
    logo_url: 'https://assets.aftership.com/couriers/svg/chronopost-france.svg',
  },
  {
    type: 'ups',
    text: 'UPS',
    logo_url: 'https://assets.aftership.com/couriers/svg/ups.svg',
  },
  {
    type: 'dhl',
    text: 'DHL Express',
    logo_url: 'https://assets.aftership.com/couriers/svg/dhl.svg',
  },
  {
    type: 'fedex',
    text: 'FedEx®',
    logo_url: 'https://assets.aftership.com/couriers/svg/fedex.svg',
  },
  {
    type: 'dpd',
    text: 'DPD',
    logo_url: 'https://assets.aftership.com/couriers/svg/dpd.svg',
  },
  {
    type: 'exapaq',
    text: 'EXAPAQ - DPD France',
    logo_url: 'https://assets.aftership.com/couriers/svg/exapaq.svg',
  },
  {
    type: 'colis-prive',
    text: 'Colis Privé',
    logo_url: 'https://assets.aftership.com/couriers/svg/colis-prive.svg',
  },
  {
    type: 'gls',
    text: 'GLS',
    logo_url: 'https://assets.aftership.com/couriers/svg/gls.svg',
  },
  {
    type: 'b2ceurope',
    text: 'B2C Europe',
    logo_url: 'https://assets.aftership.com/couriers/svg/b2ceurope.svg',
  },
  {
    type: 'postnl-international',
    text: 'PostNL International',
    logo_url: 'https://assets.aftership.com/couriers/svg/postnl.svg',
  },
  {
    type: 'cubyn',
    text: 'Cubyn',
    logo_url: 'https://assets.aftership.com/couriers/svg/cubyn.svg',
  },
  {
    type: 'mondialrelay',
    text: 'Mondial Relay',
    logo_url: 'https://assets.aftership.com/couriers/svg/mondialrelay.svg',
  },
];

const usDeliveryServices: ServiceOption[] = [
  {
    type: 'usps',
    text: 'USPS',
    logo_url: 'https://assets.aftership.com/couriers/svg/usps.svg',
  },
  {
    type: 'ups',
    text: 'UPS',
    logo_url: 'https://assets.aftership.com/couriers/svg/ups.svg',
  },
  {
    type: 'fedex',
    text: 'FedEx®',
    logo_url: 'https://assets.aftership.com/couriers/svg/fedex.svg',
  },
  {
    type: 'dhl-global-mail',
    text: 'DHL eCommerce US',
    logo_url: 'https://assets.aftership.com/couriers/svg/dhl-global-mail.svg',
  },
  {
    type: 'ontrac',
    text: 'OnTrac',
    logo_url: 'https://assets.aftership.com/couriers/svg/ontrac.svg',
  },
  {
    type: 'lasership',
    text: 'LaserShip',
    logo_url: 'https://assets.aftership.com/couriers/svg/lasership.svg',
  },
  {
    type: 'osm-worldwide',
    text: 'OSM Worldwide',
    logo_url: 'https://assets.aftership.com/couriers/svg/osm-worldwide.svg',
  },
  {
    type: 'yrc',
    text: 'YRC',
    logo_url: 'https://assets.aftership.com/couriers/svg/yrc.svg',
  },
];

export const deliveryServices = uniqueBy(
  frDeliveryServices.concat(usDeliveryServices),
  (a, b) => a.type === b.type
);
