type BlockInfoProps = {
  children?: JSX.Element;
  title: string;
  type: 'error' | 'warning';
};

export const BlockInfo: React.FC<BlockInfoProps> = ({
  children,
  title,
  type,
}: BlockInfoProps) => {
  return (
    <div
      className={`rounded border p-6 text-gray-700 ${
        type === 'error'
          ? 'border-red-600 bg-red-300'
          : 'border-beige-400 bg-beige-300'
      }`}
    >
      <p
        className={`text-xs font-semibold uppercase tracking-wider ${
          children ? 'mb-1' : ''
        }`}
      >
        {title}
      </p>
      {children}
    </div>
  );
};
