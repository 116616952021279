import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';

import { Sidebar, SidebarContent } from '@appchoose/sidebar';

import { mobileUserSidebarOpenState } from '../../stores/mobileUserSidebarOpenState';
import { UserSidebarContactInfo } from './user-sidebar-contact-info';
import { UserSidebarCredit } from './user-sidebar-credit';
import { UserSidebarNote } from './user-sidebar-note';
import { UserSidebarOrders } from './user-sidebar-orders';
import { UserSidebarUserInfo } from './user-sidebar-user-info';

const UserSidebarContent: React.FC = () => {
  return (
    <section className="h-screen shrink-0 divide-y divide-gray-300 overflow-y-scroll">
      <div className="p-4">
        <UserSidebarUserInfo />
        <UserSidebarCredit />
        <UserSidebarNote />
      </div>
      <UserSidebarContactInfo />
      <UserSidebarOrders />
    </section>
  );
};

UserSidebarContent.displayName = 'UserSidebarContent';

export const UserSidebar: React.FC = () => {
  const location = useLocation();

  const [mobileUserSidebarOpen, setMobileUserSidebarOpen] = useRecoilState(
    mobileUserSidebarOpenState
  );

  useEffect(() => {
    setMobileUserSidebarOpen(false);
  }, [location]);

  return (
    <>
      <Sidebar
        open={mobileUserSidebarOpen}
        onOpenChange={setMobileUserSidebarOpen}
      >
        <SidebarContent className="flex max-w-xs flex-1 flex-col bg-gray-50">
          <UserSidebarContent />
        </SidebarContent>
      </Sidebar>

      {/* Static sidebar for desktop */}
      <aside className="hidden md:flex md:shrink-0">
        <div className="flex w-72 flex-col bg-gray-50">
          <UserSidebarContent />
        </div>
      </aside>
    </>
  );
};

UserSidebar.displayName = 'UserSidebar';
