import { forwardRef, useEffect } from 'react';

import { StepProps, StepRef } from '../other-actions-modal';
import { OtherActionsRefundStepRefund } from './other-actions-refund/other-actions-refund-step-refund';
import { OtherActionsRefundStepSelectProducts } from './other-actions-refund/other-actions-refund-step-select-products';
import { OtherActionsRefundStepSimulation } from './other-actions-refund/other-actions-refund-step-simulation';

export const OtherActionsRefund = forwardRef<StepRef, StepProps>(
  ({ order, step, setSubmitTitle, setNumberOfSteps, setShowFooter }) => {
    useEffect(() => {
      setNumberOfSteps(4);
    }, []);

    if (step === 1) {
      return (
        <OtherActionsRefundStepSelectProducts
          order={order}
          setSubmitTitle={setSubmitTitle}
        />
      );
    }

    if (step === 2) {
      return (
        <OtherActionsRefundStepSimulation
          order={order}
          setSubmitTitle={setSubmitTitle}
        />
      );
    }

    return (
      <OtherActionsRefundStepRefund
        order={order}
        setShowFooter={setShowFooter}
      />
    );
  }
);

OtherActionsRefund.displayName = 'OtherActionsRefund';
