import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { useRecoilValue } from 'recoil';

import AddressView from '@appchoose/address-view';
import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import * as Sentry from '@sentry/react';

import { track } from '../../api/analytics';
import { selectedAddressState, selectedState } from '../../stores/selected';
import { formatPhone } from '../../utils/phone';
import { ReferralModal } from '../referral-modal/referral-modal';

export const UserSidebarContactInfo: React.FC = () => {
  const { i18n, t } = useTranslation();

  const selected = useRecoilValue(selectedState);
  const selectedAddress = useRecoilValue(selectedAddressState);

  const [displayModalReferral, setDisplayModalReferral] = useState(false);
  const [isReferralDisplayed, setIsReferralDisplayed] = useState(false);

  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = clipboardState;
    if (value) {
      toast.success(t('clipboard.copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [clipboardState]);

  if (!selected) return null;

  return (
    <div>
      <CollapsiblePrimitive.Root defaultOpen={false}>
        <CollapsiblePrimitive.Trigger className="group flex w-full items-center justify-between px-4 font-bold text-gray-900 hover:bg-gray-100 data-[state=open]:mb-4 data-[state=closed]:py-6 data-[state=open]:pb-2 data-[state=open]:pt-6">
          <h4 className="font-bold text-gray-900">
            {t('sidebar.contact_info')}
          </h4>
          <Icon
            icon="plus"
            size="large"
            className="text-gray-500 group-data-[state=open]:hidden"
          />
          <Icon
            icon="minus"
            size="large"
            className="text-gray-500 group-data-[state=closed]:hidden"
          />
        </CollapsiblePrimitive.Trigger>
        <CollapsiblePrimitive.Content className="mb-6 px-4">
          <ul className="space-y-2 pb-4">
            {selected.user.phoneNumber ? (
              <li
                className="flex cursor-pointer items-center"
                onClick={() => {
                  copyToClipboard(selected.user.phoneNumber ?? '');
                  track('CopyToClipboard', {
                    label: 'user_phone',
                  });
                }}
              >
                <Icon icon="phone" className="mr-2 shrink-0 text-gray-700" />
                <div className="truncate text-sm text-gray-700">
                  {formatPhone(selected.user.phoneNumber)}
                </div>
              </li>
            ) : null}
            {selected.user.email ? (
              <li
                className="flex cursor-pointer items-center"
                onClick={() => {
                  copyToClipboard(selected.user.email ?? '');
                  track('CopyToClipboard', {
                    label: 'user_email',
                  });
                }}
              >
                <Icon icon="mail" className="mr-2 shrink-0 text-gray-700" />
                <div className="truncate text-sm text-gray-700">
                  {selected.user.email}
                </div>
              </li>
            ) : null}
          </ul>
          {isReferralDisplayed &&
          (selected.user.referralCode ?? selected.user.referral) ? (
            <div className="referral mb-1">
              <h5 className="mb-1 text-sm font-bold text-gray-700">
                {t('sidebar.referral')}
              </h5>
              {selected.user.referralCode ? (
                <div className="flex items-center justify-between">
                  <p className="text-sm text-gray-700">
                    {selected.user.referralCode}
                  </p>
                  <Modal
                    open={displayModalReferral}
                    onOpenChange={setDisplayModalReferral}
                  >
                    <ModalTrigger asChild>
                      <button
                        type="button"
                        className="text-xs font-semibold text-green-900"
                      >
                        <Icon icon="edit" />
                      </button>
                    </ModalTrigger>
                    <ModalContent scrollable>
                      <ReferralModal
                        user={selected.user}
                        referral_code={selected.user.referralCode || ''}
                        onClose={() => setDisplayModalReferral(false)}
                      />
                    </ModalContent>
                  </Modal>
                </div>
              ) : null}
              {selected.user.referral ? (
                <p className="text-xs">
                  {t('sidebar.sponsored_by', {
                    name: selected.user.referral.name,
                  })}
                </p>
              ) : null}
            </div>
          ) : null}
          {(selected.user.referralCode ?? selected.user.referral) ? (
            <a
              className="mb-4 block cursor-pointer text-xs font-semibold text-green-900"
              onClick={() => setIsReferralDisplayed(!isReferralDisplayed)}
            >
              {isReferralDisplayed
                ? t('sidebar.hide_referral_infos')
                : t('sidebar.show_referral_infos')}
            </a>
          ) : null}
          {selectedAddress ? (
            <div className="text-sm text-gray-700">
              <h5 className="mb-1 font-bold">
                {t('address.shipping_address')}
              </h5>
              <AddressView
                label={t('address.label')}
                size="default"
                address={{
                  name: selectedAddress.name,
                  street: selectedAddress.street,
                  street2: selectedAddress.additional_address,
                  bp: selectedAddress.bp,
                  city: selectedAddress.city,
                  province: selectedAddress.state,
                  country: selectedAddress.country,
                  countryCode: selectedAddress.countryCode,
                }}
                locale={i18n.language === 'fr' ? 'FR' : 'EN'}
              />
            </div>
          ) : null}
        </CollapsiblePrimitive.Content>
      </CollapsiblePrimitive.Root>
    </div>
  );
};

UserSidebarContactInfo.displayName = 'UserSidebarContactInfo';
