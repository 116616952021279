import React from 'react';

type TextAreaProps = React.ComponentPropsWithRef<'textarea'>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { ...props }: TextAreaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <textarea
        {...props}
        ref={ref}
        className='form-textarea block w-full rounded border border-gray-500 p-2 text-sm leading-5.5 text-gray-900 outline-none transition duration-300 placeholder:text-gray-500 hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-300 disabled:placeholder:text-gray-300 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:focus:border-red-600 aria-[invalid="true"]:focus:ring-red-600'
      ></textarea>
    );
  }
);

TextArea.displayName = 'TextArea';
