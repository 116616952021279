import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import AddressView from '@appchoose/address-view';

import Chronopost from '../../assets/img/pickup_services/Chronopost.svg';
import Colissimo from '../../assets/img/pickup_services/Colissimo.svg';
import MondialRelay from '../../assets/img/pickup_services/MondialRelay.svg';
import { selectedOrdersState } from '../../stores/selected';
import { PickupPointNetwork } from '../../types/generated';
import { capitalize } from '../../utils/string';
import { PickupPointOpeningDays } from './pickup-point-opening-days';

type PickupPointModalProps = {
  orderId: string | null;
};

export const PickupPointModal: React.FC<PickupPointModalProps> = ({
  orderId,
}: PickupPointModalProps) => {
  const { i18n, t } = useTranslation();

  const selectedOrders = useRecoilValue(selectedOrdersState);
  const [pickupPoint] = useState(
    selectedOrders?.find((order) => order.id === orderId)?.shipping
      ?.pickupPoint ?? null
  );

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
        <h3
          id="modal-pickup-point-title"
          className="mb-6 text-2xl font-bold text-gray-900"
        >
          {t('pickup_point.pickup_point_title')}
        </h3>
        <div className="flex flex-col gap-y-6">
          <section>
            <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
              {t('pickup_point.service_provider')}
            </h4>
            <p className="flex gap-x-1">
              {pickupPoint?.network === PickupPointNetwork.MondialRelay ? (
                <img src={MondialRelay} />
              ) : null}
              {pickupPoint?.network === PickupPointNetwork.Colissimo ? (
                <img src={Colissimo} />
              ) : null}
              {pickupPoint?.network === PickupPointNetwork.Shop2Shop ? (
                <img src={Chronopost} />
              ) : null}
              <span className="text-sm text-gray-700">
                {pickupPoint?.network === PickupPointNetwork.Shop2Shop
                  ? 'Shop2Shop by Chronopost'
                  : pickupPoint?.network
                      ?.split('_')
                      ?.map((string) => capitalize(string.toLowerCase()))
                      ?.join(' ')}
              </span>
            </p>
          </section>
          <section>
            <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
              {t('pickup_point.address')}
            </h4>
            {pickupPoint?.address ? (
              <AddressView
                label={t('address.label')}
                size="medium"
                address={{
                  ...pickupPoint.address,
                  street2: pickupPoint.address.streetAdditional ?? undefined,
                  bp: pickupPoint.address.zipCode ?? undefined,
                  country: '',
                }}
                locale={i18n.language === 'fr' ? 'FR' : 'EN'}
              />
            ) : null}
          </section>
          {pickupPoint?.openingDays && (
            <section>
              <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {t('pickup_point.schedule')}
              </h4>
              <div className="max-w-[240px] divide-y divide-gray-100">
                <PickupPointOpeningDays openingDays={pickupPoint.openingDays} />
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};
