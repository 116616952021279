import { Trans, useTranslation } from 'react-i18next';

import { StoreCurrency } from '../../types/generated';
import { transformPrice } from '../../utils/currency';

type PostActionCashbackAmountMessageProps = {
  cashbackAmount: number;
  cashbackCurrency: StoreCurrency;
  currentAmount: number;
  postActionAmount: number;
};

export const PostActionCashbackAmountMessage: React.FC<
  PostActionCashbackAmountMessageProps
> = ({
  cashbackAmount,
  cashbackCurrency,
  currentAmount,
  postActionAmount,
}: PostActionCashbackAmountMessageProps) => {
  const { i18n } = useTranslation();

  const formatAmount = (amount: number) =>
    transformPrice(
      amount || 0,
      i18n.language === 'fr' ? 'fr' : 'en',
      cashbackCurrency === StoreCurrency.Eur ? 'EUR' : 'USD',
      0
    );

  return postActionAmount !== 0 ? (
    <Trans
      values={{
        oldCashback: formatAmount(currentAmount),
        newCashback: formatAmount(postActionAmount),
      }}
      i18nKey="other_actions.step_action.cashback_partial_update_cashback"
    />
  ) : (
    <Trans
      i18nKey="other_actions.step_action.cashback_no_more_cashback"
      values={{
        cashback: formatAmount(cashbackAmount),
      }}
    />
  );
};
