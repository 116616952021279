import React from 'react';

import { User } from 'firebase/auth';

export type UserState = {
  user: User | null;
  setUser: (user: User | null) => void | null;
};

const userState: UserState = {
  user: null,
  setUser: () => null,
};

export const UserContext = React.createContext<UserState>(userState);

export const useUser = () => {
  return React.useContext(UserContext);
};
