import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import { useMarkOrderAsDeliveredMutation } from '../../../types/generated';
import { StepProps, StepRef } from '../other-actions-modal';
import { OtherActionsModalConfirmMarkAsDelivered } from '../other-actions-modal-confirm-mark-as-delivered';

export const OtherActionsMarkAsDelivered = forwardRef<StepRef, StepProps>(
  ({ step, order }, ref) => {
    const { t } = useTranslation();

    const [markOrderAsDeliveredMutation, { error: markOrderAsDeliveredError }] =
      useMarkOrderAsDeliveredMutation();

    useEffect(() => {
      if (markOrderAsDeliveredError) {
        toast.error(t('other_actions.errors.mark_as_delivered_error'));
      }
    }, [markOrderAsDeliveredError]);

    useImperativeHandle(ref, () => ({
      onNextStep: async () => {
        await markOrderAsDeliveredMutation({
          variables: {
            orderId: order?.id as string,
          },
        });

        toast.success(
          t('other_actions.confirmation_message.mark_as_delivered')
        );
      },
    }));

    return <>{step === 1 && <OtherActionsModalConfirmMarkAsDelivered />}</>;
  }
);

OtherActionsMarkAsDelivered.displayName = 'OtherActionsMarkAsDelivered';
