import { initReactI18next } from 'react-i18next';

import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import fr from './fr.json';

const resources: Resource = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

// initialize i18next with catalog and language to use
if (i18n) {
  i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    fallbackLng: 'en',
  });
  i18n.changeLanguage('fr');
}

export default i18n;
