export const config = {
  pathname: {
    unhandled: 'unhandled',
    assignedTo: 'assigned-to',
    unassigned: 'unassigned',
    usa: 'usa',
    pinned: 'pinned',
    claimAuto: 'claim-auto',
    archives: 'archives',
    search: 'search',
    templates: 'templates',
    delivery: 'delivery',
    order: 'order',
    postOperation: 'post-operation',
    preSales: 'pre-sales',
  },
};
