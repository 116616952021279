import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import { useCancelOrderMutation } from '../../../types/generated';
import { OtherActionsForm, StepProps, StepRef } from '../other-actions-modal';
import { OtherActionsModalCancelOrderFormFields } from '../other-actions-modal-cancel-order-form-fields';
import { OtherActionsModalConfirmationCancel } from '../other-actions-modal-confirmation-cancel';

export const OtherActionsCancelOrder = forwardRef<StepRef, StepProps>(
  ({ step, order, setNumberOfSteps }, ref) => {
    const { t } = useTranslation();

    useEffect(() => {
      setNumberOfSteps(3);
    }, []);

    const [cancelOrderMutation, { error: cancelOrderError }] =
      useCancelOrderMutation();

    useEffect(() => {
      if (cancelOrderError) {
        toast.error(t('other_actions.errors.cancel_order_error'));
      }
    }, [cancelOrderError]);

    useImperativeHandle(ref, () => ({
      onNextStep: async (data: OtherActionsForm) => {
        await cancelOrderMutation({
          variables: {
            orderId: order?.id as string,
            input: {
              notifyCustomer: true,
              putBackOrderItemsInStock: data.putBackInStock,
            },
          },
        });

        toast.success(t('other_actions.confirmation_message.cancel_order'));
      },
    }));

    return (
      <>
        {step === 1 && <OtherActionsModalCancelOrderFormFields order={order} />}
        {step === 2 && <OtherActionsModalConfirmationCancel order={order} />}
      </>
    );
  }
);

OtherActionsCancelOrder.displayName = 'OtherActionsCancelOrder';
