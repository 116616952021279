import { atom, selector } from 'recoil';

import { differenceInDays, differenceInHours, getDay } from 'date-fns';

import {
  ConversationMessage,
  ConversationMessageGroup,
  ConversationStatus,
} from '../types/generated';
import { IUser } from '../types/user';
import { Conversation } from '../views/Messages-Screen/conversation';
import { ordersState } from './orders';

export interface ThreadMessageSimple extends ConversationMessage {
  displayTime?: boolean;
  displayStatus?: boolean;
  displayImage?: boolean;
  displayReadStatus?: boolean;
}

export interface ThreadMessageGroup extends ConversationMessageGroup {
  displayTime?: boolean;
  displayStatus?: boolean;
  displayImage?: boolean;
  displayReadStatus?: boolean;
}

export type ThreadMessage = ThreadMessageSimple | ThreadMessageGroup;

export type Conversation = {
  user: IUser;
  messages:
    | (ConversationMessage | ConversationMessageGroup)[]
    | null
    | undefined;
  status: ConversationStatus | null | undefined;
};

export const selectedState = atom<Conversation | undefined>({
  key: 'selectedState',
  default: undefined,
});

export const selectedOrderIdActiveState = atom<string | undefined>({
  key: 'selectedOrderIdActiveState',
  default: undefined,
});

export const selectedAddressState = selector({
  key: 'selectedAddressState',
  get: ({ get }) => {
    const selected = get(selectedState);
    if (selected?.user?.addresses && selected?.user.addresses.length > 0) {
      return selected.user.addresses.find((c) => c.selected);
    }
    return null;
  },
});

export const selectedIsFromEmailState = selector({
  key: 'selectedIsFromEmailState',
  get: ({ get }) => {
    const selected = get(selectedState);
    if (selected?.messages && selected.messages.length > 0) {
      const message = selected.messages.slice()[selected.messages.length - 1];
      if (message) {
        return typeof message.fromEmail !== 'undefined'
          ? message.fromEmail
          : null;
      }
      return false;
    }
    return false;
  },
});

export const selectedLastMessageState = selector({
  key: 'selectedLastMessageState',
  get: ({ get }) => {
    const selected = get(selectedState);
    if (selected?.messages?.length) {
      const message = selected.messages[selected.messages.length - 1];
      return message;
    }
    return null;
  },
});

export const selectedMessagesState = selector({
  key: 'selectedMessagesState',
  get: ({ get }) => {
    const selected = get(selectedState);
    return selected?.messages ?? [];
  },
});

export const selectedOrdersState = selector({
  key: 'selectedOrdersState',
  get: ({ get }) => {
    const orders = get(ordersState);

    return orders
      .slice()
      .sort((a, b) =>
        new Date(a.createdAt || '').getTime() >
        new Date(b.createdAt || '').getTime()
          ? -1
          : 1
      );
  },
});

export const selectedPhotosState = selector({
  key: 'selectedPhotosState',
  get: ({ get }) => {
    const selected = get(selectedState);
    if (selected?.messages && selected.messages.length > 0) {
      const photos = selected.messages.reduce<string[]>((s, m) => {
        if (
          m.__typename === 'ConversationMessage' &&
          !m.chooseReply &&
          m.photo
        ) {
          s.push(m.photo);
        }
        if (m.__typename === 'ConversationMessageGroup') {
          s.push(...m.photos);
        }
        return s;
      }, []);
      if (photos.length > 0) {
        return photos;
      }
      return null;
    }
    return null;
  },
});

export const selectedThreadMessagesState = selector<ThreadMessage[]>({
  key: 'selectedThreadMessagesState',
  get: ({ get }) => {
    const selectedMessages = get(selectedMessagesState);
    let lastIdxChoose = 0;
    const res = [...selectedMessages]
      .sort((a, b) =>
        new Date(a.createdAt || '') > new Date(b.createdAt || '') ? 1 : -1
      )
      .map((message) => ({
        ...message,
        displayImage: false,
        displayTime: false,
        displayStatus: false,
        displayReadStatus: false,
      }))
      .map((current, idx, messages) => {
        if (
          current.__typename === 'ConversationMessage' &&
          current.chooseReply
        ) {
          lastIdxChoose = idx;
        }
        if (idx === 0) {
          current.displayTime = true;
          const next = messages[1];
          if (!next) {
            current.displayStatus = true;
          }
        } else {
          const next = messages[idx + 1];
          const prec = messages[idx - 1];
          if (!next) {
            current.displayStatus = true;
          } else {
            const precMessageIsChooseReply =
              prec.__typename === 'ConversationMessage' && prec.chooseReply;
            const currentMessageIsChooseReply =
              current.__typename === 'ConversationMessage' &&
              current.chooseReply;
            const nextMessageIsChooseReply =
              next.__typename === 'ConversationMessage' && next.chooseReply;
            if (currentMessageIsChooseReply !== nextMessageIsChooseReply) {
              current.displayStatus = true;
            } else if (
              Math.abs(
                differenceInHours(
                  new Date(current.createdAt || ''),
                  new Date(prec.createdAt || '')
                )
              ) >= 1
            ) {
              current.displayStatus = true;
            } else {
              current.displayStatus = false;
            }
            if (currentMessageIsChooseReply && nextMessageIsChooseReply) {
              current.displayImage = true;
              if (precMessageIsChooseReply && prec.displayStatus) {
                prec.displayStatus = false;
              }
            }
          }

          if (
            prec &&
            Math.abs(
              differenceInDays(
                new Date(current.createdAt || ''),
                new Date(prec.createdAt || '')
              )
            ) > 0
          ) {
            current.displayTime = true;
          }

          if (
            prec &&
            getDay(new Date(current.createdAt || '')) !==
              getDay(new Date(prec.createdAt || ''))
          ) {
            current.displayTime = true;
          }

          if (idx === messages.length - 1) {
            current.displayImage = true;
          }
        }
        return current;
      });
    return res.map((current, idx) => {
      current.displayReadStatus = idx === lastIdxChoose;
      return current;
    });
  },
});
