type IsReplyingProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const IsReplying: React.FC<IsReplyingProps> = ({
  ...props
}: IsReplyingProps) => {
  return (
    <svg
      width="25"
      height="16"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3" width="22" height="14" rx="7" fill="currentColor" />
      <circle cx="9" cy="7" r="2" fill="white" />
      <circle cx="4.5" cy="12.5" r="2.5" fill="currentColor" />
      <circle cx="1" cy="15" r="1" fill="currentColor" />
      <circle cx="14" cy="7" r="2" fill="white" />
      <circle cx="19" cy="7" r="2" fill="white" />
    </svg>
  );
};
