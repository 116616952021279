import { atom, selector } from 'recoil';

import { UserElasticSearchHit } from '../types/generated';
import {
  ElasticSearchHighlights,
  isHighlightPerfectMatchId,
  sanitizeEmphasedElasticSearchResult,
} from '../utils/elastic-search';

export const searchResultsState = atom<UserElasticSearchHit[]>({
  key: 'searchResultsState',
  default: [],
});

export type MessageFromSearchResult = {
  id: string | null | undefined;
  userKey: string | null | undefined;
  name: string | null | undefined;
  email: string | null | undefined;
  lastTime: string | null | undefined;
  highlights: ElasticSearchHighlights;
};

export const messagesFromSearchResultsState = selector({
  key: 'messagesFromSearchResultsState',
  get: ({ get }) => {
    const searchResults = get(searchResultsState);

    return searchResults.map((hit) => {
      const tmp: MessageFromSearchResult = {
        id: hit._id,
        userKey: hit._id,
        name: hit._source.name,
        email: hit._source.email,
        lastTime: hit.highlight.last_time.value,
        highlights: hit.highlight,
      };
      if ('order_id' in hit.highlight) {
        tmp.userKey = hit.highlight.user_key.value
          .replace('<em>', '')
          .replace('</em>', '');
      } else {
        tmp.userKey = hit._id;
      }
      return tmp;
    });
  },
});

export const exactHighlightedOrderIdState = selector({
  key: 'exactHighlightedOrderIdState',
  get: ({ get }) => {
    const searchResults = get(searchResultsState);

    const highlightedHit = searchResults.find((hit) => {
      const highlight = hit?.highlight as ElasticSearchHighlights;
      return (
        highlight?.order_id?.value &&
        isHighlightPerfectMatchId(highlight?.order_id?.value)
      );
    });

    return sanitizeEmphasedElasticSearchResult(
      (highlightedHit?.highlight as ElasticSearchHighlights)?.order_id.value ||
        ''
    );
  },
});
