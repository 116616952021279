import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import { ClaimReason } from '../../types/generated';
import { ComplaintOption, OpenComplaintForm } from './open-complaint';

type OpenComplaintReasonFormFieldsProps = {
  options: ComplaintOption[];
};

export const OpenComplaintReasonFormFields = ({
  options,
}: OpenComplaintReasonFormFieldsProps) => {
  const { t } = useTranslation();

  const form = useFormContext<OpenComplaintForm>();

  form.watch('reason');

  return (
    <>
      <h4 className="mb-4 font-semibold text-gray-700">
        {t('complaint.open_complaint.reason')}
      </h4>
      <FormField
        control={form.control}
        name="reason"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormItem>
            <FormLabel className="sr-only" hidden>
              {t('complaint.open_complaint.fields.complaint_reason.label')}
            </FormLabel>
            <FormControl>
              <RadioGroup
                value={value}
                onValueChange={(value) => onChange(value as ClaimReason)}
                className="gap-4"
              >
                {options.map((option) => (
                  <div
                    className={cn('group flex items-center space-x-3', {
                      'items-start': option.description,
                    })}
                    key={option.value}
                  >
                    <RadioGroupItem
                      id={`reason-option-${option.value}`}
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                    />

                    <Label
                      htmlFor={`reason-option-${option.value}`}
                      className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                    >
                      <p>{option.text}</p>
                      {option.description ? (
                        <p className="text-gray-500 group-has-[.peer:disabled]:text-gray-300">
                          {option.description}
                        </p>
                      ) : null}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage match="required">
              {t(
                'complaint.open_complaint.fields.complaint_reason.validation_errors.required'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
      {form.getValues('reason') === ClaimReason.Other ? (
        <div className="ml-8 mt-2">
          <FormField
            control={form.control}
            name="otherReason"
            rules={{ required: form.getValues('reason') === ClaimReason.Other }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="sr-only">
                  {t(
                    'complaint.open_complaint.fields.complaint_reason_other.label'
                  )}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t(
                      'complaint.open_complaint.fields.complaint_reason_other.placeholder'
                    )}
                    {...field}
                    autoFocus
                  />
                </FormControl>
                <FormMessage match="required">
                  {t(
                    'complaint.open_complaint.fields.complaint_reason_other.validation_errors.required'
                  )}
                </FormMessage>
              </FormItem>
            )}
          />
        </div>
      ) : null}
    </>
  );
};
