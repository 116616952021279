import { ref, uploadBytesResumable } from 'firebase/storage';

import { storage } from './firebase';

export const uploadFileToFirebase = (file: Blob, filePath: string) => {
  const storageRef = ref(storage, filePath);
  return uploadBytesResumable(storageRef, file);
};

export const uploadFile = (file: Blob, filePath: string) => {
  return fetch(filePath, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'Cache-Control': 'no-cache',
    },
    body: file,
  });
};
