import { uploadFile } from '../../api/storage';
import { useGetUploadReturnSlipLabelUrlLazyQuery } from '../../types/generated';

export const useFileUpload = ({ orderId }: { orderId: string }) => {
  const [getUploadReturnSlipLabelUrl] = useGetUploadReturnSlipLabelUrlLazyQuery(
    {
      variables: {
        orderId: orderId,
      },
    }
  );

  const uploadFileAsync = async ({ file }: { file: Blob }) => {
    const { data: uploadUrlData } = await getUploadReturnSlipLabelUrl();

    if (!uploadUrlData?.getUploadReturnSlipLabelUrl)
      throw new Error('No upload url');

    await uploadFile(file, uploadUrlData.getUploadReturnSlipLabelUrl.url);

    return {
      key: uploadUrlData.getUploadReturnSlipLabelUrl.key,
      url: uploadUrlData.getUploadReturnSlipLabelUrl.url,
    };
  };

  return { mutateAsync: uploadFileAsync };
};
