import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

import { OperationType, OrderQuery } from '../../types/generated';

export const getOperationType = (
  type: OrderQuery['order']['operationType'],
  t: TFunction
) => {
  if (type === OperationType.Reseller) {
    return t('order.operation_type.reseller');
  }
  if (type === OperationType.PostOperationPurchase) {
    return t('order.operation_type.post_operation_purchase');
  }
  if (type === OperationType.FirmPurchase) {
    return t('order.operation_type.firm_purchase');
  }
  return t('order.operation_type.dropshipping');
};

type OrderOperationTypeBadgeProps = {
  operationType: OrderQuery['order']['operationType'];
};

export const OrderOperationTypeBadge: React.FC<
  OrderOperationTypeBadgeProps
> = ({ operationType }) => {
  const { t } = useTranslation();

  return (
    <div className="inline-flex rounded bg-gray-100 px-1 py-0.5 text-xs capitalize leading-[0.875rem] text-gray-500">
      {getOperationType(operationType, t)}
    </div>
  );
};
