import React from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

import cn from '@appchoose/cn';

export type SimpleTextAreaProps = {
  /**
   * Should the textarea have a className
   */
  noStyle?: boolean;
} & TextareaAutosizeProps;

export const SimpleTextArea = React.forwardRef<
  HTMLTextAreaElement,
  SimpleTextAreaProps
>(
  (
    { className, noStyle, ...props }: SimpleTextAreaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <div className="relative flex w-full text-gray-500 transition-colors focus-within:text-gray-900">
        <TextareaAutosize
          ref={ref}
          minRows={5}
          maxRows={10}
          className={
            noStyle
              ? 'w-full resize-none text-sm leading-5.5 text-gray-900'
              : cn(
                  'form-input block w-full rounded border border-gray-500 text-sm leading-5.5 text-gray-900 placeholder-gray-500 outline-none transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-gray-300 disabled:placeholder-gray-300 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:focus:border-red-600 aria-[invalid="true"]:focus:ring-red-600',
                  className
                )
          }
          style={{ padding: '8px' }}
          {...props}
        />
      </div>
    );
  }
);

SimpleTextArea.displayName = 'SimpleTextArea';
