import { useTranslation } from 'react-i18next';

export const OtherActionsModalConfirmMarkAsDelivered: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="text-sm text-gray-700">
        {t('other_actions.mark_as_delivered.confirm_mark_as_delivered')}
      </p>
    </div>
  );
};
