type PinProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Pin: React.FC<PinProps> = ({ ...props }: PinProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="PointRelais">
        <g id="Shape">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.7045 14.2019C6.98001 14.4213 7.26085 14.6357 7.55296 14.8328C7.80272 14.9993 8.13667 14.9991 8.38628 14.8324C8.67888 14.6367 8.95906 14.421 9.23424 14.2019C9.73215 13.8054 10.398 13.2323 11.0664 12.5268C12.3725 11.1481 13.8245 9.12171 13.8245 6.83494C13.8245 5.28207 13.2076 3.7928 12.1096 2.69475C11.0115 1.5967 9.52224 0.979828 7.96937 0.979828C6.4165 0.979828 4.92723 1.5967 3.82918 2.69475C2.73113 3.7928 2.11426 5.28207 2.11426 6.83494C2.11426 9.12171 3.56623 11.1481 4.87235 12.5268C5.54074 13.2323 6.20659 13.8054 6.7045 14.2019ZM7.96937 2.47983C6.81432 2.47983 5.70658 2.93867 4.88984 3.75541C4.0731 4.57215 3.61426 5.67989 3.61426 6.83494C3.61426 8.5188 4.71484 10.1795 5.96128 11.4951C6.56916 12.1368 7.17959 12.6627 7.63889 13.0285C7.76119 13.1258 7.87235 13.2115 7.96937 13.2847C8.06639 13.2115 8.17754 13.1258 8.29985 13.0285C8.75914 12.6627 9.36957 12.1368 9.97746 11.4951C11.2239 10.1795 12.3245 8.5188 12.3245 6.83494C12.3245 5.67989 11.8656 4.57215 11.0489 3.75541C10.2322 2.93867 9.12441 2.47983 7.96937 2.47983Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.96945 4.38325C6.61541 4.38325 5.51774 5.48092 5.51774 6.83495C5.51774 8.18899 6.61541 9.28666 7.96945 9.28666C9.32348 9.28666 10.4212 8.18899 10.4212 6.83495C10.4212 5.48092 9.32348 4.38325 7.96945 4.38325ZM7.96945 5.88325C7.44383 5.88325 7.01774 6.30934 7.01774 6.83495C7.01774 7.36057 7.44384 7.78666 7.96945 7.78666C8.49506 7.78666 8.92115 7.36057 8.92115 6.83495C8.92115 6.30934 8.49506 5.88325 7.96945 5.88325Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
