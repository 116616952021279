import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import { FormControl, FormField, FormItem, FormLabel } from '@appchoose/form';
import Select, {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';

import { ItemReturnReasonCode } from '../../types/generated';
import { Item } from '../../types/order';
import {
  ProductView,
  ProductViewContent,
  ProductViewProductDetails,
} from '../product-view/product-view';

export type CreateReturnSlipProductsForm = {
  products: {
    product: Item;
    selected: boolean;
    reason: ItemReturnReasonCode;
  }[];
};

export const CreateReturnSlipStepProducts: React.FC = () => {
  const { t } = useTranslation();

  const form = useFormContext<CreateReturnSlipProductsForm>();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const reasonOptions = [
    {
      name: t('return.return.item_return_reason_code.better_price'),
      value: ItemReturnReasonCode.BetterPrice,
    },
    {
      name: t('return.return.item_return_reason_code.damaged_product'),
      value: ItemReturnReasonCode.DamagedProduct,
    },
    {
      name: t('return.return.item_return_reason_code.damaged_shipping'),
      value: ItemReturnReasonCode.DamagedShipping,
    },
    {
      name: t('return.return.item_return_reason_code.delay'),
      value: ItemReturnReasonCode.Delay,
    },
    {
      name: t('return.return.item_return_reason_code.description'),
      value: ItemReturnReasonCode.Description,
    },
    {
      name: t('return.return.item_return_reason_code.other'),
      value: ItemReturnReasonCode.Other,
    },
    {
      name: t('return.return.item_return_reason_code.picture'),
      value: ItemReturnReasonCode.Picture,
    },
    {
      name: t('return.return.item_return_reason_code.quality'),
      value: ItemReturnReasonCode.Quality,
    },
    {
      name: t('return.return.item_return_reason_code.size'),
      value: ItemReturnReasonCode.Size,
    },
    {
      name: t('return.return.item_return_reason_code.wrong_article'),
      value: ItemReturnReasonCode.WrongArticle,
    },
  ];

  const ProductsErrorMessage =
    form.formState.errors.products
      ?.filter?.((s) => s !== undefined)
      .map((s) => s?.selected?.type)
      .find((t) => t === 'atLeastOne') &&
    t('return.return.fields.products.validation_errors.at_least_one');

  const value = useWatch({
    name: 'products',
    control: form.control,
  });

  return (
    <div>
      <p className="mb-4 font-semibold text-gray-700">
        {t('return.return.select_products_to_return')}
      </p>
      <div className="flex flex-wrap gap-2">
        {fields.map((field, index) => (
          <label key={field.id} className="w-full">
            <ProductView
              isSelected={field.selected}
              isDisabled={!field.product.isReturnable}
              className="space-y-4"
            >
              <ProductViewContent>
                <ProductViewProductDetails item={field.product} />
                <div className="flex h-14 items-center">
                  <FormField
                    control={form.control}
                    name={`products.${index}.selected`}
                    rules={{
                      validate: {
                        atLeastOne: () =>
                          form.getValues('products').filter((s) => s.selected)
                            .length > 0,
                      },
                    }}
                    disabled={!field.product.isReturnable}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Checkbox
                            {...field}
                            value=""
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </ProductViewContent>
              {value?.[index]?.selected ? (
                <FormField
                  control={form.control}
                  name={`products.${index}.reason`}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid },
                  }) => (
                    <FormItem>
                      <FormLabel>
                        {t(
                          'return.return.fields.item_return_reason_code.label'
                        )}
                      </FormLabel>
                      <FormControl>
                        <Select onValueChange={onChange} value={value}>
                          <SelectTrigger
                            id="item-return-reason"
                            aria-invalid={invalid}
                          >
                            <SelectValue
                              placeholder={t(
                                'return.return.fields.item_return_reason_code.empty_field'
                              )}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            {reasonOptions.map((option) => (
                              <SelectItem
                                value={option.value}
                                key={option.value}
                              >
                                {option.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
              ) : null}
            </ProductView>
          </label>
        ))}
      </div>
      <div className="mt-2 text-xs text-red-600">{ProductsErrorMessage}</div>
    </div>
  );
};
