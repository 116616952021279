import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabelCheckbox,
} from '@appchoose/form';

import { CashbackStatus, StoreCurrency } from '../../types/generated';
import { Order } from '../../types/order';
import { transformPrice } from '../../utils/currency';
import { OtherActionsForm } from './other-actions-modal';

type OtherActionsModalConfirmationCancelProps = {
  order?: Order;
};

export const OtherActionsModalConfirmationCancel = ({
  order,
}: OtherActionsModalConfirmationCancelProps) => {
  const { i18n, t } = useTranslation();

  const form = useFormContext<OtherActionsForm>();

  const cashbackAmount = transformPrice(
    order?.cashback?.amount ?? 0,
    i18n.language === 'fr' ? 'fr' : 'en',
    order?.cashback?.currency === StoreCurrency.Eur ? 'EUR' : 'USD',
    0
  );

  const remainingAmount = order?.items
    .map((item) => item.refundSummary.remainingToRefundAmountInCents)
    .reduce((acc, val) => acc + val, 0);

  const remainingItemsToCancel = order?.items?.filter(
    (item) => !item.isCancelled
  )?.length;

  return (
    <div className="space-y-4">
      <div className="text-sm text-gray-700">
        {t('other_actions.step_confirmation.automatic_refund')}
      </div>
      <Alert
        appearance="warning"
        title={transformPrice(
          remainingAmount ?? 0,
          i18n.language === 'fr' ? 'fr' : 'en',
          order?.totalPriceSold?.currency ?? 'EUR'
        )}
      >
        <p className="text-sm text-gray-700">
          {t('other_actions.step_confirmation.articleWithCount', {
            count: remainingItemsToCancel ?? 0,
          })}
          , {t('other_actions.step_confirmation.shipping_fee')}.
        </p>
        {order?.cashback &&
          order.cashback.status === CashbackStatus.Pending && (
            <>
              <div className="my-4 border-t border-beige-400"></div>
              <div className="mb-1 text-sm text-gray-700">
                {t('other_actions.cancel_order_reasons.credit_will_be_update')}
              </div>
              <div className="text-sm font-semibold text-gray-700">
                {t(
                  'other_actions.cancel_order_reasons.client-wont_receive_credit',
                  { credits: cashbackAmount }
                )}
              </div>
            </>
          )}
      </Alert>
      {form.getValues('cancelOrderReason') === 'dont_want_it' &&
      order &&
      !order.isFullDigital ? (
        <FormField
          control={form.control}
          name="putBackInStock"
          render={({ field }) => (
            <FormItem>
              <div className="flex space-x-3">
                <FormControl>
                  <Checkbox
                    {...field}
                    value=""
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabelCheckbox>
                  {t('other_actions.step_confirmation.put_back_in_stock')}
                </FormLabelCheckbox>
              </div>
            </FormItem>
          )}
        />
      ) : null}
    </div>
  );
};
