type ReturnArrowProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const ReturnArrow: React.FC<ReturnArrowProps> = ({
  ...props
}: ReturnArrowProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5286 2.19526C9.26825 2.45561 9.26825 2.87772 9.5286 3.13807L11.7239 5.33333H5.33333C4.44928 5.33333 3.60143 5.68452 2.97631 6.30964C2.35119 6.93477 2 7.78261 2 8.66667V13.3333C2 13.7015 2.29848 14 2.66667 14C3.03486 14 3.33333 13.7015 3.33333 13.3333V8.66667C3.33333 8.13623 3.54405 7.62753 3.91912 7.25245C4.29419 6.87738 4.8029 6.66667 5.33333 6.66667H11.7239L9.5286 8.86193C9.26825 9.12228 9.26825 9.54439 9.5286 9.80474C9.78894 10.0651 10.2111 10.0651 10.4714 9.80474L13.8047 6.4714C14.0651 6.21106 14.0651 5.78894 13.8047 5.5286L10.4714 2.19526C10.2111 1.93491 9.78894 1.93491 9.5286 2.19526Z"
        fill="#0B1115"
      />
    </svg>
  );
};
