type FocusOrderProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const FocusOrder: React.FC<FocusOrderProps> = ({
  ...props
}: FocusOrderProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.7594 11.3514C10.3857 11.3514 10.0827 11.6419 10.0827 12.0001C10.0827 12.3584 10.3857 12.6488 10.7594 12.6488H13.2406C13.6143 12.6488 13.9173 12.3584 13.9173 12.0001C13.9173 11.6419 13.6143 11.3514 13.2406 11.3514H10.7594Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.64866C4.5 6.29041 4.80297 6 5.17669 6H18.8233C19.197 6 19.5 6.29041 19.5 6.64866V9.62169C19.5 9.97993 19.197 10.2703 18.8233 10.2703H18.2594V17.3513C18.2594 17.7096 17.9565 18 17.5827 18H6.41732C6.04359 18 5.74063 17.7096 5.74063 17.3513V10.2703H5.17669C4.80297 10.2703 4.5 9.97993 4.5 9.62169V6.64866ZM18.1466 8.97303V7.29732H5.85338V8.97303C9.95078 8.97303 14.0488 8.97303 18.1466 8.97303ZM16.906 10.2703H7.09401V16.7027H16.906V10.2703Z"
        fill="currentColor"
      />
      <path
        d="M2 6V2H6"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 18V22H6"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6V2H18"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 18V22H18"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
