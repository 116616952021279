import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Flag from '@appchoose/flag';
import Icon from '@appchoose/icon';
import Input, { InputAdornment } from '@appchoose/input';
import { toast } from '@appchoose/toast';
import { useDebouncedState } from '@react-hookz/web';

import { salesState } from '../../stores/group-message/sales';
import { segmentsState } from '../../stores/group-message/segments';
import { selectedSaleState } from '../../stores/group-message/selectedSale';
import {
  SalesElasticSearchHit,
  Segment,
  useGetSegmentsInfosLazyQuery,
  useSearchSalesEsLazyQuery,
} from '../../types/generated';
import { formatRange, transformDateSimple } from '../../utils/date';
import { SaleSearch, SaleStatus, getSaleStatus } from '../../utils/sale';
import { SaleGroupMessageForm } from './sale-group-message-modal';
import { SaleGroupMessageSummary } from './sale-group-message-summary';

type SaleExtraInfosProps = {
  sale: SaleSearch;
  showStatus?: boolean;
};

export const SaleExtraInfos: React.FC<SaleExtraInfosProps> = ({
  sale,
  showStatus = true,
}: SaleExtraInfosProps) => {
  const { i18n, t } = useTranslation();

  const getSaleStatusLabel = (saleStatus: SaleStatus) => {
    switch (saleStatus) {
      case SaleStatus.Cancelled:
        return t('message_group.sale_status.cancelled');
      case SaleStatus.Ended:
        return t('message_group.sale_status.ended');
      case SaleStatus.InProgress:
        return t('message_group.sale_status.in_progress');
      case SaleStatus.Upcoming:
        return t('message_group.sale_status.upcoming');
      default:
        return t('message_group.sale_status.upcoming');
    }
  };

  const getSaleStatusColor = (saleStatus: SaleStatus) => {
    switch (saleStatus) {
      case SaleStatus.Cancelled:
        return 'text-gray-500';
      case SaleStatus.Ended:
        return 'text-red-600';
      case SaleStatus.InProgress:
        return 'text-green-600';
      case SaleStatus.Upcoming:
        return 'text-orange-600';
      default:
        return 'text-orange-600';
    }
  };

  const getFormattedSaleDate = (startAt: string, endAt: string | null) => {
    if (endAt) {
      return formatRange(
        new Date(startAt),
        new Date(endAt),
        i18n.language === 'fr' ? 'fr' : 'en'
      );
    }

    return transformDateSimple(
      new Date(startAt),
      i18n.language === 'fr' ? 'fr' : 'en'
    );
  };

  return (
    <>
      {sale.store_id && (
        <>
          <span>
            <Flag
              className="h-4 w-4"
              flag={sale.store_id === 'us' ? 'US' : 'EU'}
            />
          </span>

          <span>•</span>
        </>
      )}

      {showStatus && (
        <>
          <span
            className={`font-semibold ${getSaleStatusColor(sale.sale_status)}`}
          >
            <span>{getSaleStatusLabel(sale.sale_status)}</span>
          </span>

          <span>•</span>
        </>
      )}

      <span className="mr-1 font-normal text-gray-500">
        {getFormattedSaleDate(sale.start_at, sale.end_at)}
      </span>
    </>
  );
};

export const SaleGroupMessageModalStepSale: React.FC = () => {
  const { t } = useTranslation();

  const form = useFormContext<SaleGroupMessageForm>();

  const [sales, setSales] = useRecoilState(salesState);
  const [selectedSale, setSelectedSale] = useRecoilState(selectedSaleState);
  const setSegments = useSetRecoilState(segmentsState);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebouncedState(
    '',
    250
  );

  const [getSearchSales, { data: getSearchSalesData }] =
    useSearchSalesEsLazyQuery();
  const [getSegmentsInfos] = useGetSegmentsInfosLazyQuery();

  useEffect(() => {
    return () => {
      form.unregister('saleId', { keepValue: true, keepIsValid: false });
    };
  }, []);

  useEffect(() => {
    getSearchSales({
      variables: {
        name: searchTerm,
      },
    });
  }, [debouncedSearchTerm]);
  useEffect(() => {
    if (getSearchSalesData) {
      setSales(
        getSearchSalesData.searchSalesES?.hits
          ?.filter((hit): hit is SalesElasticSearchHit => !!hit)
          .map((c) => {
            const startAt =
              (c.highlight.start_at as { value: string }).value ?? '';
            const endAt =
              (c.highlight.end_at as { value: string }).value || null;
            const isCancelled =
              (c.highlight.is_cancelled as { value: boolean | string })
                .value === true;

            return {
              id: c.highlight.sale_id.value,
              start_at: startAt,
              end_at: endAt,
              sale_status: getSaleStatus(startAt, endAt, isCancelled),
              store_id: (c.highlight.store_id as { value: string } | undefined)
                ?.value,
              highlighted_name: c.highlight.brand_name.value,
              name: c.highlight.brand_name.value
                .replaceAll('<em>', '')
                .replaceAll('</em>', ''),
            };
          }) ?? []
      );
    }
  }, [getSearchSalesData]);

  const onSelectSale = async (searchElement: SaleSearch) => {
    setSales([]);
    form.setValue('saleId', searchElement.id, { shouldValidate: true });
    form.setValue('saleStoreId', searchElement.store_id ?? '');
    setSelectedSale(searchElement);

    try {
      const segmentInfosData = await getSegmentsInfos({
        variables: {
          saleId: searchElement.id,
        },
      });
      setSegments(
        segmentInfosData.data?.getSegmentsInfos?.filter(
          (segment): segment is Segment => !!segment
        ) ?? []
      );
    } catch {
      toast.error(t('message_group.errors.segment_infos_error'));
    }
  };

  const onUnselectSale = () => {
    setSales([]);
    form.setValue('saleId', '', { shouldValidate: true });
    form.setValue('saleStoreId', '');
    setSelectedSale(undefined);
  };

  return (
    <>
      <h2 className="mb-6 text-2xl font-bold text-gray-900">
        {t('message_group.contact_group_of_customers')}
      </h2>
      <SaleGroupMessageSummary />
      <input type="hidden" {...form.register('saleId', { required: true })} />
      {selectedSale ? (
        <div className="form-input flex w-full items-center justify-between rounded border border-gray-500 p-2 text-sm leading-5.5 text-gray-900 outline-none transition duration-300 placeholder:text-gray-500 hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700">
          <div className="flex items-center space-x-1 truncate text-xs text-gray-500">
            <span
              dangerouslySetInnerHTML={{
                __html: selectedSale.name,
              }}
              className="text-sm font-semibold text-gray-900"
            />
            <span>•</span>
            <SaleExtraInfos sale={selectedSale} />
          </div>
          <button type="button" onClick={onUnselectSale} className="pl-2">
            <Icon icon="close" />
          </button>
        </div>
      ) : (
        <div>
          <label htmlFor="search-sale" className="sr-only">
            {t('message_group.fields.search_sale_by_name.label')}
          </label>
          <Input
            id="search-sale"
            type="text"
            placeholder={
              t('message_group.fields.search_sale_by_name.placeholder') || ''
            }
            startAdornment={
              <InputAdornment position="start">
                <Icon icon="search" />
              </InputAdornment>
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setDebouncedSearchTerm(e.target.value);
            }}
            autoFocus
          />
        </div>
      )}

      {sales.length ? (
        <div className="sales mt-1 h-full max-h-56 overflow-y-auto rounded border border-gray-900">
          {sales.map((sale) => (
            <div
              className="cursor-pointer space-y-1 border-b border-gray-100 p-4 last:border-none hover:bg-green-300"
              key={sale.id}
              onClick={() => onSelectSale(sale)}
            >
              <p
                className="text-sm font-normal text-gray-900"
                dangerouslySetInnerHTML={{
                  __html: sale.highlighted_name,
                }}
              />
              <div className="flex items-center space-x-1 p-0 text-xs text-gray-500">
                <SaleExtraInfos sale={sale} />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
