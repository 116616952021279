import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { ConversationThread } from '../../components/conversation-thread/conversation-thread';
import { useListenOrderUpdated } from '../../hooks/use-listen-order-updated';
import { selectedState } from '../../stores/selected';
import { firstEmailFromSelectedEmailState } from '../../stores/selectedEmail';
import { ConversationCategory, MessageStatus } from '../../types/generated';

type ConversationProps = {
  conversationStatus: MessageStatus;
  onUnselect: () => void;
  onChangeMessage: (message: string) => void;
  onFixConversation(isFixed: boolean): void;
  onPinConversation(isPinned: boolean): void;
  onAssignConversation(isAssigned: boolean, assignedOperatorId: string): void;
  onSetConversationCategory(
    isAssigned: boolean,
    assignedCategory: ConversationCategory
  ): void;
  onSubmit: (
    message: string,
    email?: { email: string; subject: string },
    status?: MessageStatus
  ) => void;
};

export const Conversation: React.FC<ConversationProps> = ({
  conversationStatus,
  onUnselect,
  onChangeMessage,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onFixConversation,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onPinConversation,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onAssignConversation,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  onSetConversationCategory,
  onSubmit,
}: ConversationProps) => {
  const selected = useRecoilValue(selectedState);
  const firstEmailFromSelectedEmail = useRecoilValue(
    firstEmailFromSelectedEmailState
  );

  useListenOrderUpdated();

  useEffect(() => {
    scrollToBottomListOfMessages();
  }, [selected, selected?.messages]);

  const scrollToBottomListOfMessages = () => {
    const element = document.querySelector('.conversations-container');

    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  return (
    <ConversationThread
      key={`${
        selected?.user?.userKey ?? firstEmailFromSelectedEmail?.email ?? ''
      }`}
      conversationStatus={conversationStatus}
      onChangeMessage={onChangeMessage}
      onUnselect={onUnselect}
      onFixConversation={onFixConversation}
      onPinConversation={onPinConversation}
      onAssignConversation={onAssignConversation}
      onSetConversationCategory={onSetConversationCategory}
      onSubmit={onSubmit}
    />
  );
};

Conversation.displayName = 'Conversation';
