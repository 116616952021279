type MediumPdfProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const MediumPdf: React.FC<MediumPdfProps> = ({ ...props }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3H35V37H5V10M12 3L5 10M12 3V10H5"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9756 25.0001V17.3999H13.8962C15.3837 17.3999 16.4369 18.2142 16.4369 19.7451C16.4369 21.2543 15.4272 22.0686 13.918 22.0686H11.8768V25.0001H10.9756ZM11.8768 21.2434H13.8637C14.7431 21.2434 15.5249 20.7874 15.5249 19.7451C15.5249 18.6485 14.7214 18.2251 13.842 18.2251H11.8768V21.2434Z"
        fill="currentColor"
      />
      <path
        d="M18.5885 24.175H19.9239C21.444 24.175 22.3994 23.3389 22.3994 21.1892C22.3994 19.2457 21.4548 18.2251 19.9891 18.2251H18.5885V24.175ZM17.6873 25.0001V17.3999H20.0651C21.748 17.3999 23.3549 18.5617 23.3549 21.1892C23.3549 24.099 21.6828 25.0001 19.9999 25.0001H17.6873Z"
        fill="currentColor"
      />
      <path
        d="M24.9291 25.0001V17.3999H29.511V18.2251H25.8303V20.972H29.207V21.7646H25.8303V25.0001H24.9291Z"
        fill="currentColor"
      />
      <path
        d="M10.9756 25.0001V17.3999H13.8962C15.3837 17.3999 16.4369 18.2142 16.4369 19.7451C16.4369 21.2543 15.4272 22.0686 13.918 22.0686H11.8768V25.0001H10.9756ZM11.8768 21.2434H13.8637C14.7431 21.2434 15.5249 20.7874 15.5249 19.7451C15.5249 18.6485 14.7214 18.2251 13.842 18.2251H11.8768V21.2434Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5885 24.175H19.9239C21.444 24.175 22.3994 23.3389 22.3994 21.1892C22.3994 19.2457 21.4548 18.2251 19.9891 18.2251H18.5885V24.175ZM17.6873 25.0001V17.3999H20.0651C21.748 17.3999 23.3549 18.5617 23.3549 21.1892C23.3549 24.099 21.6828 25.0001 19.9999 25.0001H17.6873Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9291 25.0001V17.3999H29.511V18.2251H25.8303V20.972H29.207V21.7646H25.8303V25.0001H24.9291Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
