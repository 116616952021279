import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '@appchoose/icon';

import { CashbackStatus, StoreCurrency } from '../../types/generated';
import { Order } from '../../types/order';
import { transformPrice } from '../../utils/currency';
import { formatDate } from '../../utils/date';

type CashbackInfoProps = {
  cashback: Order['cashback'];
};

const getIcon = (status: CashbackStatus): IconType => {
  if (status === CashbackStatus.Cancelled) {
    return 'closeCircle';
  } else if (status === CashbackStatus.Fulfilled) {
    return 'check';
  } else {
    return 'clock';
  }
};

export const CashbackInfo = ({ cashback }: CashbackInfoProps) => {
  const { i18n, t } = useTranslation();

  if (!cashback) return null;

  const getTitle = (): string => {
    if (cashback?.status === CashbackStatus.Cancelled) {
      return `${t('order.credit_offered')} ${t('order.credit_cancelled')}`;
    }

    return `${t('order.credit_offered')} : ${transformPrice(
      cashback?.amount ?? 0,
      i18n.language === 'fr' ? 'fr' : 'en',
      cashback?.currency === StoreCurrency.Eur ? 'EUR' : 'USD',
      0
    )}`;
  };

  const getDetailsSection = (): React.ReactNode | undefined => {
    if (cashback?.status === CashbackStatus.Cancelled) {
      return undefined;
    }

    return (
      <div className="ml-6 flex items-center space-x-1">
        <div className="text-xs text-gray-500">
          {cashback?.status === CashbackStatus.Fulfilled
            ? t('order.credit_received')
            : t('order.credit_waiting')}
        </div>
        <div className="text-xs text-gray-300">•</div>
        <div className="text-xs text-gray-500">
          {formatDate(new Date(cashback.dueDate), 'P', i18n.language)}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-4 space-y-0.5 rounded border border-gray-300 p-3">
      <div className="flex items-center">
        <Icon icon={getIcon(cashback.status)} className="mr-2 text-gray-700" />
        <div className="text-xs font-semibold text-gray-700">{getTitle()}</div>
      </div>
      {getDetailsSection()}
    </div>
  );
};

CashbackInfo.displayName = 'CashbackInfo';
