type HubspotProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Hubspot: React.FC<HubspotProps> = ({ ...props }: HubspotProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63139 2.67553C4.65446 2.56503 4.66658 2.45052 4.66658 2.33317C4.66658 1.4127 3.92039 0.666504 2.99992 0.666504C2.07944 0.666504 1.33325 1.4127 1.33325 2.33317C1.33325 3.25364 2.07944 3.99984 2.99992 3.99984C3.3139 3.99984 3.60761 3.91301 3.85833 3.76206L8.23179 6.97212C7.62756 7.62582 7.25834 8.49996 7.25834 9.46033C7.25834 10.2112 7.48406 10.9094 7.87134 11.4907L6.26833 13.0937C6.19301 13.0779 6.11494 13.0696 6.03493 13.0696C5.40955 13.0696 4.90259 13.5766 4.90259 14.202C4.90259 14.8273 5.40955 15.3343 6.03493 15.3343C6.66031 15.3343 7.16727 14.8273 7.16727 14.202C7.16727 14.1629 7.16529 14.1243 7.16143 14.0862L8.79934 12.4483C9.39893 12.8756 10.1326 13.127 10.925 13.127C12.9501 13.127 14.5917 11.4854 14.5917 9.46033C14.5917 7.65342 13.2847 6.15178 11.5645 5.84923V4.01015C11.9866 3.77514 12.2721 3.32444 12.2721 2.80706C12.2721 2.04708 11.656 1.43099 10.8961 1.43099C10.1361 1.43099 9.51999 2.04708 9.51999 2.80706C9.51999 3.32589 9.80713 3.77766 10.2312 4.01212V5.85922C9.92474 5.91791 9.63187 6.01473 9.35768 6.14457L4.63139 2.67553ZM10.925 11.7937C12.2137 11.7937 13.2583 10.749 13.2583 9.46033C13.2583 8.17166 12.2137 7.12699 10.925 7.12699C10.4238 7.12699 9.96305 7.28353 9.58397 7.55048C8.98109 7.97503 8.59167 8.67227 8.59167 9.46033C8.59167 10.749 9.63634 11.7937 10.925 11.7937Z"
        fill="currentColor"
      />
    </svg>
  );
};
