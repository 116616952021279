import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';

export type TrackingNumberFormData = {
  trackingNumber: string;
};

export const TrackingNumberFormFields: React.FC = () => {
  const { t } = useTranslation();

  const form = useFormContext<TrackingNumberFormData>();

  return (
    <FormField
      control={form.control}
      name="trackingNumber"
      rules={{
        required: true,
        maxLength: 200,
      }}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            {t('other_actions.step_params.fields.tracking_number.label')}
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              {...field}
              placeholder={t(
                'other_actions.step_params.fields.tracking_number.placeholder'
              )}
            />
          </FormControl>
          <FormMessage match="required">
            {t(
              'other_actions.step_params.fields.tracking_number.validation_errors.required'
            )}
          </FormMessage>
          <FormMessage match="maxLength">
            {t(
              'other_actions.step_params.fields.tracking_number.validation_errors.maxLength',
              {
                maxLength: '200',
              }
            )}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

TrackingNumberFormFields.displayName = 'TrackingNumberFormFields';
