import React from 'react';

import Icon from '@appchoose/icon';

export type PhotoViewProps = {
  photo: { url: string; selected: boolean };
  isSelected: boolean;
  children: React.ReactNode;
};

export const PhotoView: React.FC<PhotoViewProps> = ({
  photo,
  isSelected,
  children,
}: PhotoViewProps) => {
  return (
    <div
      className={`relative flex h-max w-max cursor-pointer flex-col items-center justify-center rounded-lg transition duration-300 focus-within:ring-2 ${
        isSelected
          ? 'border-2 border-green-900 focus-within:ring-green-900/30'
          : 'border-2 border-gray-100 focus-within:ring-gray-900/30 hover:border-gray-900'
      }`}
    >
      {children}
      <img
        src={photo.url}
        alt=""
        className="h-40 rounded-lg border-2 border-white object-contain"
      />
      {isSelected && (
        <div className="absolute right-0 top-0 -mr-2 -mt-2 rounded-[0.1875rem] bg-white p-0.5">
          <div className="rounded-[0.1875rem] bg-green-900 p-0.5 text-white">
            <Icon icon="check" />
          </div>
        </div>
      )}
    </div>
  );
};
