import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { toast } from '@appchoose/toast';

import { selectedState } from '../../stores/selected';
import { useSetUserNoteMutation } from '../../types/generated';

export const UserSidebarNote: React.FC = () => {
  const { t } = useTranslation();

  const [selected] = useRecoilState(selectedState);

  const [note, setNote] = useState('');
  const [setUserNote, { error: userNoteError }] = useSetUserNoteMutation();

  useEffect(() => {
    setNote(selected?.user?.support_note ?? '');
  }, [selected]);

  useEffect(() => {
    if (userNoteError) {
      toast.error(t('sidebar.note.save_note_error'));
    }
  }, [userNoteError]);

  const onBlurNote = () => {
    if (selected?.user?.userKey) {
      const userKey = selected.user.userKey;

      setUserNote({
        variables: {
          userId: userKey,
          message: note,
        },
      });
    }
  };

  if (!selected) return null;

  return (
    <textarea
      className="note w-full rounded border border-gray-500 p-2 text-sm text-gray-700 placeholder:text-gray-500 focus:border-gray-900"
      placeholder={t('sidebar.note.add_note') || ''}
      key={selected.user.userKey}
      value={note}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(event?.target.value);
      }}
      onBlur={() => onBlurNote()}
    />
  );
};

UserSidebarNote.displayName = 'UserSidebarNote';
