import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import { useUpdateBillingOrdersBillingAddressMutation } from '../../../types/generated';
import { OtherActionsForm, StepProps, StepRef } from '../other-actions-modal';
import { OtherActionsModalBillingAddressFormFields } from '../other-actions-modal-billing-address-form-fields';

export const OtherActionsChangeBillingAddress = forwardRef<StepRef, StepProps>(
  ({ step, order }, ref) => {
    const { t } = useTranslation();

    const [
      updateBillingOrdersBillinAddressMutation,
      { error: updateBillingOrdersBillinAddressError },
    ] = useUpdateBillingOrdersBillingAddressMutation();

    useEffect(() => {
      if (updateBillingOrdersBillinAddressError) {
        toast.error(t('other_actions.errors.update_order_billing_error'));
      }
    }, [updateBillingOrdersBillinAddressError]);

    useImperativeHandle(ref, () => ({
      onNextStep: async (data: OtherActionsForm) => {
        await updateBillingOrdersBillinAddressMutation({
          variables: {
            input: {
              firstname: data.billing.firstname || '',
              lastname: data.billing.lastname || '',
              street: data.billing.street || '',
              streetAdditional: data.billing.street2,
              zipCode: data.billing.bp || '',
              city: data.billing.city || '',
              county: data.billing.province,
              countryCode: data.billing.countryCode || '',
            },
            billingId: order?.billingId || '',
          },
        });

        toast.success(
          t('other_actions.confirmation_message.change_billing_address')
        );
      },
    }));

    return (
      <>
        {step === 1 && (
          <OtherActionsModalBillingAddressFormFields order={order} />
        )}
      </>
    );
  }
);

OtherActionsChangeBillingAddress.displayName =
  'OtherActionsChangeBillingAddress';
