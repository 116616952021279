import { WebSocketLink } from '@apollo/client/link/ws';
import * as Sentry from '@sentry/react';

import { fetchApiKey } from './apollo';

export const wsLink = new WebSocketLink({
  uri: import.meta.env.REACT_APP_WS_URL as string,
  options: {
    lazy: true,
    timeout: 5 * 60000,
    reconnect: true,
    connectionParams: async () => {
      const authToken = await fetchApiKey();
      return {
        authToken,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    connectionCallback: async (errors: Error | Error[]) => {
      if (errors) {
        if (Array.isArray(errors)) {
          for (const err of errors) {
            Sentry.captureException(err);
            if (err.message.includes('Unauthorized')) {
              const result = await fetchApiKey();
              console.log(result);
              // setApiKey(result.data.api as string);
            }
          }
        } else {
          Sentry.captureException(errors);
          if (errors.message.includes('Unauthorized')) {
            const result = await fetchApiKey();
            console.log(result);
            // setApiKey(result.data.api as string);
          }
        }
      }
    },
  },
  webSocketImpl: WebSocket,
});
(wsLink as any).subscriptionClient.maxConnectTimeGenerator.setMin(30000);
// (this.wsLink as any).subscriptionClient.maxConnectTimeGenerator.duration = () => (this.wsLink as any).subscriptionClient.maxConnectTimeGenerator.max
