import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';

import { Order } from '../../types/order';
import { transformDateSimple } from '../../utils/date';
import { getClaimReasonOrOther, isClaimPending } from '../../utils/utils';
import { CancelComplaint } from '../cancel-complaint/cancel-complaint';

type OrderClaimDetailsProps = {
  claim: Order['claims'][0];
  orderId: Order['id'];
};

export const OrderClaimDetails: React.FC<OrderClaimDetailsProps> = ({
  claim,
  orderId,
}: OrderClaimDetailsProps) => {
  const { i18n, t } = useTranslation();

  const [displayCancelComplaintModal, setDisplayCancelComplaintModal] =
    useState(false);

  if (!claim) return null;

  const claimReasonOrOther = getClaimReasonOrOther(
    claim.reason,
    claim.otherReasonLabel,
    t
  );

  if (isClaimPending(claim)) {
    return (
      <div className="mb-3">
        <div className="flex">
          <Icon icon="complains" size="large" className="mr-1 text-red-600" />
          <div className="space-y-0.5 leading-none text-red-600">
            <div className="space-x-2 text-sm font-semibold">
              <span>{t('order.claims.claim_open')}</span>
              {claim.isOpenedByBot ? (
                <span className="inline-flex items-center rounded bg-gray-100 px-1 py-0.75 text-xxs font-normal text-gray-900">
                  {t('sidebar.claim_auto_label')}
                </span>
              ) : null}
            </div>
            <p className="text-xs font-semibold text-gray-900">
              {claimReasonOrOther}
            </p>
            <time dateTime={claim.createdAt} className="text-xs text-gray-500">
              {transformDateSimple(
                new Date(claim.createdAt),
                i18n.language === 'fr' ? 'fr' : 'en',
                'dd/MM/yyyy'
              )}
            </time>
            <Modal
              open={displayCancelComplaintModal}
              onOpenChange={setDisplayCancelComplaintModal}
            >
              <ModalTrigger
                type="button"
                className="flex cursor-pointer items-center text-xs font-semibold text-green-900"
              >
                {t('cancel')}
              </ModalTrigger>
              <ModalContent>
                <CancelComplaint
                  claim={claim}
                  orderId={orderId}
                  setIsOpen={setDisplayCancelComplaintModal}
                />
              </ModalContent>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
  if (claim.isAccepted || claim.isDeclined) {
    return (
      <div className="mb-3">
        <div className="flex">
          <Icon icon="complains" size="large" className="mr-1 text-[#767A7C]" />
          <div className="leading-none">
            <div className="space-x-2 text-sm font-semibold text-[#767A7C]">
              <span>{t('order.claims.claim_closed')}</span>
              {claim.isOpenedByBot ? (
                <span className="inline-flex items-center rounded bg-gray-100 px-1 py-0.75 text-xxs font-normal text-gray-900">
                  {t('order.claims.claim_auto_label')}
                </span>
              ) : null}
            </div>
            <p className="text-xs font-semibold text-gray-900">
              {claimReasonOrOther}
            </p>
            {claim.updatedAt ? (
              <time
                dateTime={claim.updatedAt}
                className="text-xs text-gray-500"
              >
                {transformDateSimple(
                  new Date(claim.updatedAt),
                  i18n.language === 'fr' ? 'fr' : 'en',
                  'dd/MM/yyyy'
                )}
              </time>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

OrderClaimDetails.displayName = 'OrderReclame';
