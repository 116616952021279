import { atom, selector } from 'recoil';

import { CategoryData, TemplateData } from '../types/generated';

export const categoriesState = atom<CategoryData[] | null | undefined>({
  key: 'categoriesState',
  default: undefined,
});

export const templatesState = atom<TemplateData[] | null | undefined>({
  key: 'templatesState',
  default: undefined,
});

export const templatesMapState = selector({
  key: 'templatesMapState',
  get: ({ get }) => {
    const categories = get(categoriesState);
    const templates = get(templatesState);

    const templatesMap = [] as {
      key: string;
      name: string;
      data: TemplateData[];
    }[];

    categories?.forEach((category) => {
      if (typeof category.id !== 'undefined') {
        templatesMap.push({
          key: `##${category.id ?? 0}`,
          name: category.name ?? '',
          data: [],
        });
      }
    });

    templates?.forEach((template) => {
      if (typeof template.category !== 'undefined') {
        templatesMap
          .find((category) => `##${template.category ?? 0}` === category.key)
          ?.data.push(template);
      }
    });

    return templatesMap;
  },
});
