import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Loader from '@appchoose/loader';

import crossIcon from '../../../../assets/cross.svg';
import { useRefundOrderItemsPartiallyMutation } from '../../../../types/generated';
import { Order } from '../../../../types/order';
import { formatPrice } from '../../../../utils/currency';
import { AnimatedCheck } from '../../../animated-check/animated-check';
import { RefundForm } from '../../../open-complaint/open-complaint';

type OtherActionsRefundStepRefundProps = {
  order: Order | undefined;
  setShowFooter?: (isOpen: boolean) => void;
};

export const OtherActionsRefundStepRefund = ({
  order,
  setShowFooter,
}: OtherActionsRefundStepRefundProps) => {
  const { i18n, t } = useTranslation();
  const { getValues, control } = useFormContext<RefundForm>();
  const { fields } = useFieldArray({
    control,
    name: 'selected',
  });

  setShowFooter?.(false);

  const [
    refundOrderItems,
    {
      loading: refundOrderItemsLoading,
      data: refundOrderItemsData,
      error: refundOrderItemsError,
    },
  ] = useRefundOrderItemsPartiallyMutation();

  useEffect(() => {
    refundOrderItems({
      variables: {
        orderId: order?.id ?? '',
        input: {
          apply: true,
          refundIntents: fields.map((field) => ({
            itemId: field.product.id,
            percentageToRefund: getValues('percentage'),
          })),
          refundInfo: {
            coveredBy: getValues('initiator'),
            reason: getValues('refundReason'),
          },
        },
      },
    });
  }, [fields]);

  return (
    <div className="flex flex-auto flex-col items-center justify-center">
      {/* REMBOURSEMENT EN COURS */}
      {refundOrderItemsLoading ? <Loader className="h-24 w-24" /> : null}

      {/* REMBOURSEMENT ERROR */}
      {refundOrderItemsError ? (
        <>
          <img className="size-32" src={crossIcon} alt="" />
          <p className="mt-8 text-2xl font-semibold text-gray-700">
            {t('other_actions.refund.error_description')}
          </p>
          <p className="mt-2 text-gray-700">
            {t('other_actions.refund.refund_not_made')}
          </p>
        </>
      ) : null}

      {/* REMBOURSEMENT INFO */}
      {refundOrderItemsData ? (
        <>
          <AnimatedCheck className="h-32 w-32" />
          <p className="mt-8 text-2xl font-semibold text-gray-700">
            {t('other_actions.refund.refund_made', {
              amount: formatPrice(
                (refundOrderItemsData.refundOrderItemsPartially
                  .cashAmountInCents +
                  refundOrderItemsData.refundOrderItemsPartially
                    .creditAmountInCents) /
                  100,
                i18n.language === 'fr' ? 'fr' : 'en',
                order?.totalPriceSold.currency ?? 'EUR'
              ),
            })}
          </p>
          <p className="mt-2 text-gray-700">
            {t('other_actions.refund.refund_detail', {
              cash: formatPrice(
                refundOrderItemsData.refundOrderItemsPartially
                  .cashAmountInCents / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                order?.totalPriceSold.currency ?? 'EUR'
              ),
              credits: formatPrice(
                refundOrderItemsData.refundOrderItemsPartially
                  .creditAmountInCents / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                order?.totalPriceSold.currency ?? 'EUR'
              ),
            })}
          </p>
        </>
      ) : null}
    </div>
  );
};
