import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Loader from '@appchoose/loader';

import crossIcon from '../../../../assets/cross.svg';
import { useRefundOrderShippingPartiallyMutation } from '../../../../types/generated';
import { Order } from '../../../../types/order';
import { formatPrice } from '../../../../utils/currency';
import { AnimatedCheck } from '../../../animated-check/animated-check';
import { RefundForm } from '../../../open-complaint/open-complaint';

type OtherActionsShippingRefundStepRefundProps = {
  order: Order | undefined;
  setShowFooter?: (isOpen: boolean) => void;
};

export const OtherActionsShippingRefundStepRefund = ({
  order,
  setShowFooter,
}: OtherActionsShippingRefundStepRefundProps) => {
  const { i18n, t } = useTranslation();

  const { getValues } = useFormContext<RefundForm>();

  setShowFooter?.(false);

  const [
    refundOrderShipping,
    {
      loading: refundOrderShippingPartiallyLoading,
      data: refundOrderShippingPartiallyData,
      error: refundOrderShippingPartiallyError,
    },
  ] = useRefundOrderShippingPartiallyMutation();

  useEffect(() => {
    refundOrderShipping({
      variables: {
        orderId: order?.id ?? '',
        input: {
          apply: true,
          refundIntent: {
            percentageToRefund: 100,
          },
          refundInfo: {
            coveredBy: getValues('initiator'),
          },
        },
      },
    });
  }, []);

  return (
    <div className="flex flex-auto flex-col items-center justify-center">
      {/* REMBOURSEMENT EN COURS */}
      {refundOrderShippingPartiallyLoading ? (
        <Loader className="h-24 w-24" />
      ) : null}

      {/* REMBOURSEMENT ERROR */}
      {refundOrderShippingPartiallyError ? (
        <>
          <img className="size-32" src={crossIcon} alt="" />
          <p className="mt-8 text-2xl font-semibold text-gray-700">
            {t('other_actions.refund.error_description')}
          </p>
          <p className="mt-2 text-gray-700">
            {t('other_actions.refund.refund_not_made')}
          </p>
        </>
      ) : null}

      {/* REMBOURSEMENT INFO */}
      {refundOrderShippingPartiallyData ? (
        <>
          <AnimatedCheck className="h-32 w-32" />
          <p className="mt-8 text-center text-2xl font-semibold text-gray-700">
            {t('other_actions.refund.shipping_refund_made', {
              amount: formatPrice(
                (refundOrderShippingPartiallyData.refundOrderShippingPartially
                  .cashAmountInCents +
                  refundOrderShippingPartiallyData.refundOrderShippingPartially
                    .creditAmountInCents) /
                  100,
                i18n.language === 'fr' ? 'fr' : 'en',
                order?.totalPriceSold.currency ?? 'EUR'
              ),
            })}
          </p>
          <p className="mt-2 text-gray-700">
            {t('other_actions.refund.refund_detail', {
              cash: formatPrice(
                refundOrderShippingPartiallyData.refundOrderShippingPartially
                  .cashAmountInCents / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                order?.totalPriceSold.currency ?? 'EUR'
              ),
              credits: formatPrice(
                refundOrderShippingPartiallyData.refundOrderShippingPartially
                  .creditAmountInCents / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                order?.totalPriceSold.currency ?? 'EUR'
              ),
            })}
          </p>
        </>
      ) : null}
    </div>
  );
};
