import { useTranslation } from 'react-i18next';

import Tooltip from '@appchoose/tooltip';

import { PaymentProcessor } from '../../types/generated';
import { Order } from '../../types/order';
import { transformPrice } from '../../utils/currency';
import { transformDateSimple } from '../../utils/date';
import { Threex } from '../icons/3x';

type AlmaProps = {
  payment: Order['payment'];
};

export const Alma: React.FC<AlmaProps> = ({ payment }: AlmaProps) => {
  const { i18n, t } = useTranslation();

  if (payment.processor !== PaymentProcessor.Alma) return null;

  const pendingPayments = payment.transactionPlan.pendingTransactions;
  return (
    <div className="flex items-center">
      <Threex className="mr-1 flex rounded bg-gray-50" />
      <Tooltip
        placement="top"
        content={
          <p>
            {pendingPayments
              .map(
                (pendingPayment) =>
                  `${transformPrice(
                    pendingPayment.amount,
                    i18n.language === 'fr' ? 'fr' : 'en',
                    pendingPayment.currency
                  )} le ${transformDateSimple(
                    new Date(pendingPayment.dueDate),
                    i18n.language === 'fr' ? 'fr' : 'en',
                    'dd/MM'
                  )}`
              )
              .join(' et le ')}
          </p>
        }
      >
        <span className="cursor-pointer text-xs text-gray-700">
          {t('order.payments.alma.dueWithCount', {
            count: pendingPayments.length,
          })}
        </span>
      </Tooltip>
    </div>
  );
};

Alma.displayName = 'Alma';
