import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';

import { History } from '../../components/icons/history';
import { ConversationCategory } from '../../types/generated';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { ReturnArrow } from '../icons/return-arrow';

type CategorySelectProps = {
  assignedCategory: ConversationCategory | undefined;
  assignCategory: (category: ConversationCategory) => void;
};

export const CategorySelect: React.FC<CategorySelectProps> = ({
  assignCategory,
  assignedCategory,
}: CategorySelectProps) => {
  const { t } = useTranslation();

  const categories: {
    label: string;
    value: ConversationCategory;
    icon: React.ReactNode;
  }[] = [
    {
      label: t('sidebar.pre_sales'),
      value: ConversationCategory.PreSales,
      icon: <History className="size-4" />,
    },
    {
      label: t('sidebar.delivery'),
      value: ConversationCategory.Delivery,
      icon: <Icon icon="truck" className="size-4" />,
    },
    {
      label: t('sidebar.order'),
      value: ConversationCategory.Order,
      icon: <Icon icon="orders" className="size-4" />,
    },
    {
      label: t('sidebar.post_operation'),
      value: ConversationCategory.PostOperation,
      icon: <Icon icon="tag" className="size-4" />,
    },
  ];

  const selectedCategory = categories.find(
    (category) => category.value === assignedCategory
  );

  return (
    <DropdownMenu>
      {assignedCategory ? (
        <DropdownMenuTrigger className="flex w-full cursor-pointer items-center justify-center space-x-1 rounded border border-gray-900 bg-transparent px-2 py-1.75 text-gray-700">
          {selectedCategory?.icon}
          <p className="flex flex-nowrap whitespace-nowrap text-xs font-semibold">
            {selectedCategory?.label}
          </p>
          <Icon icon="arrowDown" />
        </DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger asChild>
          <Button type="button" appearance="outline" size="small" containsIcon>
            <ReturnArrow />
          </Button>
        </DropdownMenuTrigger>
      )}

      <DropdownMenuContent align="end" className="p-0">
        <DropdownMenuGroup className="p-1">
          {categories.map((category) => (
            <DropdownMenuItem
              key={category.value}
              onSelect={() => assignCategory(category.value)}
              className="space-x-2 text-gray-700"
            >
              {category.icon}
              <p>{category.label}</p>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
