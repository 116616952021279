import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';
import Select, {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';

import { useUserClaims } from '../../hooks/use-user-claims';
import { statsState } from '../../stores/stats';
import { useUser } from '../../stores/usercontext';
import { ConversationTab } from '../../types/conversationTab';
import { OperatorUser, SortType } from '../../types/generated';
import { UserRole, checkRoles } from '../../utils/role';
import { getFirstName } from '../../utils/string';
import { useSort } from './useSort';

type ConversationListHeaderProps = {
  conversationTab: ConversationTab;
  totalHit: number;
  selectedOperator: OperatorUser | undefined;
};

export const ConversationListHeader: React.FC<ConversationListHeaderProps> = ({
  conversationTab,
  totalHit,
  selectedOperator,
}: ConversationListHeaderProps) => {
  const { t } = useTranslation();

  const { user: userAuthenticated } = useUser();
  const claims = useUserClaims();

  const [sort, setSort] = useSort();

  const stats = useRecoilValue(statsState);

  let title = '';
  let statsToRender: number | undefined = undefined;
  let hasSort = false;

  switch (conversationTab) {
    case ConversationTab.Unhandled:
      title = t('sidebar.all');
      statsToRender = stats.unhandled ?? undefined;
      hasSort = true;

      break;
    case ConversationTab.AssignedToMe:
      title = t('sidebar.me');
      statsToRender =
        stats?.assignOperatorRepartition?.find(
          (or) => or.operatorId === userAuthenticated?.uid
        )?.nbConversationAssigned ?? undefined;
      hasSort = true;

      break;
    case ConversationTab.AssignedTo:
      title = getFirstName(selectedOperator?.displayName);
      statsToRender =
        stats.assignOperatorRepartition?.find(
          (r) => r.operatorId === selectedOperator?.operatorId
        )?.nbConversationAssigned ?? undefined;
      hasSort = true;

      break;
    case ConversationTab.Unassigned:
      title = t('sidebar.unassigned');
      statsToRender = stats.unassigned ?? undefined;
      hasSort = true;

      break;

    case ConversationTab.PreSales:
      title = t('sidebar.pre_sales');
      statsToRender = stats.categoryPreSales ?? undefined;
      hasSort = true;

      break;

    case ConversationTab.Delivery:
      title = t('sidebar.delivery');
      statsToRender = stats.categoryDelivery ?? undefined;
      hasSort = true;

      break;

    case ConversationTab.Order:
      title = t('sidebar.order');
      statsToRender = stats.categoryOrder ?? undefined;
      hasSort = true;

      break;

    case ConversationTab.PostOperation:
      title = t('sidebar.post_operation');
      statsToRender = stats.categoryPostOperation ?? undefined;
      hasSort = true;

      break;

    case ConversationTab.Usa:
      title = t('sidebar.usa');
      statsToRender = stats.usa ?? undefined;
      hasSort = true;

      break;
    case ConversationTab.Pinned:
      title = t('sidebar.pinned');
      statsToRender = stats.pinned ?? undefined;
      hasSort = true;

      break;
    case ConversationTab.Read:
      title = t('sidebar.archived');
      statsToRender = undefined;
      hasSort = false;

      break;
    case ConversationTab.ClaimAuto:
      title = t('sidebar.claim_auto');
      statsToRender = stats.categoryBotProcessing ?? undefined;
      hasSort = false;

      break;
    case ConversationTab.Search:
      title = t('sidebar.result');
      statsToRender = totalHit;
      hasSort = false;

      break;
  }

  const sortOptions = [
    {
      name: t('list.sort.most_recent'),
      value: SortType.Desc,
    },
    {
      name: t('list.sort.oldest'),
      value: SortType.Asc,
    },
    {
      name: t('list.sort.ai_scoring'),
      value: SortType.AiScoring,
    },
  ];

  const onChangeSort = () => {
    if (sort === SortType.Desc) {
      setSort(SortType.Asc);
    } else {
      setSort(SortType.Desc);
    }
  };

  const onChangeSortIA = (value: SortType) => {
    setSort(value);
  };

  return (
    <>
      <div className="flex min-w-0 flex-initial items-end">
        <h2 className="flex flex-initial overflow-hidden text-xl font-bold leading-[1.875em] text-gray-900">
          <span className="truncate">{title}</span>
        </h2>
        {statsToRender ? (
          <span className="ml-2 flex shrink-0 text-sm font-normal leading-[2.125rem] text-gray-500">
            {statsToRender}
          </span>
        ) : null}
      </div>
      {hasSort &&
      checkRoles(
        {
          onlyAdmin: false,
          requireRole: [UserRole.IA_SUPPORT],
        },
        claims
      ) ? (
        <div className="ml-2">
          <Select onValueChange={onChangeSortIA} value={sort}>
            <SelectTrigger id="item-sorting" className="w-auto space-x-2">
              <SelectValue placeholder={t('list.sort.most_recent')} />
            </SelectTrigger>
            <SelectContent align="end">
              {sortOptions.map((option) => (
                <SelectItem value={option.value} key={option.value}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      ) : null}
      {hasSort &&
      !checkRoles(
        {
          onlyAdmin: false,
          requireRole: [UserRole.IA_SUPPORT],
        },
        claims
      ) ? (
        <div
          className="ml-4 mt-1 flex shrink-0 cursor-pointer text-xs font-semibold text-green-900"
          onClick={onChangeSort}
        >
          {sort === SortType.Desc
            ? t('list.sort.most_recent')
            : t('list.sort.oldest')}
          <Icon
            icon="arrowDown"
            className={`ml-1 ${sort === SortType.Desc ? 'rotate-180' : ''}`}
          />
        </div>
      ) : null}
    </>
  );
};

ConversationListHeader.displayName = 'ConversationListHeader';
