import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { ModalFooter, ModalTitle, ModalTrigger } from '@appchoose/modal';
import { toast } from '@appchoose/toast';

import { useCancelClaimMutation } from '../../types/generated';
import { Order } from '../../types/order';

type CancelComplaintProps = {
  claim: Order['claims'][0];
  orderId?: Order['id'];
  setIsOpen: (isOpen: boolean) => void;
};

export const CancelComplaint = ({
  claim,
  orderId,
  setIsOpen,
}: CancelComplaintProps) => {
  const { t } = useTranslation();

  const [
    cancelClaimMutation,
    { loading: cancelClaimLoading, error: cancelClaimError },
  ] = useCancelClaimMutation();

  useEffect(() => {
    if (cancelClaimError) {
      toast.error(t('complaint.cancel_claim.error'));
    }
  }, [cancelClaimError]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await cancelClaimMutation({
        variables: {
          orderId: orderId ?? '',
          claimId: claim.id,
        },
      });

      toast.success(t('complaint.cancel_claim.success'));

      setIsOpen(false);
    } catch (error) {
      // Something went wrong
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      className="flex h-full flex-col justify-between overflow-hidden"
    >
      <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
        <ModalTitle className="mb-4">
          {t('complaint.cancel_claim.title')}
        </ModalTitle>
        <p className="mb-10 text-sm text-gray-700">
          {t('complaint.cancel_claim.description')}
        </p>
      </div>

      <ModalFooter>
        <ModalTrigger asChild>
          <Button type="button" appearance="outline">
            {t('cancel')}
          </Button>
        </ModalTrigger>
        <Button
          appearance="primary"
          type="submit"
          disabled={cancelClaimLoading}
        >
          {t('complaint.cancel_claim.confirm')}
        </Button>
      </ModalFooter>
    </form>
  );
};
