import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { TFunction } from 'i18next';

import { OrderTagClaim } from '../../types/generated';
import { OrderBadge } from './order-badge';

export const getOrderClaimLabelFromTag = (tag: OrderTagClaim, t: TFunction) => {
  switch (tag) {
    case OrderTagClaim.Opened:
      return t('order.status.claim.open');
    case OrderTagClaim.Closed:
      return t('order.status.claim.closed');
  }
};

export const OrderClaimBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagClaim;
}> = ({ isCancelled, tag }) => {
  const { t } = useTranslation();

  if (tag === OrderTagClaim.None) return null;

  return (
    <OrderBadge style={tag === OrderTagClaim.Opened ? 'danger' : 'default'}>
      <div className="flex items-center gap-x-1">
        {tag === OrderTagClaim.Opened ? (
          <Icon icon="complains" className="!size-3.5" />
        ) : null}
        <span className={cn({ 'line-through': isCancelled })}>
          {getOrderClaimLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
