import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { matchSorter } from 'match-sorter';

import { ServiceOption } from '../../types/services';

export type DeliveryServiceFormData = {
  deliveryService: string;
};

type DeliveryServiceFormFieldsProps = {
  deliveryServices: ServiceOption[];
};

export const DeliveryServiceFormFields: React.FC<
  DeliveryServiceFormFieldsProps
> = ({ deliveryServices }) => {
  const { t } = useTranslation();
  const form = useFormContext<DeliveryServiceFormData>();

  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();

  useEffect(() => {
    return () => {
      form.unregister('deliveryService', {
        keepValue: false,
        keepIsValid: false,
      });
    };
  }, []);

  const deliveryServiceOptions = deliveryServices.map((service) => ({
    label: service.text,
    value: service.type,
  }));

  const deliveryServiceMatches = useMemo(
    () => matchSorter(deliveryServiceOptions, searchQuery, { keys: ['label'] }),
    [deliveryServiceOptions, searchQuery]
  );

  return (
    <FormField
      control={form.control}
      name="deliveryService"
      rules={{
        required: true,
      }}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            {t('other_actions.step_params.fields.delivery_service.label')}
          </FormLabel>
          <FormControl>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <button
                    type="button"
                    role="combobox"
                    aria-expanded={open}
                    className='form-input flex w-full items-center justify-between rounded border-gray-500 text-sm leading-5.5 text-gray-900 transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-1 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                  >
                    <div className="flex items-center truncate">
                      {field.value
                        ? deliveryServiceOptions.find(
                            (category) => category.value === field.value
                          )?.label
                        : t(
                            'other_actions.step_params.fields.delivery_service.empty_field'
                          )}
                    </div>
                    <Icon
                      icon={open ? 'close' : 'arrowDown'}
                      className="ml-2 size-4 shrink-0 opacity-50"
                    />
                  </button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent
                className="p-0"
                align="end"
                style={{
                  width: 'var(--radix-popover-trigger-width)',
                }}
              >
                <Command shouldFilter={false}>
                  <CommandInput
                    placeholder={t(
                      'other_actions.step_params.fields.delivery_service.placeholder'
                    )}
                    onValueChange={(value) => {
                      setSearchQuery(value);

                      //#region scroll list to top when typing
                      // https://github.com/pacocoursey/cmdk/issues/234
                      // https://github.com/pacocoursey/cmdk/issues/233

                      // clear pending scroll
                      if (scrollId.current)
                        cancelAnimationFrame(scrollId.current);

                      // the setTimeout is used to create a new task
                      // this is to make sure that we don't scroll until the user is done typing
                      // you can tweak the timeout duration ofc
                      scrollId.current = requestAnimationFrame(() => {
                        // inside your list select the first group and scroll to the top
                        listRef.current?.scrollTo({ top: 0 });
                      });

                      //#endregion
                    }}
                    value={searchQuery}
                  />
                  <CommandList ref={listRef}>
                    <CommandEmpty>
                      {t(
                        'other_actions.step_params.fields.delivery_service.no_result'
                      )}
                    </CommandEmpty>

                    <CommandGroup>
                      {deliveryServiceMatches.map((category) => (
                        <CommandItem
                          key={category.value}
                          value={category.value}
                          keywords={[category.label]}
                          onSelect={(currentValue) => {
                            field.onChange(
                              currentValue === field.value ? '' : currentValue
                            );
                            setOpen(false);
                          }}
                          className={cn('flex items-center justify-between', {
                            'font-semibold text-green-900':
                              field.value === category.value,
                          })}
                        >
                          <div className="flex items-center truncate">
                            {category.label}
                          </div>
                          <Icon
                            icon="check"
                            className={cn(
                              'ml-2 size-4 shrink-0',
                              field.value === category.value
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormMessage match="required">
            {t(
              'other_actions.step_params.fields.delivery_service.validation_errors.required'
            )}
          </FormMessage>
        </FormItem>
      )}
    />
  );
};

DeliveryServiceFormFields.displayName = 'DeliveryServiceFormFields';
