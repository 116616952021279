import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { useRecoilState } from 'recoil';

import Flag from '@appchoose/flag';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';

import { track } from '../../api/analytics';
import defaultAvatar from '../../assets/defaultAvatar.svg';
import { selectedState } from '../../stores/selected';
import { StoreRegion } from '../../types/generated';
import { capitalize } from '../../utils/string';
import { CurrentTime } from './current-time';

export const UserSidebarUserInfo: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [selected] = useRecoilState(selectedState);

  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = clipboardState;
    if (value) {
      toast.success(t('clipboard.copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [clipboardState]);

  if (!selected) return null;

  const displayedLanguage = selected.user?.language
    ? capitalize(
        new Intl.DisplayNames([i18n.language === 'fr' ? 'fr' : 'en'], {
          type: 'language',
        }).of(selected.user?.language.substring(0, 2))
      )
    : 'Inconnue';

  return (
    <header className="mb-5 flex items-start justify-between">
      <div className="group line-clamp-3">
        <h3
          className="mb-2 line-clamp-3 cursor-pointer text-xl font-bold text-gray-900"
          onClick={() => {
            copyToClipboard(selected.user?.name ?? '');
            track('CopyToClipboard', {
              label: 'user_name',
            });
          }}
        >
          {selected.user?.name ?? t('anonymous')}
        </h3>

        <div className="flex flex-col space-y-2">
          <div className="flex items-center text-xs text-gray-500">
            <Icon icon="clock" className="mr-1 shrink-0" />
            <div className="flex flex-wrap">
              <CurrentTime timezone={selected.user?.timezone ?? undefined} />
            </div>
          </div>
          <div className="flex items-center text-xs text-gray-500">
            <Icon icon="flag" className="mr-1 shrink-0" />
            <div className="flex flex-wrap">
              {t('language', { language: displayedLanguage })}
            </div>
          </div>
          <div className="flex items-center text-xs text-gray-500">
            {selected?.user?.device?.manufacturer && (
              <Icon
                icon={
                  selected?.user?.device?.manufacturer === 'Apple'
                    ? 'apple'
                    : 'android'
                }
                className="mr-1 shrink-0"
              />
            )}
            <div className="flex flex-wrap">
              <Tooltip
                className="flex flex-nowrap"
                trigger="mouseenter click"
                placement="bottom"
                content={
                  <div className="px-1.5 py-3">{selected.user?.appVersion}</div>
                }
              >
                <span>
                  {selected.user?.has_latest_version ? 'À jour' : 'Pas à jour'}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex shrink-0">
        <img
          className="ml-4 h-12 w-12 rounded-3xl object-cover"
          src={
            selected.user.profilePicture
              ? selected.user.profilePicture
              : defaultAvatar
          }
        />
        <div className="absolute -bottom-0.5 left-1.5 flex h-5 w-7 items-center justify-center rounded-[0.1875rem] bg-gray-50">
          <Flag
            className="h-6 w-6"
            flag={selected.user.currentStore === StoreRegion.Us ? 'US' : 'EU'}
          />
        </div>
      </div>
    </header>
  );
};

UserSidebarUserInfo.displayName = 'UserSidebarUserInfo';
