import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import { useDebouncedState } from '@react-hookz/web';

import { config } from '../../app/config';
import { UserSidebar } from '../../components/user-sidebar/user-sidebar';
import { activeOperatorsState } from '../../stores/activeOperators';
import { isProcessingState } from '../../stores/isProcessing';
import {
  emailsState,
  messagesOrEmailsDataState,
  messagesState,
} from '../../stores/messages';
import { mobileMenuOpenState } from '../../stores/mobileMenuOpenState';
import { operatorsState } from '../../stores/operators';
import { ordersState } from '../../stores/orders';
import { ordersLoadingState } from '../../stores/ordersLoading';
import {
  MessageFromSearchResult,
  messagesFromSearchResultsState,
  searchResultsState,
} from '../../stores/searchResults';
import { selectedState } from '../../stores/selected';
import {
  firstEmailFromSelectedEmailState,
  selectedEmailState,
} from '../../stores/selectedEmail';
import { selectedIndexState } from '../../stores/selectedIndex';
import { statsState } from '../../stores/stats';
import { useUser } from '../../stores/usercontext';
import { ConversationTab } from '../../types/conversationTab';
import {
  ConversationCategory,
  ConversationEmail,
  ConversationMessage,
  ConversationMessageGroup,
  Email,
  Message,
  MessageChanged,
  MessageStatus,
  NewMessageSubscription,
  OperatorActive,
  OperatorUser,
  StoreRegion,
  UserElasticSearchHit,
  useCustomerOrdersLazyQuery,
  useFindUserLazyQuery,
  useGetConversationEmailLazyQuery,
  useGetConversationV2LazyQuery,
  useInboxV2LazyQuery,
  useMessageChangedSubscription,
  useNewMessageSubscription,
  useNewOperatorReplyingSubscription,
  useOperatorReplyingMutation,
  useRefreshStatsSubscription,
  useStatsLazyQuery,
} from '../../types/generated';
import { SupportMatch } from '../../types/navigation';
import { IUser } from '../../types/user';
import {
  debounce2,
  getConversationTab,
  getFilters,
  getPathnameFromConversationTab,
} from '../../utils/utils';
import { Conversation } from './conversation';
import { ConversationList } from './conversation-list';
import { ConversationListHeader } from './conversation-list-header';
import { useConversationStatus } from './useConversationStatus';
import { useSort } from './useSort';

import './MessagesScreen.scss';

const INBOX_LIMIT = 50;

export const MessagesScreen: React.FC = () => {
  const { t } = useTranslation();

  const [selectedEmail, setSelectedEmail] = useRecoilState(selectedEmailState);
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexState);
  const [selected, setSelected] = useRecoilState(selectedState);
  const resetSelectedEmail = useResetRecoilState(selectedEmailState);
  const resetSelectedIndex = useResetRecoilState(selectedIndexState);
  const resetSelected = useResetRecoilState(selectedState);
  const setActiveOperatorsState = useSetRecoilState(activeOperatorsState);
  const setStats = useSetRecoilState(statsState);
  const setIsProcessing = useSetRecoilState(isProcessingState);
  const operators = useRecoilValue(operatorsState);
  const setMobileMenuOpen = useSetRecoilState(mobileMenuOpenState);
  const messagesFromSearchResults = useRecoilValue(
    messagesFromSearchResultsState
  );
  const setSearchResultsState = useSetRecoilState(searchResultsState);
  const firstEmailFromSelectedEmail = useRecoilValue(
    firstEmailFromSelectedEmailState
  );
  const setEmails = useSetRecoilState(emailsState);
  const resetEmails = useResetRecoilState(emailsState);
  const [messages, setMessages] = useRecoilState(messagesState);
  const resetMessages = useResetRecoilState(messagesState);
  const messagesOrEmailsData = useRecoilValue(messagesOrEmailsDataState);
  const setOrders = useSetRecoilState(ordersState);
  const setOrdersLoading = useSetRecoilState(ordersLoadingState);

  const resetAllSelected = () => {
    resetSelected();
    resetSelectedEmail();
    resetSelectedIndex();
  };

  const resetMessagesAndEmails = () => {
    resetEmails();
    resetMessages();
  };

  const { user: userAuthenticated } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const { id, operatorId } = useParams<SupportMatch>();

  const [initialLoad, setInitialLoad] = useState(true);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [disableContent, setDisableContent] = useState(false);
  const [totalHit, setTotalHit] = useState(0);

  // TODO: supprimer conversationStatus
  const conversationStatus = useConversationStatus();
  const [conversationTab] = useState<ConversationTab>(
    getConversationTab(location.pathname, userAuthenticated?.uid ?? '')
  );
  const [selectedOperator, setSelectedOperator] = useState<
    OperatorUser | undefined
  >(undefined);
  const [sort] = useSort();

  const [messageOffset, setMessageOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get('q') ?? ''
  );
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebouncedState(
    new URLSearchParams(location.search).get('q') ?? '',
    250
  );

  const [inboxV2, { data: inboxData }] = useInboxV2LazyQuery();
  const [searchResult, { data: searchResultData }] = useFindUserLazyQuery();
  const [statsLazyQuery, { data: statsData }] = useStatsLazyQuery();
  const [
    loadConversation,
    { data: loadConversationData, error: loadConversationError },
  ] = useGetConversationV2LazyQuery();
  const [
    refreshConversation,
    { data: refreshConversationData, error: refreshConversationError },
  ] = useGetConversationV2LazyQuery();
  const [
    getConversationEmail,
    { data: conversationEmailData, error: conversationEmailError },
  ] = useGetConversationEmailLazyQuery();
  const [
    customerOrders,
    {
      data: customerOrdersData,
      loading: customerOrdersLoading,
      error: customerOrdersError,
    },
  ] = useCustomerOrdersLazyQuery({ errorPolicy: 'all' });

  const [operatorReplyingMutation] = useOperatorReplyingMutation();

  const { data: refreshStatsData, error: refreshStatsError } =
    useRefreshStatsSubscription();
  const { data: newMessageData, error: newMessageError } =
    useNewMessageSubscription();
  const { data: messageChangedData, error: messageChangedError } =
    useMessageChangedSubscription();
  const { data: newOperatorReplyingData, error: newOperatorReplyingError } =
    useNewOperatorReplyingSubscription();

  const conversations =
    conversationTab === ConversationTab.Search
      ? messagesFromSearchResults
      : messagesOrEmailsData;

  const messagesOrSearchResults =
    conversationTab === ConversationTab.Search
      ? messagesFromSearchResults
      : messages;

  useEffect(() => {
    window.addEventListener('unload', () => {
      onChangeMessage('');
    });

    window.onbeforeunload = () => {
      changeOperationStatus('', true);
    };

    return () => {
      onChangeMessage('');
    };
  }, []);

  useEffect(() => {
    setSelectedOperator(
      operatorId
        ? operators.find((operator) => operator.operatorId === operatorId)
        : undefined
    );
  }, [operators]);

  useEffect(() => {
    setInitialLoad(true);

    resetAllSelected();
    resetMessagesAndEmails();

    setDisplayLoader(true);

    setHasMoreMessages(true);
    setMessageOffset(0);
    setSelectedOperator(
      operatorId
        ? operators.find((operator) => operator.operatorId === operatorId)
        : undefined
    );

    if (conversationTab !== ConversationTab.Search) {
      inboxV2({
        variables: {
          filter: getFilters(
            conversationTab,
            conversationTab === ConversationTab.AssignedToMe
              ? userAuthenticated?.uid
              : operatorId
          ),
          offset: 0,
          limit: INBOX_LIMIT,
          sort: sort,
        },
      });
    } else {
      searchResult({
        variables: {
          search: searchTerm?.trim(),
        },
      });
    }
  }, [sort, operatorId]);

  useUpdateEffect(() => {
    if (selected) {
      resetAllSelected();
    }
  }, [searchTerm]);

  useUpdateEffect(() => {
    navigate(`/${config.pathname.search}?q=${searchTerm}`, { replace: true });

    setDisplayLoader(true);

    resetMessagesAndEmails();

    searchResult({ variables: { search: searchTerm?.trim() } });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (inboxData) {
      if (inboxData.inboxV2) {
        const messagesFromInbox = inboxData.inboxV2.messages
          ? inboxData.inboxV2.messages.map((c, idx) => ({
              ...c,
              name: c.name ?? 'Anonyme' + idx,
            }))
          : [];

        setMessages([...(messages ?? []), ...(messagesFromInbox || [])]);

        setEmails(
          conversationTab !== ConversationTab.Usa
            ? inboxData.inboxV2.emails
            : []
        );

        if (inboxData.inboxV2.messages) {
          setMessageOffset(messageOffset + inboxData.inboxV2.messages.length);
        }
      }

      if (!inboxData.inboxV2 || inboxData.inboxV2.messages.length === 0) {
        setHasMoreMessages(false);
      }

      if (initialLoad) setInitialLoad(false);

      setDisplayLoader(false);
    }
  }, [inboxData]);

  useEffect(() => {
    if (searchResultData) {
      if (searchResultData?.findUser) {
        setTotalHit(searchResultData.findUser?.total ?? 0);
        setSearchResultsState(
          searchResultData.findUser?.hits?.filter(
            (hit): hit is UserElasticSearchHit => !!hit
          ) || []
        );
      } else {
        setTotalHit(0);
      }

      if (initialLoad) setInitialLoad(false);

      setDisplayLoader(false);
    }
  }, [searchResultData]);

  useEffect(() => {
    if (initialLoad === false) {
      selectConversation();
    }
  }, [initialLoad]);

  useEffect(() => {
    if (statsData?.stats) {
      setStats(statsData.stats);
    }
  }, [statsData]);

  useEffect(() => {
    if (loadConversationData?.conversationV2) {
      setInactive(false);
      onChangeMessage('', loadConversationData.conversationV2.user?.userKey);

      const conversation = loadConversationData.conversationV2;

      setSelected({
        user: conversation.user as IUser,
        messages: conversation.messages?.filter(
          (
            m: ConversationMessage | ConversationMessageGroup
          ): m is ConversationMessage | ConversationMessageGroup => !!m
        ),
        status: conversation.status,
      });
      resetSelectedEmail();
      const foundIndex = messagesOrEmailsData.findIndex(
        (c) => 'email' in c && c.email === conversation.user?.userKey
      );
      if (foundIndex === -1) {
        resetSelectedIndex();
      } else {
        setSelectedIndex(foundIndex);
      }

      setIsProcessing(false);
    }
  }, [loadConversationData]);

  useEffect(() => {
    if (loadConversationError) {
      toast.error(
        'Une erreur est survenue lors du chargement de la conversation'
      );
    }
  }, [loadConversationError]);

  useEffect(() => {
    if (refreshConversationData?.conversationV2) {
      const conversation = refreshConversationData.conversationV2;

      if (selected && conversation.messages) {
        setSelected({
          user: conversation.user as IUser,
          messages: conversation.messages?.filter(
            (
              m: ConversationMessage | ConversationMessageGroup
            ): m is ConversationMessage | ConversationMessageGroup => !!m
          ),
          status: conversation.status,
        });
      }

      setIsProcessing(false);
    }
  }, [refreshConversationData]);

  useEffect(() => {
    if (refreshConversationError) {
      toast.error(
        'Une erreur est survenue lors du rafraîchissement de la conversation'
      );
    }
  }, [refreshConversationError]);

  useEffect(() => {
    if (conversationEmailData) {
      const conversation = {
        emails:
          conversationEmailData.emails
            ?.filter((email): email is ConversationEmail => !!email)
            .map((email) => ({
              ...email,
              email: email.email ?? '',
              body: email.body ?? '',
              fromChoose: email.fromChoose ?? false,
              isFixed: email.isFixed ?? false,
              isPinned: email.isPinned ?? false,
              createdAt: email.createdAt ?? '',
            })) ?? [],
      };

      resetSelected();
      setSelectedEmail(
        conversation.emails.sort((a, b) => {
          return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
        })
      );

      const foundIndex = messagesOrEmailsData.findIndex(
        (c) => 'email' in c && c.email === conversation.emails?.[0]?.email
      );

      if (foundIndex === -1) {
        resetSelectedIndex();
      } else {
        setSelectedIndex(foundIndex);
      }

      navigate(
        `/${getPathnameFromConversationTab(
          conversationTab,
          conversationTab === ConversationTab.AssignedToMe
            ? userAuthenticated?.uid
            : operatorId
        )}/email`,
        { replace: true }
      );
      setIsProcessing(false);
    }
  }, [conversationEmailData]);

  useEffect(() => {
    if (conversationEmailError) {
      toast.error(
        'Une erreur est survenue lors du chargement de la conversation'
      );
    }
  }, [conversationEmailError]);

  useEffect(() => {
    if (customerOrdersData?.customerOrders) {
      setOrders(customerOrdersData.customerOrders);
    }
  }, [customerOrdersData]);

  useEffect(() => {
    setOrdersLoading(customerOrdersLoading);
  }, [customerOrdersLoading]);

  useEffect(() => {
    if (customerOrdersError) {
      toast.error(t('orders.loading_orders_error'));
    }
  }, [customerOrdersError]);

  useEffect(() => {
    if (refreshStatsData?.refreshStats) {
      setStats(refreshStatsData.refreshStats);
    }
  }, [refreshStatsData]);

  useEffect(() => {
    if (refreshStatsError)
      toast.error('Une erreur est survenue en rafraîchissant les statistiques');
  }, [refreshStatsError]);

  useEffect(() => {
    if (newMessageData?.newMessage) {
      const selectedConversation = selected;
      if (
        selectedConversation?.user?.userKey &&
        newMessageData?.newMessage?.userKey ===
          selectedConversation.user.userKey
      ) {
        setIsProcessing(true);
        refreshConversation({
          variables: { key: newMessageData?.newMessage?.userKey },
        });
      } else {
        switch (conversationTab) {
          case ConversationTab.Unhandled:
            if (newMessageData.newMessage.userStore === StoreRegion.Fr) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.AssignedToMe:
            if (
              newMessageData?.newMessage?.assignToOperatorId ===
              userAuthenticated?.uid
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.AssignedTo:
            if (newMessageData?.newMessage?.assignToOperatorId === operatorId) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.ClaimAuto:
            if (
              newMessageData?.newMessage?.category ===
              ConversationCategory.BotProcessing
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.Unassigned:
            if (
              !newMessageData?.newMessage?.assignToOperatorId &&
              newMessageData?.newMessage?.userStore === StoreRegion.Fr
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.Usa:
            if (newMessageData?.newMessage?.userStore === StoreRegion.Us) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.Pinned:
            //TODO: implement pinned
            /*if (newMessageData?.newMessage?.isPinned) {
                addMessage(newMessageData?.newMessage);
              }*/
            break;
          case ConversationTab.PreSales:
            if (
              newMessageData?.newMessage?.category ===
              ConversationCategory.PreSales
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.Delivery:
            if (
              newMessageData?.newMessage?.category ===
              ConversationCategory.Delivery
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.Order:
            if (
              newMessageData?.newMessage?.category ===
              ConversationCategory.Order
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
          case ConversationTab.PostOperation:
            if (
              newMessageData?.newMessage?.category ===
              ConversationCategory.PostOperation
            ) {
              addMessage(newMessageData?.newMessage);
            }
            break;
        }
      }
      statsLazyQuery();
    }
  }, [newMessageData]);

  useEffect(() => {
    if (newMessageError) {
      toast.error(
        'Une erreur est survenue en rafraîchissant les nouveaux messages'
      );
    }
  }, [newMessageError]);

  useEffect(() => {
    if (newOperatorReplyingData?.newOperatorReplying) {
      setActiveOperatorsState(
        newOperatorReplyingData?.newOperatorReplying?.filter(
          (op): op is OperatorActive => !!op
        ) || []
      );
    }
  }, [newOperatorReplyingData]);

  useEffect(() => {
    if (newOperatorReplyingError) {
      toast.error(
        'Une erreur est survenue en rafraîchissant les opérateurs en train de répondre'
      );
    }
  }, [newOperatorReplyingError]);

  useEffect(() => {
    if (messageChangedData?.messageChanged) {
      messageChanged(messageChangedData.messageChanged);
    }
  }, [messageChangedData]);

  useEffect(() => {
    if (messageChangedError) {
      toast.error(
        'Une erreur est survenue en rafraîchissant les messages qui ont changé'
      );
    }
  }, [messageChangedError]);

  useEffect(() => {
    if (messagesFromSearchResults && messagesFromSearchResults.length > 0) {
      selectConversation();
    }
  }, [messagesFromSearchResults]);

  const fetchNextMessages = () => {
    setDisplayLoader(true);

    inboxV2({
      variables: {
        filter: getFilters(
          conversationTab,
          conversationTab === ConversationTab.AssignedToMe
            ? userAuthenticated?.uid
            : operatorId
        ),
        offset: messageOffset,
        limit: INBOX_LIMIT,
        sort: sort,
      },
    });
  };

  const selectConversation = () => {
    if (id === 'email') {
      return;
    }
    if ((messagesOrSearchResults?.length ?? 0) > 0) {
      const firstMessage = conversations[0] as
        | Message
        | MessageFromSearchResult;
      if (id) {
        if (id !== 'null') {
          const foundMessage = [...(messagesOrSearchResults ?? [])].find(
            (c) => c.userKey === id
          );
          if (
            foundMessage &&
            'userKey' in foundMessage &&
            foundMessage.userKey
          ) {
            selectMessage(foundMessage.userKey);
          } else if (firstMessage.userKey !== null) {
            selectMessage(id);
          }
        }
      } else if (firstMessage.userKey !== null) {
        navigate(
          `/${getPathnameFromConversationTab(
            conversationTab,
            conversationTab === ConversationTab.AssignedToMe
              ? userAuthenticated?.uid
              : operatorId
          )}/${firstMessage.userKey ?? ''}`,
          { replace: true }
        );
        selectMessage(firstMessage.userKey ?? '');
      }
    } else if (id && id !== 'null') {
      if (conversationTab === ConversationTab.Search) {
        selectMessage(id);
      } else {
        navigate(
          `/${getPathnameFromConversationTab(
            conversationTab,
            conversationTab === ConversationTab.AssignedToMe
              ? userAuthenticated?.uid
              : operatorId
          )}/`,
          { replace: true }
        );
      }
    }
  };

  const addMessage = (message: NewMessageSubscription['newMessage']) => {
    if (message && messages) {
      const idx = messages.findIndex((c) => c.userKey === message.userKey);
      if (idx === -1) {
        const messageAdded: Message = {
          assignTo: message.assignToOperatorId,
          body: message.body,
          createdAt: message.createdAt,
          id: undefined, // @TODO
          name: message.name,
          store: message.userStore as StoreRegion, // @TODOn,
          total_orders: message.total_orders,
          userKey: message.userKey,
          __typename: 'Message',
        };
        setMessages([...messages, messageAdded]);
      } else {
        setMessages([
          ...messages.filter((message, index) => index !== idx),
          {
            ...messages[idx],
            body: message.body,
            createdAt: message.createdAt ?? new Date().toUTCString(),
          },
        ]);
      }

      if (message?.userKey === id || id === 'null') {
        selectMessage(message?.userKey as string);
      }
    }
  };

  const removeMessage = (userKey: string) => {
    if (!messages) {
      return;
    }
    const shallowMessages = messages.filter((c) => c.userKey !== userKey);
    setMessages(shallowMessages);
  };

  const messageChanged = (message: MessageChanged) => {
    if (message.isAssign !== null && message.isAssign !== undefined) {
      if (selected && selected?.user?.userKey === message.userKey) {
        setSelected((prev) =>
          prev
            ? {
                ...prev,
                status: {
                  ...prev.status,
                  assignTo: message.assignToOperatorId,
                },
              }
            : undefined
        );
      }
      const foundMessage = [...(messagesOrSearchResults ?? [])].find(
        (m) => m.userKey === message.userKey
      );
      if (foundMessage && 'assignTo' in foundMessage && messages) {
        setMessages([
          ...messages.filter((m) => m.userKey !== foundMessage.userKey),
          {
            ...foundMessage,
            assignTo: message.assignToOperatorId ?? undefined,
          },
        ]);
      }

      switch (conversationTab) {
        case ConversationTab.Unassigned:
          if (message.isAssign && message.userKey) {
            removeMessage(message.userKey);
          }
          break;
        case ConversationTab.AssignedToMe:
          if (
            (message.isAssign === false && message.userKey) ??
            (message.isAssign &&
              message.assignToOperatorId !== userAuthenticated?.uid &&
              message.userKey)
          ) {
            removeMessage(message.userKey);
          }
          break;
        case ConversationTab.AssignedTo:
          if (
            (message.isAssign === false && message.userKey) ??
            (message.isAssign &&
              message.assignToOperatorId !== operatorId &&
              message.userKey)
          ) {
            removeMessage(message.userKey);
          }
          break;
      }
    }
    if (message.isFixed !== null && message.isFixed !== undefined) {
      if (selected && selected?.user?.userKey === message.userKey) {
        setSelected((prev) =>
          prev
            ? {
                ...prev,
                status: {
                  ...prev.status,
                  isFixed: message.isFixed,
                },
              }
            : undefined
        );
      }
      switch (conversationTab) {
        case ConversationTab.Unhandled:
        case ConversationTab.AssignedToMe:
        case ConversationTab.AssignedTo:
        case ConversationTab.ClaimAuto:
        case ConversationTab.Unassigned:
        case ConversationTab.PreSales:
        case ConversationTab.Delivery:
        case ConversationTab.Order:
        case ConversationTab.PostOperation:
        case ConversationTab.Usa:
          if (message.isFixed && message.userKey) {
            removeMessage(message.userKey);
          }
          break;
        case ConversationTab.Read:
          if (!message.isFixed) {
            if (message.userKey) {
              removeMessage(message.userKey);
            }
          } else {
            if (message.userKey !== selected?.user?.userKey) {
              resetMessagesAndEmails();
              inboxV2({
                variables: {
                  filter: getFilters(conversationTab),
                  offset: 0,
                  limit: INBOX_LIMIT,
                  sort: sort,
                },
              });
            }
          }
          break;
      }
    }

    if (message.isPinned !== null && message.isPinned !== undefined) {
      if (selected && selected?.user?.userKey === message.userKey) {
        setSelected((prev) =>
          prev
            ? {
                ...prev,
                status: {
                  ...prev.status,
                  isPinned: message.isPinned,
                },
              }
            : undefined
        );
      }
      switch (conversationTab) {
        case ConversationTab.AssignedToMe:
        case ConversationTab.AssignedTo:
        case ConversationTab.Unassigned:
          if (message.isPinned) {
            if (message.userKey) {
              removeMessage(message.userKey);
            }
          }
          break;
        case ConversationTab.Pinned:
          if (!message.isPinned) {
            if (message.userKey) {
              removeMessage(message.userKey);
            }
          } else {
            if (message.userKey !== selected?.user?.userKey) {
              resetMessagesAndEmails();
              inboxV2({
                variables: {
                  filter: getFilters(conversationTab),
                  offset: 0,
                  limit: INBOX_LIMIT,
                  sort: sort,
                },
              });
            }
          }
          break;
      }
    }

    if (message.category !== null && message.category !== undefined) {
      switch (conversationTab) {
        case ConversationTab.PreSales:
        case ConversationTab.Delivery:
        case ConversationTab.Order:
        case ConversationTab.PostOperation:
          if (message.category) {
            if (message.userKey) {
              removeMessage(message.userKey);
            }
          }
          break;
      }
    }
  };

  const selectEmail = (email: string) => {
    setIsProcessing(true);

    getConversationEmail({
      variables: {
        email,
      },
    });
    customerOrders({ variables: { customerId: email } });
  };

  const selectMessage = (userKey: string, fromClick?: boolean) => {
    setInactive(true);

    if (userKey) {
      let path = `/${getPathnameFromConversationTab(
        conversationTab,
        conversationTab === ConversationTab.AssignedToMe
          ? userAuthenticated?.uid
          : operatorId
      )}/${userKey}`;
      if (
        location.pathname.indexOf(userKey) === -1 &&
        path !== location.pathname
      ) {
        if (conversationTab === ConversationTab.Search) {
          path = `${path}?q=${searchTerm}`;
        }
        navigate(path, { replace: true });
      }

      setIsProcessing(true);
      loadConversation({ variables: { key: userKey } });
      customerOrders({ variables: { customerId: userKey } });
    }
    if (fromClick) {
      setDisableContent(false);
    }
  };

  const changeOperationStatus = async (
    message: string,
    isCancelled: boolean,
    userKey?: string
  ) => {
    const userKeyToReport = userKey ? userKey : selected?.user?.userKey;

    if (userKeyToReport || isCancelled) {
      await operatorReplyingMutation({
        variables: {
          data: {
            userKey: userKeyToReport,
            status: conversationStatus,
            operator: userAuthenticated?.displayName ?? '',
            operatorId: userAuthenticated?.uid ?? '',
            message,
            isCancelled,
          },
        },
      });
    }
  };

  const setIsActive = (
    message: string,
    isCancelled: boolean,
    userKey?: string
  ) => {
    changeOperationStatus(message, isCancelled, userKey);
  };

  const setInactive = (_isCancelled: boolean) => {
    debounce2((isCancelled: boolean) => {
      setIsActive('', isCancelled);
    }, 300)(_isCancelled);
  };

  const onChangeMessage = (message: string, userKey?: string) => {
    debounce2((_message: string, userKey?: string) => {
      setIsActive(_message, false, userKey);
    }, 300)(message, userKey);
  };

  const onUnselect = () => {
    resetAllSelected();
    setDisableContent(true);
  };

  const getNextConversation = (
    userKey: string | undefined,
    email: string | undefined
  ) => {
    let currentMessageIndex = selectedIndex ? selectedIndex - 1 : -1;
    if (currentMessageIndex === -1) {
      if (userKey)
        currentMessageIndex = conversations?.findIndex(
          (m) => (m as Message)?.userKey === userKey
        );
      if (email)
        currentMessageIndex = conversations?.findIndex(
          (m) => (m as Email).email === email
        );
    }

    return conversations.length > currentMessageIndex
      ? conversations[currentMessageIndex + 1]
      : undefined;
  };

  const goToNextConversation = (
    userKey: string | undefined,
    email: string | undefined
  ) => {
    const nextConversation = getNextConversation(userKey, email);

    if (nextConversation) {
      if ('userKey' in nextConversation && nextConversation.userKey) {
        selectMessage(nextConversation.userKey);
      } else if ('email' in nextConversation && nextConversation.email) {
        selectEmail(nextConversation.email || '');
      }
    } else {
      resetAllSelected();
      navigate(
        `/${getPathnameFromConversationTab(
          conversationTab,
          conversationTab === ConversationTab.AssignedToMe
            ? userAuthenticated?.uid
            : operatorId
        )}/`,
        { replace: true }
      );
    }
  };

  const onFixConversation = (isFixed: boolean) => {
    if (
      ((conversationTab === ConversationTab.Unhandled ||
        conversationTab === ConversationTab.AssignedToMe ||
        conversationTab === ConversationTab.AssignedTo ||
        conversationTab === ConversationTab.Unassigned ||
        conversationTab === ConversationTab.Usa) &&
        isFixed) ||
      (conversationStatus === MessageStatus.Read && !isFixed)
    ) {
      goToNextConversation(
        selected?.user?.userKey,
        firstEmailFromSelectedEmail?.email
      );
    }
  };

  const onPinConversation = (isPinned: boolean) => {
    if (
      ((conversationTab === ConversationTab.Unhandled ||
        conversationTab === ConversationTab.AssignedToMe ||
        conversationTab === ConversationTab.AssignedTo ||
        conversationTab === ConversationTab.Unassigned ||
        conversationTab === ConversationTab.Usa) &&
        isPinned) ||
      (conversationStatus === MessageStatus.Pinned && !isPinned)
    ) {
      goToNextConversation(
        selected?.user?.userKey,
        firstEmailFromSelectedEmail?.email
      );
    }
  };

  const onAssignConversation = (
    isAssigned: boolean,
    assignedOperatorId: string
  ) => {
    if (
      (conversationTab === ConversationTab.AssignedToMe && !isAssigned) ||
      (conversationTab === ConversationTab.AssignedToMe &&
        isAssigned &&
        assignedOperatorId !== userAuthenticated?.uid) ||
      (conversationTab === ConversationTab.AssignedTo &&
        isAssigned &&
        assignedOperatorId !== operatorId) ||
      (conversationTab === ConversationTab.Unassigned && isAssigned)
    ) {
      goToNextConversation(
        selected?.user?.userKey,
        firstEmailFromSelectedEmail?.email
      );
    }
  };

  const onSetConversationCategory = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isAssigned: boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    assignedCategory: ConversationCategory
  ) => {
    if (
      conversationTab === ConversationTab.ClaimAuto ||
      conversationTab === ConversationTab.Delivery ||
      conversationTab === ConversationTab.Order ||
      conversationTab === ConversationTab.PreSales ||
      conversationTab === ConversationTab.PostOperation
    ) {
      goToNextConversation(
        selected?.user?.userKey,
        firstEmailFromSelectedEmail?.email
      );
    }
  };

  const onMessageSubmit = (
    message: string,
    email?: { email: string; subject: string },
    status?: MessageStatus
  ) => {
    if (
      status === MessageStatus.Read ||
      conversationTab === ConversationTab.Search
    ) {
      if (selected) {
        loadConversation({
          variables: { key: selected?.user?.userKey ?? '' },
        });
        customerOrders({
          variables: { customerId: selected?.user?.userKey ?? '' },
        });
      } else if (selectedEmail) {
        getConversationEmail({
          variables: {
            email: firstEmailFromSelectedEmail?.email ?? '',
          },
        });
        customerOrders({
          variables: { customerId: firstEmailFromSelectedEmail?.email ?? '' },
        });
      }
    } else {
      goToNextConversation(
        selected?.user?.userKey,
        firstEmailFromSelectedEmail?.email
      );
    }
  };

  return (
    <div className="messages-screen flex w-full min-w-0 flex-initial flex-row">
      <section className="side flex h-screen w-screen shrink-0 flex-col bg-white md:w-72">
        <header>
          <div className="hidden md:flex">
            <label htmlFor="search-conversations" className="sr-only">
              {t('list.search')}
            </label>
            <div className="relative w-full text-gray-500 transition-colors focus-within:text-gray-900">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4">
                <Icon icon="search" />
              </div>
              <input
                id="search-conversations"
                type="search"
                value={searchTerm}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.target.value);
                  setDebouncedSearchTerm(e.target.value);
                }}
                placeholder={t('list.search')}
                autoFocus={conversationTab === ConversationTab.Search}
                className="form-input block w-full border-none p-4 pl-9 text-sm leading-5.5 text-gray-900 outline-none transition duration-300 placeholder:text-gray-500 focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-300 disabled:placeholder:text-gray-300"
              />
            </div>
          </div>
          <div className="row flex items-center justify-between border-y border-gray-100 p-4 md:pt-6">
            <button
              onClick={() => setMobileMenuOpen(true)}
              type="button"
              title={t('menu') || ''}
              className="flex rounded-lg focus-visible:ring-2 focus-visible:ring-gray-900/30 md:hidden"
            >
              <Icon icon="menu" size="large" />
              <span className="sr-only">{t('menu')}</span>
            </button>
            <ConversationListHeader
              conversationTab={conversationTab}
              totalHit={totalHit}
              selectedOperator={selectedOperator}
            />
          </div>
        </header>
        <ConversationList
          isLoading={displayLoader}
          search={searchTerm}
          hasMoreMessages={hasMoreMessages}
          conversationTab={conversationTab}
          selectEmail={selectEmail}
          selectMessage={selectMessage}
          onScrollToEnd={fetchNextMessages}
        />
      </section>
      <section
        className={`flex w-full min-w-0 flex-initial ${
          id && !disableContent ? 'content content-active' : 'content'
        }`}
      >
        {(selected ?? selectedEmail) ? (
          <Conversation
            conversationStatus={conversationStatus}
            onChangeMessage={onChangeMessage}
            onUnselect={onUnselect}
            onFixConversation={onFixConversation}
            onPinConversation={onPinConversation}
            onAssignConversation={onAssignConversation}
            onSetConversationCategory={onSetConversationCategory}
            onSubmit={onMessageSubmit}
          />
        ) : null}
      </section>
      {selected?.user?.userKey && <UserSidebar />}
    </div>
  );
};

MessagesScreen.displayName = 'MessagesScreen';
