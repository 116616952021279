type HistoryProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const History: React.FC<HistoryProps> = ({ ...props }: HistoryProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1748_2877)">
        <path
          d="M10.1762 2.40857C8.81273 1.87793 7.30237 1.86374 5.9292 2.3687C4.95022 2.7287 4.08907 3.33487 3.42354 4.11979H4.30098C4.69293 4.11979 5.01066 4.43753 5.01066 4.82947C5.01066 5.22142 4.69293 5.53915 4.30098 5.53915L2.18304 5.53915C1.99458 5.53915 1.81385 5.46419 1.68072 5.33079C1.54759 5.19739 1.47299 5.01652 1.47337 4.82805L1.4776 2.71434C1.47838 2.3224 1.79675 2.0053 2.18869 2.00608C2.58829 2.00688 2.8977 2.34078 2.89691 2.73349C3.62631 2.02675 4.50049 1.47345 5.46903 1.11729C7.14734 0.500127 8.99333 0.51746 10.6598 1.16603C12.3262 1.8146 13.6982 3.04971 14.5178 4.63904C15.3373 6.22836 15.5479 8.06238 15.11 9.79611C14.672 11.5298 13.6157 13.0438 12.1396 14.0532C10.6636 15.0627 8.86956 15.498 7.09504 15.2773C5.32051 15.0566 3.68776 14.1952 2.5039 12.855C1.32004 11.5148 0.666667 9.78822 0.666667 8.00002C0.666667 7.63183 0.965143 7.33335 1.33333 7.33335C1.70152 7.33335 2 7.63183 2 8.00002C2 9.46309 2.53458 10.8757 3.50319 11.9723C4.4718 13.0688 5.80769 13.7736 7.25958 13.9542C8.71146 14.1347 10.1793 13.7785 11.387 12.9526C12.5946 12.1268 13.4589 10.8881 13.8173 9.46955C14.1756 8.05104 14.0033 6.55048 13.3327 5.25012C12.6622 3.94976 11.5396 2.93922 10.1762 2.40857Z"
          fill="currentColor"
        />
        <path
          d="M8 3.33335C8.36819 3.33335 8.66667 3.63183 8.66667 4.00002V7.588L10.9648 8.73707C11.2941 8.90173 11.4276 9.30218 11.263 9.6315C11.0983 9.96082 10.6978 10.0943 10.3685 9.92964L7.70186 8.59631C7.476 8.48338 7.33333 8.25254 7.33333 8.00002V4.00002C7.33333 3.63183 7.63181 3.33335 8 3.33335Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1748_2877">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
