type WebsiteProps = {
  className?: string;
} & React.ComponentPropsWithoutRef<'svg'>;

export const Website: React.FC<WebsiteProps> = ({ ...props }: WebsiteProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00227 15.3332C12.0514 15.332 15.3334 12.0492 15.3334 7.99984C15.3334 3.94975 12.0502 0.666504 8.00008 0.666504C3.94999 0.666504 0.666748 3.94975 0.666748 7.99984C0.666748 11.9427 4.04374 15.3515 8.00227 15.3332ZM6.48389 2.19305C4.11262 2.81048 2.31209 4.84199 2.0367 7.33317H4.70014C4.84961 5.49104 5.46616 3.72278 6.48389 2.19305ZM9.51627 2.19305C10.534 3.72278 11.1505 5.49104 11.3 7.33317H13.9635C13.6881 4.84199 11.8875 2.81048 9.51627 2.19305ZM9.96169 7.33317C9.79422 5.52439 9.1132 3.80113 8.00008 2.36704C6.88697 3.80113 6.20594 5.52439 6.03848 7.33317H9.96169ZM6.03847 8.6665H9.96169C9.79422 10.4753 9.1132 12.1986 8.00008 13.6326C6.88696 12.1986 6.20594 10.4753 6.03847 8.6665ZM4.70014 8.6665H2.0367C2.31209 11.1577 4.11262 13.1892 6.48388 13.8066C5.46615 12.2769 4.84961 10.5086 4.70014 8.6665ZM9.51628 13.8066C10.534 12.2769 11.1506 10.5086 11.3 8.6665H13.9635C13.6881 11.1577 11.8875 13.1892 9.51628 13.8066Z"
        fill="currentColor"
      />
    </svg>
  );
};
