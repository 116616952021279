import React, { Fragment } from 'react';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';

import { useUser } from '../../stores/usercontext';
import { OperatorUser } from '../../types/generated';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { OperatorAvatar } from '../operator-avatar/operator-avatar';

type OperatorSelectProps = {
  assignedUser: OperatorUser | undefined;
  assignUser: (user: OperatorUser) => void;
  users: OperatorUser[];
};

export const OperatorSelect = ({
  assignUser,
  assignedUser,
  users,
}: OperatorSelectProps) => {
  const { user: userAuthenticated } = useUser();

  const extractFirstName = (name: string) => {
    return name.split(' ')?.[0];
  };

  const authenticatedUser = users.find((user) => {
    return user.operatorId === userAuthenticated?.uid;
  });

  const filteredUsers = users.filter(
    (user) => user.operatorId !== authenticatedUser?.operatorId
  );

  return (
    <DropdownMenu>
      {assignedUser ? (
        <DropdownMenuTrigger className="flex w-full cursor-pointer items-center justify-center space-x-1 rounded border border-gray-900 bg-transparent px-2 py-1.75">
          <OperatorAvatar
            operatorId={assignedUser.operatorId}
            size="small"
            showBorder={false}
          />
          <p className="text-xs font-semibold text-gray-700">
            {extractFirstName(assignedUser.displayName ?? '')}
          </p>
          <Icon icon="arrowDown" />
        </DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger asChild>
          <Button type="button" appearance="outline" size="small" containsIcon>
            <Icon icon="profileAdd" />
          </Button>
        </DropdownMenuTrigger>
      )}

      <DropdownMenuContent align="end" className="p-0">
        {authenticatedUser ? (
          <>
            <DropdownMenuGroup className="p-1">
              <DropdownMenuItem
                onSelect={() => assignUser(authenticatedUser)}
                className="space-x-2"
              >
                <OperatorAvatar
                  operatorId={authenticatedUser.operatorId}
                  size="medium"
                  showBorder={false}
                />
                <p className="flex flex-nowrap whitespace-nowrap text-sm font-normal text-gray-900">
                  {extractFirstName(users[0].displayName ?? '')}

                  <div className="ml-1 text-gray-300">(moi)</div>
                </p>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
          </>
        ) : null}

        <DropdownMenuGroup className="p-1">
          {filteredUsers.map((user) => (
            <DropdownMenuItem
              key={user.operatorId}
              onSelect={() => assignUser(user)}
              className="space-x-2"
            >
              <OperatorAvatar
                operatorId={user.operatorId}
                size="medium"
                showBorder={false}
              />
              <p className="flex flex-nowrap whitespace-nowrap text-sm font-normal text-gray-900">
                {extractFirstName(user.displayName ?? '')}
              </p>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
