export const OrderUnfulfilled: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="2.5"
        width="9"
        height="9"
        rx="1.5"
        stroke="currentColor"
      />
    </svg>
  );
};
