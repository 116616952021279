import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import { compareDesc } from 'date-fns';

import { selectedState } from '../../stores/selected';
import {
  CreditHistory,
  useGetCreditHistoryLazyQuery,
} from '../../types/generated';
import { AddCreditModal } from '../add-credit-modal/add-credit-modal';
import { CreditDisplay } from '../credit-display/credit-display';
import { CreditHistoryModal } from '../credit-history-modal/credit-history-modal';

export const UserSidebarCredit: React.FC = () => {
  const { t } = useTranslation();
  const [selected] = useRecoilState(selectedState);
  const [credits, setCredits] = useState<CreditHistory[]>([]);
  const [displayModalCredit, setDisplayModalCredit] = useState(false);

  const [
    creditHistory,
    { data: creditHistoryData, error: creditHistoryError },
  ] = useGetCreditHistoryLazyQuery();

  useEffect(() => {
    if (creditHistoryData?.getCreditHistory) {
      setCredits(
        creditHistoryData?.getCreditHistory.filter(
          (creditHistory): creditHistory is CreditHistory => !!creditHistory
        )
      );
    }
  }, [creditHistoryData]);

  useEffect(() => {
    if (creditHistoryError) {
      toast.error(t('sidebar.credit.get_credit_history_error'));
    }
  }, [creditHistoryError]);

  if (!selected) return null;

  const getHistoryCredit = () => {
    setCredits([]);

    creditHistory({
      variables: {
        userKey: selected.user?.userKey || '',
      },
    });
  };

  return (
    <div className="mb-4 flex items-end justify-between">
      <div>
        <h4 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
          {t('sidebar.credit.credit')}
        </h4>
        <div className="mt-1 flex items-center text-sm text-gray-700">
          <CreditDisplay user={selected?.user} />
        </div>
      </div>
      <div className="flex items-center justify-end">
        <Modal>
          <ModalTrigger
            type="button"
            className="mr-1 border-r border-gray-300 pr-1"
            onClick={getHistoryCredit}
          >
            <Icon
              size="small"
              icon="clock"
              className="cursor-pointer text-green-900"
            />
          </ModalTrigger>
          <ModalContent scrollable>
            <CreditHistoryModal
              credits={credits.toSorted((a, b) =>
                compareDesc(
                  new Date(a.createdAt ?? ''),
                  new Date(b.createdAt ?? '')
                )
              )}
            />
          </ModalContent>
        </Modal>
        <Modal open={displayModalCredit} onOpenChange={setDisplayModalCredit}>
          <ModalTrigger
            type="button"
            className="cursor-pointer text-xs font-semibold text-green-900"
          >
            {t('sidebar.credit.add_credit')}
          </ModalTrigger>
          <ModalContent scrollable>
            <AddCreditModal
              setIsOpen={setDisplayModalCredit}
              user={selected.user}
            />
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

UserSidebarCredit.displayName = 'UserSidebarCredit';
