import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { config } from '../../app/config';
import { MessageStatus } from '../../types/generated';

export const useConversationStatus = (): MessageStatus => {
  const location = useLocation();

  const conversationStatus = useMemo<MessageStatus>(() => {
    const conversationStatusFromUrl = location.pathname.split('/')[1];
    if (conversationStatusFromUrl === config.pathname.pinned) {
      return MessageStatus.Pinned;
    } else if (conversationStatusFromUrl === config.pathname.archives) {
      return MessageStatus.Read;
    } else if (conversationStatusFromUrl === config.pathname.search) {
      return MessageStatus.Search;
    }
    return MessageStatus.Unhandled;
  }, [location.pathname]);

  return conversationStatus;
};
