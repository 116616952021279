export const KlarnaxStripe: React.FC = () => {
  return (
    <svg
      width="79"
      height="28"
      viewBox="0 0 79 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="78" height="27" rx="3.5" fill="white" />
      <g clipPath="url(#clip0_439_3076)">
        <path
          d="M19.0402 7H15.9516C15.9567 8.23757 15.6706 9.45898 15.1162 10.5654C14.5618 11.6719 13.7548 12.6324 12.7604 13.3691L11.5381 14.2839L16.2696 20.7424H20.1679L15.8096 14.7991C16.8385 13.7784 17.654 12.5632 18.2087 11.2242C18.7633 9.88523 19.046 8.44928 19.0402 7Z"
          fill="black"
        />
        <path d="M8 20.7424H11.1622V7H8V20.7424Z" fill="black" />
        <path
          d="M21.0879 20.7372H24.0661V7.00269H21.0879V20.7372Z"
          fill="black"
        />
        <path
          d="M50.1866 10.9823C49.0511 10.9823 47.976 11.3345 47.2584 12.2966V11.2373H44.4247V20.7371H47.2925V15.7427C47.2925 14.297 48.2599 13.5899 49.427 13.5899C50.6782 13.5899 51.3958 14.3364 51.3958 15.7217V20.7371H54.2373V14.6913C54.2373 12.4806 52.4788 10.9771 50.1866 10.9771V10.9823Z"
          fill="black"
        />
        <path
          d="M30.4773 18.4319C29.8116 18.4488 29.1665 18.2009 28.6835 17.7425C28.2005 17.2842 27.9191 16.6529 27.9012 15.9873C27.9377 15.3283 28.2251 14.7084 28.7045 14.2549C29.1838 13.8013 29.8187 13.5486 30.4786 13.5486C31.1385 13.5486 31.7734 13.8013 32.2527 14.2549C32.732 14.7084 33.0195 15.3283 33.0559 15.9873C33.0381 16.6533 32.7564 17.2851 32.2728 17.7435C31.7892 18.2019 31.1434 18.4495 30.4773 18.4319ZM33.0638 11.2373V11.8446C32.4152 11.4027 31.6719 11.1192 30.8937 11.0171C30.1155 10.915 29.3242 10.997 28.5836 11.2566C27.8429 11.5162 27.1735 11.946 26.6293 12.5116C26.0851 13.0771 25.6813 13.7626 25.4505 14.5127C25.2196 15.2628 25.1681 16.0567 25.3001 16.8303C25.4321 17.604 25.7439 18.3359 26.2104 18.967C26.677 19.5981 27.2852 20.1109 27.9861 20.464C28.6871 20.8171 29.4611 21.0007 30.246 21C31.2503 21.0059 32.2328 20.7071 33.0638 20.1431V20.7503H35.9106V11.2373H33.0638Z"
          fill="black"
        />
        <path
          d="M40.3109 12.4754V11.2373H37.3984V20.7371H40.3162V16.3026C40.3162 14.8043 41.938 14 43.0657 14H43.0973V11.2373C41.9433 11.2373 40.8813 11.7315 40.3109 12.4754Z"
          fill="black"
        />
        <path
          d="M60.4882 18.4319C59.8225 18.4488 59.1773 18.2009 58.6943 17.7425C58.2114 17.2842 57.93 16.6529 57.9121 15.9873C57.9486 15.3283 58.236 14.7084 58.7154 14.2549C59.1947 13.8013 59.8296 13.5486 60.4895 13.5486C61.1494 13.5486 61.7842 13.8013 62.2636 14.2549C62.7429 14.7084 63.0304 15.3283 63.0668 15.9873C63.0489 16.6533 62.7672 17.2851 62.2837 17.7435C61.8001 18.2019 61.1543 18.4495 60.4882 18.4319ZM63.0773 11.2374V11.8446C62.324 11.3313 61.4449 11.0332 60.5348 10.9823C59.6246 10.9314 58.7178 11.1297 57.9119 11.5558C57.106 11.9819 56.4316 12.6197 55.9612 13.4005C55.4908 14.1813 55.2422 15.0757 55.2422 15.9873C55.2422 16.8988 55.4908 17.7932 55.9612 18.574C56.4316 19.3549 57.106 19.9926 57.9119 20.4187C58.7178 20.8448 59.6246 21.0431 60.5348 20.9922C61.4449 20.9413 62.324 20.6432 63.0773 20.13V20.7372H65.9215V11.2374H63.0773Z"
          fill="black"
        />
        <path
          d="M68.9418 17.3673C68.5881 17.3668 68.2423 17.4712 67.948 17.6673C67.6537 17.8634 67.4242 18.1423 67.2885 18.4689C67.1528 18.7955 67.117 19.155 67.1857 19.5019C67.2544 19.8488 67.4244 20.1675 67.6743 20.4178C67.9242 20.668 68.2427 20.8385 68.5895 20.9077C68.9363 20.9769 69.2958 20.9416 69.6226 20.8064C69.9493 20.6712 70.2287 20.4421 70.4252 20.1481C70.6217 19.8541 70.7266 19.5084 70.7266 19.1548C70.727 18.9202 70.681 18.6878 70.5915 18.4709C70.502 18.2541 70.3705 18.057 70.2048 17.891C70.039 17.725 69.8421 17.5933 69.6254 17.5034C69.4087 17.4136 69.1764 17.3673 68.9418 17.3673Z"
          fill="black"
        />
      </g>
      <rect x="0.5" y="0.5" width="78" height="27" rx="3.5" stroke="#FFB3C7" />
      <defs>
        <clipPath id="clip0_439_3076">
          <rect
            width="62.7266"
            height="14"
            fill="white"
            transform="translate(8 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
