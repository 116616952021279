import Icon from '@appchoose/icon';

import pdf from '../../assets/PDF.svg';

export type FilePreviewProps = {
  file: File;
  index: number;
  canRemove: boolean;
  onRemoveFile?: (index: number) => void;
  sizeClassName: string;
};

export const FilePreview: React.FC<FilePreviewProps> = ({
  file,
  index,
  canRemove,
  onRemoveFile,
  sizeClassName,
}: FilePreviewProps) => {
  return (
    <div className="group relative flex items-center overflow-hidden rounded-md">
      <a
        onClick={() => {
          if (onRemoveFile) onRemoveFile(index);
        }}
        className={`absolute inset-0 hidden bg-gray-900/40 ${
          canRemove ? 'cursor-pointer group-hover:block' : ''
        }`}
      >
        <Icon icon="closeCircle" className="float-right m-1 text-white" />
      </a>
      {file.type !== 'application/pdf' ? (
        <img
          src={URL.createObjectURL(file)}
          className={`object-contain ${sizeClassName ? 'h-16' : ''}`}
        />
      ) : (
        <div className="flex items-center space-x-1 bg-gray-50 py-3 pl-2 pr-8 font-bold text-gray-600">
          <img src={pdf} alt="fichier pdf" />
          <div className="text-sm">{file.name}</div>
        </div>
      )}
    </div>
  );
};
