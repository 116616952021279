import parsePhoneNumber from 'libphonenumber-js/mobile';

export const formatPhone = (phone?: string) => {
  if (phone != null) {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatInternational();
    }
    return phone;
    // if (phone && phone.length >= 2) {
    //   phone = phone.replace('+33 ', '0').replace('+33', '0').replace(/\s/g, '');
    //   phone = phone.slice(0, 10);
    //   const suffix = phone.slice(0, 2).replace('0', '+33 ');
    //   if (phone.length > 2) {
    //     const str = (phone.slice(2, phone.length) as any)
    //       .match(/.{1,2}/g)
    //       .join(' ');
    //     return suffix + ' ' + str;
    //   }
    //   return phone;
    // }
    // return phone;
  }
  return '';
};
