import { isAfter, isBefore } from 'date-fns';

export enum SaleStatus {
  Ended,
  InProgress,
  Upcoming,
  Cancelled,
}

export type SaleSearch = {
  id: string;
  highlighted_name: string;
  name: string;
  start_at: string;
  end_at: string | null;
  sale_status: SaleStatus;
  store_id?: string;
};

export const getSaleStatus = (
  saleStartAt: string,
  saleEndAt: string | null,
  isCancelled: boolean
) => {
  const startAt = new Date(saleStartAt);
  const endAt = new Date(saleEndAt ?? '');
  if (isCancelled) {
    return SaleStatus.Cancelled;
  }
  if (
    isBefore(startAt, new Date()) &&
    isAfter(new Date(endAt ?? ''), new Date())
  ) {
    return SaleStatus.InProgress;
  }
  if (isBefore(endAt, new Date())) {
    return SaleStatus.Ended;
  }
  return SaleStatus.Upcoming;
};
