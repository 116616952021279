import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import AddressView from '@appchoose/address-view';
import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import Tooltip from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';
import { TFunction } from 'i18next';

import { track } from '../../api/analytics';
import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import { exactHighlightedOrderIdState } from '../../stores/searchResults';
import {
  selectedOrderIdActiveState,
  selectedState,
} from '../../stores/selected';
import { contacts, findContactOrEmpty } from '../../types/choose-contact';
import {
  OperationType,
  OrderQuery,
  OrderTagFulfillment,
  TrackingStatus,
  TrackingSubStatus,
} from '../../types/generated';
import { Item, Order } from '../../types/order';
import { formatPrice } from '../../utils/currency';
import { formatRange, transformOrderDate } from '../../utils/date';
import { formatPhone } from '../../utils/phone';
import { BRAND_BASE_URL } from '../../utils/url';
import { CreateReturnSlipModal } from '../create-return-slip-modal/create-return-slip-modal';
import { Choose } from '../icons/choose';
import { Hubspot } from '../icons/hubspot';
import { Pin } from '../icons/pin';
import { RefundChoose } from '../icons/refund-choose';
import { Website } from '../icons/website';
import { OpenComplaint } from '../open-complaint/open-complaint';
import { ModalOrderClaimBadge } from '../order-status-badge/modal-order-claim-badge';
import { ModalOrderFulfillmentBadge } from '../order-status-badge/modal-order-fulfillment-badge';
import { ModalOrderReshipBadge } from '../order-status-badge/modal-order-reship-badge';
import { ModalOrderShipmentBadge } from '../order-status-badge/modal-order-shipment-badge';
import { getOrderReturnLabelFromTag } from '../order-status-badge/order-return-badge';
import { OtherActionsModal } from '../other-actions-modal/other-actions-modal';
import { PickupPointModal } from '../pickup-point-modal/pickup-point-modal';
import { Alma } from './alma';
import { CashbackInfo } from './cashback-info';
import { OrderClaimDetails } from './order-claim-details';
import { OrderInvoice } from './order-invoice';
import { OrderOperationTypeBadge } from './order-operation-type-badge';
import { OrderProductDetail } from './order-product-detail';
import { OrderReturn } from './order-return';
import { PaymentProviderLink } from './payment-provider-link';

import '@appchoose/tooltip/dist/style.css';

type UserSidebarOrderProps = {
  order: Order;
};

export type ItemsGroupedByVariants = {
  item: Item;
  quantity: number;
  totallyRefundedQuantity: number;
  returnStatus?: string;
};

export const getTrackingStatusTitle = (
  trackingSubStatus: OrderQuery['order']['parcels'][0]['trackingSubStatus'],
  t: TFunction
) => {
  if (!trackingSubStatus) return null;

  switch (trackingSubStatus) {
    case TrackingSubStatus.ExceptionShippingException:
      return t('shipment.errors.exception_shipping_exception_title');
    case TrackingSubStatus.ExceptionRecipientRelocated:
      return t('shipment.errors.exception_recipient_relocated_title');
    case TrackingSubStatus.ExceptionRecipientRefused:
      return t('shipment.errors.exception_recipient_refused_title');
    case TrackingSubStatus.ExceptionDelayedCustomsClearance:
      return t('shipment.errors.exception_delayed_customs_clearance_title');
    case TrackingSubStatus.ExceptionUnforseenReason:
      return t('shipment.errors.exception_unforeseen_reason_title');
    case TrackingSubStatus.ExceptionIncorrectRecipientAddress:
      return t('shipment.errors.exception_incorrect_recipient_address_title');
    case TrackingSubStatus.ExceptionPendingPayment:
      return t('shipment.errors.exception_pending_payment_title');
    case TrackingSubStatus.ExceptionPickupNotCollectedByRecipient:
      return t(
        'shipment.errors.exception_pickup_not_collected_by_recipient_title'
      );
    case TrackingSubStatus.ExceptionRejectedByCarrier:
      return t('shipment.errors.exception_rejected_by_carrier_title');
    case TrackingSubStatus.ExceptionBackToSender:
      return t('shipment.errors.exception_back_to_sender_title');
    case TrackingSubStatus.ExceptionBackToSenderReceived:
      return t('shipment.errors.exception_back_to_sender_received_title');
    case TrackingSubStatus.ExceptionDamaged:
      return t('shipment.errors.exception_damaged_title');
    case TrackingSubStatus.ExceptionLost:
      return t('shipment.errors.exception_lost_title');
    case TrackingSubStatus.ExpiredNoTrackingInformation:
      return t('shipment.errors.expired_no_tracking_information_title');
  }

  return null;
};

export const UserSidebarOrder: React.FC<UserSidebarOrderProps> = ({
  order,
}: UserSidebarOrderProps) => {
  const { i18n, t } = useTranslation();

  const selected = useRecoilValue(selectedState);
  const selectedOrderIdActive = useRecoilValue(selectedOrderIdActiveState);

  const returnedItems =
    order.returnParcels?.[0]?.items ?? order.returnSlips?.[0]?.items ?? [];
  const itemsGrouped =
    order.items?.reduce<ItemsGroupedByVariants[]>((acc, val) => {
      let itemGrouped = acc.find(
        (itemGrouped) =>
          itemGrouped.item.productVariantId === val.productVariantId &&
          itemGrouped.item.refundSummary.refundedAmountInPercentage === 0 &&
          !itemGrouped.item.isCancelled &&
          !itemGrouped.item.isReturnGenerated
      );

      if (!itemGrouped) {
        itemGrouped = {
          item: val,
          quantity: 0,
          totallyRefundedQuantity: 0,
          returnStatus: returnedItems.find((item) => item.id === val.id)
            ? getOrderReturnLabelFromTag(order.tags.return[0], t)
            : undefined,
        };
        acc.push(itemGrouped);
      }

      itemGrouped.quantity++;
      if (val.refundSummary.isTotallyRefunded)
        itemGrouped.totallyRefundedQuantity++;

      return acc;
    }, []) ?? [];
  const [displayOtherActionsModal, setDisplayOtherActionsModal] =
    useState(false);
  const [displayReturnSlipModal, setDisplayReturnSlipModal] = useState(false);
  const [displayBrandInfos, setDisplayBrandInfos] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);

  const exactHighlightedOrderId = useRecoilValue(exactHighlightedOrderIdState);

  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = clipboardState;
    if (value) {
      toast.success(t('clipboard.copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [clipboardState, t]);

  const firstEstimatedDeliveryDate =
    order.shipping.deliveryDateRangeHistory.length > 0
      ? order.shipping.deliveryDateRangeHistory[
          order.shipping.deliveryDateRangeHistory.length - 1
        ]
      : null;

  const lastEstimatedDeliveryDate =
    order.shipping.deliveryDateRangeHistory.length > 1
      ? order.shipping.deliveryDateRangeHistory[0]
      : null;

  const orderBrandUrl = `${BRAND_BASE_URL}/${order.seller?.id ?? ''}/orders/${
    order.id
  }?sid=${order.saleId?.slice(0, 5)}`;

  return (
    <div
      id={order.id}
      className={cn('group relative w-full rounded bg-white p-4', {
        'border-2 border-green-900': order.id === selectedOrderIdActive,
      })}
    >
      <OrderReturn order={order} />
      {order.initialOrderId ? (
        <div className="-m-4 mb-4 rounded-t bg-gray-500 p-4 text-xs text-white">
          {t('sidebar.parcel_return')}
        </div>
      ) : null}
      {!displayBrandInfos ? (
        <div className="relative">
          <h6 className="mb-1 flex items-start justify-between space-x-4">
            <span className="text-sm font-bold text-gray-900">
              {order.seller ? order.seller.name : t('order.order')}
            </span>
            {order.createdAt ? (
              <span className="shrink-0 text-xs font-normal leading-5 text-gray-500 group-hover:invisible">
                {transformOrderDate(
                  new Date(order.createdAt),
                  i18n.language === 'fr' ? 'fr' : 'en'
                )}
              </span>
            ) : null}
            <button
              type="button"
              onClick={() => setDisplayBrandInfos(!displayBrandInfos)}
              className="absolute right-0 top-0 hidden group-hover:flex"
            >
              <Icon className="text-green-900" icon="information" />
            </button>
          </h6>
          {order.operationType === OperationType.PostOperationPurchase ||
          order.operationType === OperationType.FirmPurchase ? (
            <OrderOperationTypeBadge operationType={order.operationType} />
          ) : null}
          <div className="mb-2">
            <a
              href={orderBrandUrl}
              target="_blank"
              rel="noreferrer"
              className={`cursor-pointer text-xs font-normal hover:text-green-900 ${
                exactHighlightedOrderId === order.id
                  ? 'font-semibold text-green-900'
                  : 'text-gray-500'
              }`}
            >
              {order.id}
            </a>
          </div>
          <div className="mb-2 flex flex-wrap items-center gap-2">
            {order.tags.fulfillment === OrderTagFulfillment.Unfulfilled ? (
              <ModalOrderFulfillmentBadge
                isCancelled={order.isCancelled}
                tag={order.tags.fulfillment}
              />
            ) : null}
            <ModalOrderShipmentBadge
              hasTrackingStucked={order.parcels?.[0]?.hasTrackingStucked}
              isCancelled={order.isCancelled}
              tag={order.tags.shipment[0]}
              trackingSubStatus={order.parcels?.[0]?.trackingSubStatus}
            />
            <ModalOrderClaimBadge
              isCancelled={order.isCancelled}
              tag={order.tags.claim[0]}
            />
            <ModalOrderReshipBadge
              tag={order.tags.reship}
              isCancelled={order.isCancelled}
            />
          </div>
          <div className="mb-2">
            <Alma payment={order.payment} />
          </div>
          {order.shipping.pickupPoint ? (
            <Modal>
              <ModalTrigger
                type="button"
                className="mb-1 flex items-center gap-x-0.5 text-xs font-semibold text-green-900"
              >
                <span>{t('order.pickup_point')}</span> <Pin />
              </ModalTrigger>
              <ModalContent>
                <PickupPointModal orderId={order.id} />
              </ModalContent>
            </Modal>
          ) : null}

          <p className="mb-1 text-xs font-normal text-gray-500">
            <span>{t('order.estimate')}</span>{' '}
            {lastEstimatedDeliveryDate && (
              <span>
                {formatRange(
                  new Date(lastEstimatedDeliveryDate.start),
                  new Date(lastEstimatedDeliveryDate.end),
                  i18n.language === 'fr' ? 'fr' : 'en'
                )}
              </span>
            )}{' '}
            {firstEstimatedDeliveryDate && (
              <span className={lastEstimatedDeliveryDate ? 'line-through' : ''}>
                {formatRange(
                  new Date(firstEstimatedDeliveryDate.start),
                  new Date(firstEstimatedDeliveryDate.end),
                  i18n.language === 'fr' ? 'fr' : 'en'
                )}
              </span>
            )}
          </p>
          {order.parcels.length > 0 ? (
            <a
              href={order.parcels[0].trackingUrl || ''}
              target="_blank"
              rel="noreferrer"
              className="mb-0.5 cursor-pointer text-xs text-gray-500"
            >
              {order.parcels[0].trackingCarrierSlug} -{' '}
              <span className="text-green-900">
                #{order.parcels[0].trackingNumber}
              </span>
            </a>
          ) : null}

          {getTrackingStatusTitle(order.parcels?.[0]?.trackingSubStatus, t) ? (
            <div className="text-xs text-gray-500">
              {t('sidebar.status')}{' '}
              {getTrackingStatusTitle(order.parcels?.[0]?.trackingSubStatus, t)}
            </div>
          ) : null}

          {order.shipping.refundSummary.refundedAmountInCents ? (
            <div className="border-l border-gray-100 pl-2 text-xs font-semibold text-orange-600">
              <div
                className="group flex w-full items-center space-x-1"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="truncate">
                  {t('sidebar.shipping_refunded')}
                </span>
                <span>
                  {formatPrice(
                    order.shipping.refundSummary.refundedAmountInCents / 100,
                    i18n.language === 'fr' ? 'fr' : 'en',
                    order.totalPriceSold.currency || 'EUR'
                  )}
                </span>
                {order.shipping.refundSummary
                  .refundedAmountInPercentageCoveredByChoose !== 0 &&
                order.shipping.refundSummary
                  .refundedAmountInPercentageCoveredBySupplier === 0 ? (
                  <Tooltip
                    offset={[0, 16]}
                    content={
                      <div className="px-1.5 py-3 text-xs font-semibold">
                        <p className="text-center">
                          {t('order.refund.payable_by_choose')}
                        </p>
                      </div>
                    }
                  >
                    <div>
                      <RefundChoose className="text-orange-600" />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="my-4 space-y-2">
            {itemsGrouped.map((itemGrouped) => (
              <OrderProductDetail
                key={itemGrouped.item.id}
                item={itemGrouped.item}
                orderBrandUrl={orderBrandUrl}
                quantity={itemGrouped.quantity}
                returnStatus={itemGrouped.returnStatus}
              />
            ))}
          </div>
          <CashbackInfo cashback={order.cashback} />

          <div className="space-y-1">
            {order.claims.length === 0 ? (
              <Modal open={openClaimModal} onOpenChange={setOpenClaimModal}>
                <ModalTrigger
                  type="button"
                  className="flex cursor-pointer items-center text-sm font-semibold text-green-900"
                >
                  <Icon icon="complains" className="mr-1" />
                  {t('order.actions.open_claim')}
                </ModalTrigger>
                <ModalContent scrollable>
                  <OpenComplaint
                    orderId={order.id}
                    setIsOpen={() => setOpenClaimModal(false)}
                  />
                </ModalContent>
              </Modal>
            ) : (
              <OrderClaimDetails claim={order.claims?.[0]} orderId={order.id} />
            )}
            {!order.isCancelled ? (
              <Modal
                open={displayOtherActionsModal}
                onOpenChange={setDisplayOtherActionsModal}
              >
                <ModalTrigger className="flex cursor-pointer items-center text-sm font-semibold text-green-900">
                  <Icon icon="more" className="mr-1" />
                  {t('order.actions.other_actions')}
                </ModalTrigger>
                <ModalContent scrollable>
                  <OtherActionsModal
                    order={order}
                    setIsOpen={setDisplayOtherActionsModal}
                    userKey={selected?.user?.userKey}
                  />
                </ModalContent>
              </Modal>
            ) : null}
            <OrderInvoice
              billingId={order.billingId}
              parcel={order.parcels?.[0]}
            />
            {(order.claims.length > 0 ||
              order.parcels?.[0]?.trackingStatus ===
                TrackingStatus.Delivered) &&
            !order.isFullDigital &&
            order.parcels?.[0]?.trackingNumber &&
            !order.returnParcels?.[0]?.trackingNumber &&
            order.returnSlips.length === 0 ? (
              <Modal
                open={displayReturnSlipModal}
                onOpenChange={setDisplayReturnSlipModal}
              >
                <ModalTrigger className="flex cursor-pointer items-center text-sm font-semibold text-green-900">
                  {t('order.actions.make_return_slip')}
                </ModalTrigger>
                <ModalContent scrollable>
                  <CreateReturnSlipModal
                    setIsOpen={setDisplayReturnSlipModal}
                    order={order}
                    userKey={selected?.user?.userKey}
                  />
                </ModalContent>
              </Modal>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="relative">
          <h6 className="mr-8 flex items-center">
            <span className="text-sm font-bold text-gray-900">
              {order.seller ? order.seller.name : ''}
            </span>
            <button
              type="button"
              onClick={() => setDisplayBrandInfos(!displayBrandInfos)}
              className="absolute right-0 top-0"
            >
              <Icon icon="closeCircle" className="text-green-900" />
            </button>
          </h6>
          <OrderOperationTypeBadge operationType={order.operationType} />

          <div className="space-y-4">
            <div className="space-y-2">
              <div className="space-y-0.5">
                {order.seller?.contactPhone ? (
                  <button
                    type="button"
                    onClick={() => {
                      copyToClipboard(
                        formatPhone(order.seller?.contactPhone ?? '')
                      );
                      track('CopyToClipboard', {
                        label: 'brand_phone',
                      });
                    }}
                    className="text-xs text-gray-700"
                  >
                    {formatPhone(order.seller.contactPhone)}
                  </button>
                ) : null}
                {order.seller ? (
                  <button
                    type="button"
                    onClick={() => {
                      copyToClipboard(order.seller?.contactEmail ?? '');
                      track('CopyToClipboard', {
                        label: 'brand_email',
                      });
                    }}
                    className="text-xs text-green-900"
                  >
                    {order.seller.contactEmail}
                  </button>
                ) : null}
              </div>
              <div className="space-y-0.5 text-xs text-gray-700">
                <dl className="grid grid-cols-5 gap-y-0.5">
                  <dt className="font-semibold uppercase tracking-wider text-gray-500">
                    {t('order.sale')}
                  </dt>
                  <dd className="col-span-4">
                    {
                      findContactOrEmpty(
                        order?.seller?.contactName ?? '',
                        contacts
                      )?.firstName
                    }
                  </dd>
                  <dt className="font-semibold uppercase tracking-wider text-gray-500">
                    {t('order.am')}
                  </dt>
                  <dd className="col-span-4">
                    {
                      findContactOrEmpty(
                        order?.logisticsManager ?? '',
                        contacts
                      )?.firstName
                    }
                  </dd>
                </dl>
              </div>
            </div>
            {order.seller?.returnAddress ? (
              <div>
                <p className="mb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
                  {t('address.return_address')}
                </p>
                <AddressView
                  label={t('address.label')}
                  size="small"
                  address={{
                    ...order.seller.returnAddress,
                    street2:
                      order.seller.returnAddress.streetAdditional ?? undefined,
                    province: order.seller.returnAddress.county ?? undefined,
                    bp: order.seller.returnAddress.zipCode ?? undefined,
                  }}
                  locale={i18n.language === 'fr' ? 'FR' : 'EN'}
                />
              </div>
            ) : null}
            <div>
              <p className="mb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {t('sidebar.payment')}
              </p>
              <p className="text-xs text-gray-700">
                <PaymentProviderLink payment={order.payment} />
              </p>
            </div>
            <div className="space-y-1">
              <p className="mb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {t('sidebar.links')}
              </p>
              {order.seller?.hubspotId ? (
                <a
                  target="_blank"
                  href={`https://app.hubspot.com/contacts/4364491/company/${order.seller.hubspotId}`}
                  rel="noreferrer"
                  className="flex items-center text-sm text-green-900"
                >
                  <Hubspot className="mr-1 size-4" />
                  Hubspot
                </a>
              ) : null}
              {order.saleId ? (
                <a
                  target="_blank"
                  href={`https://meusnidus-v2.appchoose.io/vente/${order.saleId}`}
                  rel="noreferrer"
                  className="flex items-center text-sm text-green-900"
                >
                  <Choose className="mr-1 size-4" />
                  Meusnidus
                </a>
              ) : null}
              {order.seller?.website ? (
                <a
                  target="_blank"
                  href={`https://${order.seller.website}`}
                  rel="noreferrer"
                  className="flex items-center text-sm text-green-900"
                >
                  <Website className="mr-1 size-4" />
                  {t('sidebar.website')}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UserSidebarOrder.displayName = 'UserSidebarOrder';
