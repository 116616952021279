import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Tooltip from '@appchoose/tooltip';
import { TFunction } from 'i18next';

import {
  OrderQuery,
  OrderTagShipment,
  TrackingSubStatus,
} from '../../types/generated';
import { OrderBadge } from './order-badge';
import {
  getOrderShipmentLabelFromTag,
  orderShipmentVariant,
} from './order-shipment-badge';

type ModalOrderShipmentBadgeProps = {
  hasTrackingStucked: OrderQuery['order']['parcels'][number]['hasTrackingStucked'];
  isCancelled?: boolean;
  tag: OrderTagShipment;
  trackingSubStatus: OrderQuery['order']['parcels'][number]['trackingSubStatus'];
};

const getTrackingSubStatus = (
  trackingSubStatus: TrackingSubStatus,
  t: TFunction
) => {
  switch (trackingSubStatus) {
    case TrackingSubStatus.DeliveredMarkedByBot:
      return t('order.status.tracking_sub_status.delivered_marked_by_bot');
    case TrackingSubStatus.DeliveredMarkedByCustomer:
      return t('order.status.tracking_sub_status.delivered_marked_by_customer');
    case TrackingSubStatus.DeliveredMarkedBySupportTeam:
      return t(
        'order.status.tracking_sub_status.delivered_marked_by_support_team'
      );
    default:
      return '';
  }
};

export const ModalOrderShipmentBadge: React.FC<
  ModalOrderShipmentBadgeProps
> = ({ hasTrackingStucked, isCancelled, tag, trackingSubStatus }) => {
  const { t } = useTranslation();

  if (tag === OrderTagShipment.None) return null;

  const variantStyle = orderShipmentVariant(
    { isCancelled, hasTrackingStucked, tag, trackingSubStatus },
    'filled'
  );

  if (
    trackingSubStatus === TrackingSubStatus.DeliveredMarkedByBot ||
    trackingSubStatus === TrackingSubStatus.DeliveredMarkedByCustomer ||
    trackingSubStatus === TrackingSubStatus.DeliveredMarkedBySupportTeam
  ) {
    return (
      <Tooltip
        content={
          <p className="p-4 text-xs font-semibold">
            {getTrackingSubStatus(trackingSubStatus, t)}
          </p>
        }
      >
        <div className="cursor-default">
          <OrderBadge style={variantStyle} intent="bold">
            <div className="flex flex-nowrap items-center gap-x-1">
              {variantStyle === 'warning' ? (
                <Icon icon="alertCircle" className="!size-3.5" />
              ) : (
                <Icon icon="truck" className="!size-3.5" />
              )}
              <span className={isCancelled ? 'line-through' : ''}>
                {getOrderShipmentLabelFromTag(tag, t)}
              </span>
            </div>
          </OrderBadge>
        </div>
      </Tooltip>
    );
  }

  return (
    <OrderBadge style={variantStyle} intent="bold">
      <div className="flex flex-nowrap items-center gap-x-1">
        {variantStyle === 'warning' ? (
          <Icon icon="alertCircle" className="!size-3.5" />
        ) : (
          <Icon icon="truck" className="!size-3.5" />
        )}
        <span className={isCancelled ? 'line-through' : ''}>
          {getOrderShipmentLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
