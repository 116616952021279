import React from 'react';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';

type SendSelectOption = {
  label: React.ReactNode;
  onClick: (e: Event) => void;
};

type SendSelectProps = {
  options: SendSelectOption[];
  disabled?: boolean;
};

export const SendSelect = ({ options, disabled }: SendSelectProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          type="button"
          appearance="outline"
          size="medium"
          containsIcon
          disabled={disabled}
        >
          <Icon icon="arrowDown" size="large" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" sideOffset={8} className="p-0">
        <DropdownMenuGroup className="p-1">
          {options.map((option, index) => (
            <DropdownMenuItem
              key={index}
              onSelect={option.onClick}
              className="text-gray-700"
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
