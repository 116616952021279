import { atom } from 'recoil';

import { Slide } from '../components/image-gallery/image-gallery';

export const imageViewerState = atom<
  | {
      slides: Slide[];
      initialIndex?: number;
    }
  | undefined
>({
  key: 'imageViewerState',
  default: undefined,
});
