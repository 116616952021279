import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Icon from '@appchoose/icon';

import { motivesState } from '../../stores/group-message/motives';
import { selectedSaleState } from '../../stores/group-message/selectedSale';
import { selectedSegmentationState } from '../../stores/group-message/selectedSegmentation';
import { formatPrice } from '../../utils/currency';
import { formatRange } from '../../utils/date';
import { FilesPreview } from '../files-preview/files-preview';
import {
  EstimatedDateReason,
  SaleGroupMessageForm,
} from './sale-group-message-modal';
import { SaleExtraInfos } from './sale-group-message-step-sale';

export const SaleGroupMessageModalStepSummary: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { getValues } = useFormContext<SaleGroupMessageForm>();

  const selectedSale = useRecoilValue(selectedSaleState);
  const selectedSegmentation = useRecoilValue(selectedSegmentationState);
  const motives = useRecoilValue(motivesState);

  return (
    <>
      <h2 className="mb-6 text-2xl font-bold text-gray-900">
        {t('message_group.contact_group_of_customers')}
      </h2>
      <p className="m-0 mb-6 text-sm font-normal text-gray-700">
        {t('message_group.check_message_infos')}
      </p>
      <h3 className="mb-4 text-base font-semibold text-gray-700">
        {t('message_group.message_summary')}
      </h3>
      <div className="resume-info-container">
        <div className="mb-5 flex items-center space-x-2">
          <Icon icon="information" />
          <p className="m-0 text-sm font-normal text-gray-700 first:mb-1">
            {
              motives.find((motive) => motive.id === getValues('motiveId'))
                ?.reason
            }
          </p>
        </div>
        {getValues('motiveId') === 'reason_late_delivery' &&
          getValues('estimatedDateReason') ===
            EstimatedDateReason.PostponeDate && (
            <div className="mb-5 flex items-center space-x-2">
              <Icon icon="calendar" />
              <p className="m-0 text-sm font-normal text-gray-700 first:mb-1">
                {t('message_group.postponed_days')}{' '}
                <span className="font-bold text-gray-900">
                  {t('message_group.added_business_days', {
                    count: getValues('newEstimatedDeliveryPostponeDays'),
                  })}
                </span>
              </p>
            </div>
          )}
        {getValues('motiveId') === 'reason_late_delivery' &&
          getValues('estimatedDateReason') ===
            EstimatedDateReason.ChangeDate && (
            <div className="mb-5 flex items-center space-x-2">
              <Icon icon="calendar" />
              <p className="m-0 text-sm font-normal text-gray-700 first:mb-1">
                {t('message_group.new_estimated_delivery')}{' '}
                <span className="font-bold text-gray-900">
                  {t('message_group.new_estimated_delivery_range', {
                    date: formatRange(
                      getValues('computedEstimatedDeliveryStartDate'),
                      getValues('computedEstimatedDeliveryEndDate'),
                      i18n.language === 'fr' ? 'fr' : 'en'
                    ),
                  })}
                </span>
              </p>
            </div>
          )}
        <div className="mb-5 flex items-center space-x-2">
          <Icon icon="send" />
          <p className="text-sm font-normal text-gray-700">
            {t('message_group.send_to')}{' '}
            <span className="font-bold text-gray-900">
              {selectedSegmentation?.statsBySale?.nbCustomers
                ? t('message_group.customerWithCount', {
                    count: selectedSegmentation?.statsBySale?.nbCustomers,
                  })
                : t('message_group.customerWithCount', {
                    count: getValues('segmentationOrderIds')?.length,
                  })}
            </span>{' '}
            {t('message_group.identified_via')}{' '}
            <span className="font-bold text-gray-900">
              {selectedSegmentation?.label?.toLowerCase()}
            </span>
            .
          </p>
        </div>
        {selectedSale && (
          <div className="mb-5 flex items-center space-x-2">
            <Icon icon="orders" />
            <p className="flex items-center space-x-1 text-sm font-normal text-gray-500">
              <span className="text-gray-700">
                {t('message_group.orders_from_sale')}
              </span>
              <span className="font-bold text-gray-900">
                {selectedSale.name}
              </span>
              <span>•</span>
              <SaleExtraInfos sale={selectedSale} showStatus={false} />
            </p>
          </div>
        )}
        <div className="mb-5 flex items-start space-x-2">
          <Icon icon="write" />
          <div className="flex flex-col">
            <p className="text-sm font-normal text-gray-700">
              {t('message_group.message_sent')}{' '}
              <span className="font-bold text-gray-900">
                {t('message_group.by_chat')}
              </span>
            </p>
            <p className="whitespace-pre-line text-sm font-normal">
              &quot;{getValues('message')}&quot;
            </p>
          </div>
        </div>
        {getValues('images') ? (
          <div className="mb-5 flex items-start space-x-2">
            <Icon icon="paperClip" />
            <div className="flex flex-col space-y-1">
              <p className="text-sm">
                <Trans
                  t={t}
                  i18nKey="message_group.imageWithCount"
                  count={getValues('images')?.length}
                  components={{ b: <b /> }}
                />
              </p>
              <FilesPreview
                files={getValues('images')}
                canRemove={false}
                className=""
                sizeClassName="h-12"
              />
            </div>
          </div>
        ) : null}
        {getValues('creditAmount') ? (
          <div className="mb-5 flex items-center space-x-2">
            <Icon icon="euroCircle" />
            <p className="text-sm font-normal text-gray-700">
              {t('message_group.compensation_of')}{' '}
              <span className="font-bold text-gray-900">
                {formatPrice(
                  getValues('creditAmount'),
                  i18n.language === 'fr' ? 'fr' : 'en',
                  selectedSale?.store_id === 'us' ? 'USD' : 'EUR',
                  0
                )}
              </span>{' '}
              {t('message_group.in_credit')}.
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
};
