export const capitalize = (s?: string) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getFirstName = (fullName: string | null | undefined) => {
  if (!fullName) {
    return '';
  }
  return capitalize(fullName?.split(' ')?.[0]);
};
