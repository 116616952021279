import { useTranslation } from 'react-i18next';

import { ConversationEmail } from '../../types/generated';
import { transformDateMessage, transformHours } from '../../utils/date';

type EmailMessageProps = {
  message: ConversationEmail;
};

export const EmailMessage: React.FC<EmailMessageProps> = ({
  message,
}: EmailMessageProps) => {
  const { i18n } = useTranslation();

  return (
    <li className={`message space-y-2 ${message.fromChoose ? 'choose' : ''}`}>
      {message.createdAt ? (
        <p className="timer mb-4 text-center text-xs text-gray-500">
          {transformDateMessage(
            new Date(message.createdAt),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </p>
      ) : null}
      <p
        className={`body rounded-xl p-4 text-sm ${
          message.fromChoose
            ? 'rounded-br-sm bg-green-900 text-white'
            : 'rounded-tl-sm bg-gray-50 text-gray-700'
        }`}
      >
        {message.body}
      </p>
      {message.createdAt && (
        <p
          className={`timer text-xs text-gray-500 ${
            message.fromChoose ? 'text-right' : ''
          }`}
        >
          {transformHours(
            new Date(message.createdAt),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </p>
      )}
    </li>
  );
};
