import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FormField, FormItem, FormLabel } from '@appchoose/form';
import Icon from '@appchoose/icon';
import Label from '@appchoose/label';
import Loader from '@appchoose/loader';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import * as Accordion from '@radix-ui/react-accordion';

import {
  RefundOrderCoverInput,
  useRefundOrderShippingPartiallyMutation,
} from '../../../../types/generated';
import { Order } from '../../../../types/order';
import { formatPrice } from '../../../../utils/currency';
import { RefundForm } from '../../../open-complaint/open-complaint';
import { BlockInfo } from '../../block-info';

type OtherActionsShippingRefundStepSimulationProps = {
  order: Order | undefined;
  setSubmitTitle: (title: string) => void;
};

export const OtherActionsShippingRefundStepSimulation = ({
  order,
  setSubmitTitle,
}: OtherActionsShippingRefundStepSimulationProps) => {
  const { i18n, t } = useTranslation();
  const { control } = useFormContext<RefundForm>();

  const [
    refundOrderShipping,
    {
      loading: refundOrderShippingLoading,
      data: refundOrderShippingData,
      error: refundOrderShippingError,
    },
  ] = useRefundOrderShippingPartiallyMutation();

  useEffect(() => {
    setSubmitTitle(t('other_actions.refund.refund'));
  }, []);

  useEffect(() => {
    refundOrderShipping({
      variables: {
        orderId: order?.id ?? '',
        input: {
          apply: false,
          refundIntent: {
            percentageToRefund: 100,
          },
        },
      },
    });
  }, []);

  const getRefundAmount = () =>
    ((refundOrderShippingData?.refundOrderShippingPartially.cashAmountInCents ??
      0) +
      (refundOrderShippingData?.refundOrderShippingPartially
        .creditAmountInCents ?? 0)) /
    100;

  const initiatorOptions = [
    {
      value: RefundOrderCoverInput.Supplier,
      label: t('other_actions.refund.refund_by_options.supplier', {
        brand_name: order?.seller?.name,
      }),
    },
    {
      value: RefundOrderCoverInput.Choose,
      label: t('other_actions.refund.refund_by_options.choose'),
    },
  ];

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <p className="font-semibold text-gray-700">
          {t('other_actions.refund.simulation_result')}
        </p>

        <div className="rounded border border-gray-300 p-4">
          <p className="text-sm font-bold">{order?.seller?.name}</p>
          <p className="pt-2 text-xs text-gray-500">{order?.id}</p>

          <div className="mt-4">
            {/* SIMULATION EN COURS */}
            {refundOrderShippingLoading ? (
              <div className="flex flex-row items-center space-x-2">
                <p className="text-sm font-semibold text-green-900">
                  {t('other_actions.refund.simulation_in_progress')}
                </p>
                <Loader className="h-4 w-4" />
              </div>
            ) : null}

            {/* SIMULATION ERROR */}
            {refundOrderShippingError ? (
              <BlockInfo
                title={t('other_actions.refund.error_title')}
                type="error"
              >
                <p className="mt-1 text-sm">
                  {t('other_actions.refund.error_description_refresh')}
                </p>
              </BlockInfo>
            ) : null}

            {/* SIMULATION INFO */}
            {refundOrderShippingData ? (
              <BlockInfo
                title={t('other_actions.refund.possible_shipping_refund')}
                type="warning"
              >
                <>
                  <p className="mt-1 text-2xl font-semibold">
                    {formatPrice(
                      getRefundAmount(),
                      i18n.language === 'fr' ? 'fr' : 'en',
                      order?.totalPriceSold.currency ?? 'EUR'
                    )}
                  </p>
                  <p className="mt-1 text-sm">
                    {t('other_actions.refund.refund_detail', {
                      cash: formatPrice(
                        refundOrderShippingData.refundOrderShippingPartially
                          .cashAmountInCents / 100,
                        i18n.language === 'fr' ? 'fr' : 'en',
                        order?.totalPriceSold.currency ?? 'EUR'
                      ),
                      credits: formatPrice(
                        refundOrderShippingData.refundOrderShippingPartially
                          .creditAmountInCents / 100,
                        i18n.language === 'fr' ? 'fr' : 'en',
                        order?.totalPriceSold.currency ?? 'EUR'
                      ),
                    })}
                  </p>
                  {getRefundAmount() < 1 ? (
                    <div className="mt-5">
                      <Icon icon="alert" size="large" />
                      <p className="mt-2 text-sm">
                        <Trans i18nKey="other_actions.refund.less_than_one_euro.information" />
                      </p>
                      <Accordion.Root type="single" collapsible>
                        <Accordion.Item value="example">
                          <Accordion.Trigger className="group mt-2 flex cursor-pointer flex-row">
                            <span className="text-xs font-semibold text-green-900">
                              {t(
                                'other_actions.refund.less_than_one_euro.example_wrapper_title'
                              )}
                            </span>
                            <Icon
                              icon="arrowDown"
                              className="ml-1 text-green-900 transition-transform group-data-[state=open]:rotate-180"
                            />
                          </Accordion.Trigger>

                          <Accordion.Content>
                            <div className="mt-5">
                              <p className="text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_brand" />
                              </p>
                              <p className="text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_client" />
                              </p>
                              <p className="mt-5 text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_headline" />
                              </p>
                              <p className="text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_usecase_1" />
                              </p>
                              <p className="text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_usecase_2" />
                              </p>
                              <ul>
                                <li className="ml-5 list-disc text-sm">
                                  <Trans i18nKey="other_actions.refund.less_than_one_euro.example_bullet_1" />
                                </li>
                                <li className="ml-5 list-disc text-sm">
                                  <Trans i18nKey="other_actions.refund.less_than_one_euro.example_bullet_2" />
                                </li>
                              </ul>
                              <p className="mt-5 text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_note" />
                              </p>
                            </div>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    </div>
                  ) : null}
                </>
              </BlockInfo>
            ) : null}
          </div>
        </div>
      </div>
      <FormField
        control={control}
        name="initiator"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormItem className="space-y-4">
            <FormLabel className="font-semibold text-gray-700">
              {t('other_actions.refund.refund_by')}
            </FormLabel>
            <RadioGroup
              value={value}
              onValueChange={(value) => onChange(value)}
            >
              {initiatorOptions.map((option) => (
                <div
                  className="group flex items-center space-x-3"
                  key={option.value}
                >
                  <RadioGroupItem
                    id={`currency-option-${option.value}`}
                    key={option.value}
                    value={option.value}
                  />
                  <Label
                    htmlFor={`currency-option-${option.value}`}
                    className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                  >
                    {option.label}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </FormItem>
        )}
      />
    </div>
  );
};
