import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';

import pdf from '../../assets/PDF.svg';
import { imageViewerState } from '../../stores/imageViewerState';
import {
  ThreadMessageSimple,
  selectedOrderIdActiveState,
} from '../../stores/selected';
import {
  transformDate,
  transformDateMessage,
  transformHours,
} from '../../utils/date';
import { scrollIntoOrder } from '../../utils/order';
import { capitalize } from '../../utils/string';
import { ProductModal } from '../product-modal/product-modal';

type ChatMessageProps = {
  message: ThreadMessageSimple;
};

export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
}: ChatMessageProps) => {
  const { i18n, t } = useTranslation();

  const setSelectedOrderIdActive = useSetRecoilState(
    selectedOrderIdActiveState
  );
  const setImageViewerUrl = useSetRecoilState(imageViewerState);

  const renderStatusBar = (message: ThreadMessageSimple) => {
    const renderHours = transformHours(
      new Date(message.createdAt || ''),
      i18n.language === 'fr' ? 'fr' : 'en'
    );

    let element: React.ReactNode | null = null;
    if (message.extraData) {
      element = <>{renderHours} - via product page</>;
    } else if (message.fromEmail) {
      if (message.chooseReply) {
        element = (
          <>
            {getOperator(message)} via email - {renderHours}
          </>
        );
      }
      element = (
        <>
          {renderHours} - email via {message.fromEmail}
        </>
      );
    } else if (
      message.fromPage &&
      message.fromPage !== 'Home' &&
      message.fromPage !== 'Order'
    ) {
      element = (
        <>
          {renderHours} - via {message.fromPage}
        </>
      );
    }

    element = (
      <>
        {getReadStatus(message)} {getOperator(message)} {renderHours}
      </>
    );

    return element ? (
      <p
        className={`timer text-xs text-gray-500 ${
          message.chooseReply ? 'text-right' : ''
        }`}
      >
        {element}
      </p>
    ) : null;
  };

  const getReadStatus = (message: ThreadMessageSimple) => {
    if (message.chooseReply && message.displayReadStatus) {
      return message.readAt
        ? `${t('conversation.message.status.read')} - `
        : `${t('conversation.message.status.distributed')} - `;
    }
    return null;
  };

  const getOperator = (message: ThreadMessageSimple) => {
    if (message.chooseReply && message.operator === 'Message Automatique') {
      return message.operator + ' -';
    }
    if (message.chooseReply && message.operator) {
      return capitalize(message.operator.split(' ')[0]) + ' -';
    }
    return null;
  };

  const renderBody = (message: ThreadMessageSimple) => {
    if (message.photo) {
      return (
        <p
          className="photo h-20 w-20 cursor-pointer rounded-lg"
          onClick={() =>
            setImageViewerUrl({
              slides: [{ index: 0, src: message.photo || '' }],
            })
          }
        >
          <img
            src={message.photo}
            className="h-20 w-20 rounded-lg object-cover"
          />
        </p>
      );
    } else if (message.extraData?.name) {
      return (
        <div
          className={`rounded-xl p-4 text-sm ${
            message.chooseReply
              ? 'rounded-br-sm bg-green-900 text-white'
              : 'rounded-tl-sm bg-gray-50 text-gray-700'
          } product-info`}
        >
          <Modal>
            <ModalTrigger asChild>
              <div className="flex cursor-pointer items-center space-x-2 border-b border-gray-300 pb-4">
                {message.extraData.image ? (
                  <img src={message.extraData.image} className="mr-4" />
                ) : null}
                <div>
                  <p
                    className={`font-bold ${
                      message.chooseReply ? 'text-gray-300' : 'text-green-900'
                    }`}
                  >
                    {message.extraData.brand_name}
                  </p>
                  <p>{message.extraData.name}</p>
                </div>
              </div>
            </ModalTrigger>
            <ModalContent scrollable>
              <ProductModal productId={message?.extraData?.id || ''} />
            </ModalContent>
          </Modal>
          <p className="mt-4 overflow-hidden text-ellipsis whitespace-pre-line">
            {message.body}
          </p>
        </div>
      );
    } else if (message.orderInfos && !message.isBot) {
      return (
        <div
          className={`rounded-xl p-4 text-sm ${
            message.chooseReply
              ? 'rounded-br-sm bg-green-900 text-white'
              : 'rounded-tl-sm bg-gray-50 text-gray-700'
          } product-info`}
        >
          <div className="border-b border-gray-300 pb-4">
            <div
              onClick={() => {
                setSelectedOrderIdActive(message?.orderInfos?.key ?? '');
                scrollIntoOrder(message?.orderInfos?.key ?? '');
              }}
              className="flex cursor-pointer items-center space-x-2 pb-4"
            >
              <div
                className={`flex h-10 w-10 shrink-0 items-center justify-center rounded-full ${
                  message.chooseReply
                    ? 'bg-gray-300 text-green-900'
                    : 'bg-green-900 text-white'
                }`}
              >
                <Icon icon="orders" />
              </div>
              <div>
                <div>
                  <p
                    className={`font-bold ${
                      message.chooseReply ? 'text-gray-300' : 'text-green-900'
                    }`}
                  >
                    {message.orderInfos.brand_name}
                  </p>
                  <p>n°{message.orderInfos.key}</p>
                </div>
              </div>
            </div>
            {Array.isArray(message.orderInfos.products) &&
            message.orderInfos.products.length ? (
              <div>
                <h5 className="mb-2 font-bold">
                  {t('conversation.affected_product', {
                    count: message.orderInfos.products.length,
                  })}
                </h5>
                <div className="space-y-4">
                  {message.orderInfos.products.map((product) => (
                    <div className="flex items-center" key={product.key_react}>
                      <img src={product.image} className="mr-2 h-6 w-6" />
                      <p>{product.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <p className="mt-4 overflow-hidden text-ellipsis whitespace-pre-line">
            {message.body}
          </p>
        </div>
      );
    } else if (message.fromEmail) {
      return (
        <div
          className={`rounded-xl p-4 text-sm ${
            message.chooseReply
              ? 'rounded-br-sm bg-green-900 text-white'
              : 'rounded-tl-sm bg-gray-50 text-gray-700'
          } email-info`}
        >
          <div className="flex items-center space-x-2 border-b border-gray-300 pb-4">
            <div
              className={`flex h-10 w-10 shrink-0 items-center justify-center rounded-full ${
                message.chooseReply
                  ? 'bg-gray-300 text-green-900'
                  : 'bg-green-900 text-white'
              }`}
            >
              <Icon icon="mail" />
            </div>
            <div
              className={`text-xs ${
                message.chooseReply ? 'text-white' : 'text-gray-700'
              }`}
            >
              <p>{message.emailSubject}</p>
            </div>
          </div>
          <p className="mt-4 overflow-hidden text-ellipsis whitespace-pre-line">
            {message.body}
          </p>
        </div>
      );
    }
    const file = message.file ? (
      message.file.includes('.pdf') ? (
        <a
          href={message.file}
          target="_blank"
          rel="noreferrer"
          className="mt-2 block text-sm"
        >
          <p className="body flex items-center space-x-3 rounded-xl rounded-br-sm bg-green-900 p-4 text-sm text-white">
            <img style={{ width: 40 }} src={pdf} alt="Fichier pdf" />
            <div className="text-sm font-bold text-white">
              {t('conversation.view_file')}
            </div>
          </p>
        </a>
      ) : (
        <p
          className={`body rounded-xl p-4 text-sm ${
            message.chooseReply
              ? 'rounded-br-sm bg-green-900 text-white'
              : 'rounded-tl-sm bg-gray-50 text-gray-700'
          } tag`}
        >
          <a
            href={message.file}
            target="_blank"
            rel="noreferrer"
            className="text-sm"
          >
            {t('conversation.view_file')}
          </a>
          <span className="float-right text-xs text-gray-500">
            {transformDate(
              new Date(message.createdAt || ''),
              i18n.language === 'fr' ? 'fr' : 'en'
            )}
          </span>
          <br />
          <span className="text-xs text-gray-500">
            {t('conversation.message.visible_only_choose')}
          </span>
        </p>
      )
    ) : null;
    return (
      <>
        <p
          className={`body rounded-xl p-4 text-sm ${
            message.chooseReply
              ? 'rounded-br-sm bg-green-900 text-white'
              : 'rounded-tl-sm bg-gray-50 text-gray-700'
          }`}
        >
          {message.body}
        </p>
        {file}
      </>
    );
  };

  return (
    <li className={`message space-y-2 ${message.chooseReply ? 'choose' : ''}`}>
      {message.displayTime ? (
        <p className="timer mb-4 mt-8 text-center text-xs text-gray-500">
          {transformDateMessage(
            new Date(message.createdAt || ''),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </p>
      ) : null}
      {renderBody(message)}
      {renderStatusBar(message)}
    </li>
  );
};
