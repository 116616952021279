import {
  differenceInDays,
  differenceInYears,
  format,
  formatDistanceToNowStrict,
  isSameMonth,
  isToday,
  isYesterday,
} from 'date-fns';
import { format as formatTz, toZonedTime } from 'date-fns-tz';
import { enUS, fr } from 'date-fns/locale';
import { t } from 'i18next';

export const transformDateMessage = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (differenceInDays(new Date(), date) < 7) {
    if (isToday(date)) {
      return format(date, `'${locale === 'fr' ? "Aujourd''hui" : 'Today'}'`, {
        locale: dateLocale,
      });
    } else if (isYesterday(date)) {
      return format(date, `'${locale === 'fr' ? 'Hier' : 'Yesterday'}'`, {
        locale: dateLocale,
      });
    }
    return format(date, 'EEEE', { locale: dateLocale });
  }
  return format(date, 'd MMM', { locale: dateLocale });
};

export const transformDateLastTime = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  return formatDistanceToNowStrict(date, {
    locale: dateLocale,
    addSuffix: true,
  });
};

export const transformDate = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (differenceInDays(new Date(), date) < 7) {
    if (isToday(date)) {
      return format(date, 'HH:mm', { locale: dateLocale });
    } else if (isYesterday(date)) {
      return format(
        date,
        `'${locale === 'fr' ? 'Hier' : 'Yesterday'}', HH:mm`,
        { locale: dateLocale }
      );
    }
    return format(date, 'EEEE, HH:mm', { locale: dateLocale });
  }
  return format(date, 'd MMM, HH:mm', { locale: dateLocale });
};

export const transformOrderDate = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (differenceInYears(Date.now(), new Date(date)) >= 1) {
    return format(date, 'PP', { locale: dateLocale });
  } else {
    return transformDateWithYear(date, locale);
  }
};

export const transformDateWithYear = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (differenceInDays(new Date(), date) < 7) {
    if (isToday(date)) {
      return format(date, 'HH:mm', { locale: dateLocale });
    } else if (isYesterday(date)) {
      return format(
        date,
        `'${locale === 'fr' ? 'Hier' : 'Yesterday'}', HH:mm`,
        { locale: dateLocale }
      );
    }
    return format(date, 'EEEE, HH:mm', { locale: dateLocale });
  }
  return format(date, 'PP, HH:mm', { locale: dateLocale });
};

export const transformDateSimple = (
  date: Date,
  locale: string,
  formatForm?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;

  return format(date, formatForm ?? 'dd-MM-yyyy', { locale: dateLocale });
};

export const transformHours = (date: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  return format(date, 'HH:mm', { locale: dateLocale });
};

export const formatTimeWithTimezone = (
  date: Date,
  locale: string,
  timezone?: string
) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  const convertedDate = timezone ? toZonedTime(date, timezone) : date;
  return formatTz(convertedDate, 'p (O)', {
    locale: dateLocale,
    timeZone: timezone,
  });
};

export const formatDate = (date: Date, dateFormat: string, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  return format(date, dateFormat, { locale: dateLocale });
};

export const convertDateToZonedTime = (date: Date, timezone?: string) => {
  return timezone ? toZonedTime(date, timezone) : date;
};

export const getDay = (day: string) => {
  switch (day) {
    case 'monday':
      return t('days.monday');
    case 'tuesday':
      return t('days.tuesday');
    case 'wednesday':
      return t('days.wednesday');
    case 'thursday':
      return t('days.thursday');
    case 'friday':
      return t('days.friday');
    case 'saturday':
      return t('days.saturday');
    case 'sunday':
      return t('days.sunday');
  }
};

export const formatRange = (startDate: Date, endDate: Date, locale: string) => {
  const dateLocale = locale === 'fr' ? fr : enUS;
  if (isSameMonth(startDate, endDate)) {
    return `${format(startDate, 'dd', { locale: dateLocale })} - ${format(
      endDate,
      'dd MMMM',
      { locale: dateLocale }
    )}`;
  }
  return `${format(startDate, 'dd MMMM', { locale: dateLocale })} - ${format(
    endDate,
    'dd MMMM',
    { locale: dateLocale }
  )}`;
};
