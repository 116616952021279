import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { FormControl, FormField, FormItem, FormLabel } from '@appchoose/form';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import { segmentsState } from '../../stores/group-message/segments';
import { selectedSaleState } from '../../stores/group-message/selectedSale';
import { selectedSegmentationState } from '../../stores/group-message/selectedSegmentation';
import { SegmentType } from '../../types/generated';
import { ImportCsv } from './import-csv';
import { SaleGroupMessageForm } from './sale-group-message-modal';
import { SaleGroupMessageSummary } from './sale-group-message-summary';

export const SaleGroupMessageModalStepSegmentation: React.FC = () => {
  const { t } = useTranslation();

  const form = useFormContext<SaleGroupMessageForm>();

  const segments = useRecoilValue(segmentsState);
  const selectedSale = useRecoilValue(selectedSaleState);
  const setSelectedSegmentation = useSetRecoilState(selectedSegmentationState);

  useEffect(() => {
    setSelectedSegmentation(
      segments.find(
        (segment) => segment.id === form.getValues('segmentationId')
      )
    );
    return () => {
      form.unregister('segmentationId', {
        keepValue: true,
        keepIsValid: false,
      });
    };
  }, []);

  const onSuccessfullUploadCsv = (users: string[]) => {
    form.setValue('segmentationOrderIds', users, { shouldValidate: true });
  };

  form.watch('segmentationId');

  return (
    <>
      <h2 className="mb-6 text-2xl font-bold text-gray-900">
        {t('message_group.contact_group_of_customers')}
      </h2>
      <SaleGroupMessageSummary />
      <h3 className="mb-4 text-base font-semibold text-gray-700">
        {t('message_group.select_desired_segmentation')}
      </h3>
      <div className="flex flex-col pb-12">
        <FormField
          control={form.control}
          name="segmentationId"
          rules={{
            required: true,
            validate: {
              minCustomers: (value) =>
                value === SegmentType.ImportCsv ||
                (segments.find((segment) => segment.id === value)?.statsBySale
                  ?.nbCustomers ?? 0) > 0,
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sr-only" hidden>
                {t('message_group.segmentation_list')}
              </FormLabel>

              <FormControl>
                <RadioGroup
                  value={field.value}
                  onValueChange={(value) => {
                    setSelectedSegmentation(
                      segments.find(
                        (segment) => segment.id === (value as SegmentType)
                      )
                    );
                    field.onChange(value);
                  }}
                  className="gap-4"
                >
                  {segments.map((segment) => (
                    <div
                      className="group flex items-center space-x-3"
                      key={segment.id}
                    >
                      <RadioGroupItem
                        id={`segmentation-${segment.id}`}
                        key={segment.id}
                        value={segment.id}
                      />
                      <Label htmlFor={`segmentation-${segment.id}`}>
                        <div className="flex items-center">
                          <p className="mr-1 text-sm text-gray-700">
                            {segment.label}
                          </p>
                          {segment.id !== SegmentType.ImportCsv ? (
                            <span className="text-xs text-gray-500">
                              {t('message_group.customerWithCount', {
                                count: segment.statsBySale?.nbCustomers,
                              })}
                            </span>
                          ) : (
                            <p className="text-xs font-semibold text-green-900">
                              <a
                                href="https://storage.cloud.google.com/public.appchoose.io/csv/export_sale_template.csv"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {t('message_group.see_the_model')}
                              </a>
                            </p>
                          )}
                        </div>
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />
        {form.getValues('segmentationId') === SegmentType.ImportCsv ? (
          <>
            <input
              type="hidden"
              {...form.register('segmentationOrderIds', {
                required: true,
              })}
            />
            <ImportCsv
              saleId={selectedSale?.id}
              onSuccessfullUploadCsv={onSuccessfullUploadCsv}
            />
          </>
        ) : null}
      </div>
    </>
  );
};
