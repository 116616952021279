import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';
import { parse } from 'papaparse';

import { useGetMessageGroupStatsLazyQuery } from '../../types/generated';

const CHUNK_SIZE = 150;

type ImportCsvProps = {
  saleId: string | undefined;
  onSuccessfullUploadCsv: (orderIds: string[]) => void;
};

export const ImportCsv: React.FC<ImportCsvProps> = ({
  saleId,
  onSuccessfullUploadCsv,
}: ImportCsvProps) => {
  const { t } = useTranslation();

  const [
    getMessageGroupStats,
    { loading: loadingSegmentCsvUsers, error: errorSegmentCsvUsers },
  ] = useGetMessageGroupStatsLazyQuery({ errorPolicy: 'all' });
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (errorSegmentCsvUsers) {
      setError('Le fichier .csv doit respecter le format du modèle.');
      setFileState(undefined);
    }
  }, [errorSegmentCsvUsers]);

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    setError(undefined);
    if (acceptedFiles.length) {
      parse<{ Référence: string }, File>(acceptedFiles?.[0], {
        complete: (results) => {
          const orderIds = Array.from(
            new Set(
              results.data
                .map((row) => row['Référence'])
                .filter((orderId) => !!orderId)
            )
          );
          const chunks: string[][] = [];
          for (let i = 0; i < orderIds.length; i += CHUNK_SIZE) {
            const chunk = orderIds.slice(i, i + CHUNK_SIZE);
            chunks.push(chunk);
          }
          chunkedUpload(saleId ?? '', chunks)
            .then((result) => {
              if (result.length > 0) {
                onSuccessfullUploadCsv(result);
              } else {
                setError("Aucun client n'a été détecté pour ce fichier.");
              }
            })
            .catch(() => {
              setError(
                'Une erreur est survenue lors du téléchargement du fichier .csv.'
              );
            });

          setFileState(acceptedFiles?.[0]);
        },
        error: (err) => {
          toast.error(err.message);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  }, []);

  const chunkedUpload = async (saleId: string, chunks: string[][]) => {
    let results: string[] = [];
    for (const chunk of chunks) {
      const r = await getMessageGroupStats({
        variables: {
          orderIds: chunk,
          saleId,
        },
      });
      results = results.concat(r.data?.getMessageGroupStats?.orderIds ?? []);
    }
    return results;
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    open,
  } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    onDrop: onDrop,
  });

  const [fileState, setFileState] = useState<FileWithPath | undefined>(
    undefined
  );

  const onRemoveFile = () => {
    setFileState(undefined);
  };

  const onInternalAddFile = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    open();
  };

  const onInternalRemoveFile = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemoveFile();
  };

  function renderContent() {
    if (error) {
      return (
        <>
          <img
            className="mb-4"
            src="https://public.choose.app/react-library/brand/modal/Illustration_Medium_Error.svg"
          />
          {error ? (
            Array.isArray(error) ? (
              <p className="text-xs text-gray-700">{error[0]}</p>
            ) : (
              <p className="text-xs text-gray-700">{error}</p>
            )
          ) : null}
        </>
      );
    }
    if (fileState) {
      if (loadingSegmentCsvUsers) {
        return (
          <>
            <Loader className="mb-4 h-8 w-8" />
            <p className="text-xs text-gray-700">{fileState.path}</p>
            <p
              onClick={onRemoveFile}
              className="mt-4 cursor-pointer text-sm font-semibold text-green-900"
            >
              {t('cancel')}
            </p>
          </>
        );
      }
      return (
        <>
          <img
            className="mb-4"
            src="https://public.choose.app/react-library/brand/modal/Illustration_Medium_Star.svg"
          />
          <p className="mb-4 text-xs text-gray-700">{fileState.path}</p>
          <button
            type="button"
            onClick={onInternalRemoveFile}
            className="text-sm font-semibold text-green-900"
          >
            {t('cancel')}
          </button>
        </>
      );
    }
    if (isDragActive) {
      if (isDragAccept) {
        return (
          <>
            <img
              className="mb-4"
              src="https://public.choose.app/react-library/brand/modal/Illustration_Medium_Arrow.svg"
            />
            <p className="text-xs text-gray-700">
              {t('message_group.fields.file_upload.release_to_import')}
            </p>
          </>
        );
      }
      return (
        <>
          <img
            className="mb-4"
            src="https://public.choose.app/react-library/brand/modal/Illustration_Medium_Error.svg"
          />
          <p className="text-xs text-gray-700">
            {t('message_group.fields.file_upload.must_respect_format')}
          </p>
        </>
      );
    }
    return (
      <>
        <img
          className="mb-4"
          src="https://public.choose.app/react-library/brand/modal/Illustration_Medium_Plus.svg"
        />
        <Button type="button" onClick={onInternalAddFile} className="mb-4">
          {t('message_group.fields.file_upload.add_file')}
        </Button>
        <p className="text-xs text-gray-700">
          {t('message_group.fields.file_upload.drop_to_import')}
        </p>
      </>
    );
  }
  return (
    <div
      className={`dragndrop ${
        error ? 'border-red-350 bg-red-300 hover:bg-red-300' : ''
      }`}
      data-active={isDragActive}
      data-rejected={isDragReject}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {renderContent()}
    </div>
  );
};
